export enum CustomPanelTypeShape {
    Panel = 1,

    Corner = 2,

    ZShape = 3
}

export enum CustomPanelTypeStatus {
    Created = 0,

    PreviewReady = 1,

    Published = 2,

    Failed = 3
}

export interface ICustomPanelType {
    id: string;
    name: string;
    status: CustomPanelTypeStatus;
    offset: THREE.Vector3;
    length: number;
    height: number;
    bubbleUrl: string | null;
    thumbnailUrl: string | null;
    shapeType: CustomPanelTypeShape.Panel;
}

export interface ICustomCornerType {
    id: string;
    name: string;
    status: CustomPanelTypeStatus;
    offset: THREE.Vector3;
    leftWingLength: number;
    rightWingLength: number;
    height: number;
    angle: number;
    bubbleUrl: string | null;
    thumbnailUrl: string | null;
    shapeType: CustomPanelTypeShape.Corner;
}

export interface ICustomZShapeType {
    id: string;
    name: string;
    status: CustomPanelTypeStatus;
    offset: THREE.Vector3;
    leftWingLength: number;
    shelfLength: number;
    rightWingLength: number;
    height: number;
    angle1: number;
    angle2: number;
    bubbleUrl: string | null;
    thumbnailUrl: string | null;
    shapeType: CustomPanelTypeShape.ZShape;
}

export type CustomPanelType = ICustomPanelType | ICustomCornerType | ICustomZShapeType;

export interface ICustomPanelTypeCreatedResponse {
    id: string;
    uploadUrl: string;
}