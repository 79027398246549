import { findPredefinedThicknessIndex, predefinedPanelThicknesses } from "@dextall/panels-defaults";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { editorCladdingGeometryThickness } from "../defaults";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { CornerCladdingsFactory } from "./cornerCladdingsFactory";
import { WallCornerCladdingCell } from "./wallCornerCladdingCell";
import { WallPanelCladdingCell } from "./wallPanelCladdingCell";
import { CladdingCellStyle } from "../../../responses/panelSource";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { isMoreThan } from "@dextall/shared";

export class Corner {
    private readonly wingPlanesGeometry: ExtrusionGeometry[];
    private _elementName: string;

    constructor(private readonly panelSource: ICornerPanelSource, private readonly wallCorner: IModelCorner,
        public readonly panelDbId: number,
        public readonly leftWingHooksCoordinateSystem: THREE.Matrix4,
        public readonly rightWingHooksCoordinateSystem: THREE.Matrix4,
        modelOffset: THREE.Vector3,
        cornerCladdingsFactory: CornerCladdingsFactory,
        wingBoundaryPlaneMaterial: THREE.Material,
        private readonly leftWallBoundaryLoop: BoundaryLoop,
        private readonly rightWallBoundaryLoop: BoundaryLoop) {

        const claddings = cornerCladdingsFactory.createFromSource(panelSource, wallCorner, modelOffset);
        this.cornerCladdings = claddings.cornerCladdingCells;
        this.leftWingCladdings = claddings.leftWingCladdingCells;
        this.rightWingCladdings = claddings.rightWingCladdingCells;
        this.wingPlanesGeometry = cornerCladdingsFactory.createWingPlanesGeometry(panelSource, wallCorner, modelOffset, wingBoundaryPlaneMaterial);
        this._elementName = panelSource.elementName;
        this.origin = new THREE.Vector3().copy(wallCorner.origin)
            .add(new THREE.Vector3().copy(modelOffset).negate())
            .add(new THREE.Vector3(0, 0, wallCorner.heights[this.heightIndex]));

        for (const claddingCell of this.cornerCladdings)
            claddingCell.assignId(this.panelDbId);
    }

    get id(): string {
        return this.panelSource.id;
    }

    get panelTypeId(): string {
        return this.panelSource.panelTypeId;
    }

    get customPanelTypeId(): string | null {
        return this.panelSource.customPanelTypeId;
    }

    get wallCornerId(): string {
        return this.panelSource.wallCornerId;
    }

    get userUniqueId(): number {
        return this.panelSource.userUniqueId;
    }

    get elementName(): string {
        return this._elementName;
    }

    get height(): number {
        return this.panelSource.height;
    }

    get leftWing(): number {
        return this.panelSource.leftWing;
    }

    get rightWing(): number {
        return this.panelSource.rightWing;
    }

    get leftWallFaceId(): string {
        return this.wallCorner.left.wallFaceId;
    }

    get rightWallFaceId(): string {
        return this.wallCorner.right.wallFaceId;
    }

    get heightIndex(): number {
        return this.panelSource.heightIndex;
    }

    get offset(): number {
        return this.panelSource.offset;
    }

    get isReversed(): boolean {
        return this.angle > 180;
    }

    get angle(): number {
        return this.wallCorner.angle;
    }

    get thicknessLabel(): string {
        const index = findPredefinedThicknessIndex(this.panelSource.thickness);

        const predefinedThickness = predefinedPanelThicknesses[index];

        return predefinedThickness.name;
    }

    get style(): CladdingCellStyle {
        return this.cornerCladdings[0].style;
    }

    get isParapetPanel(): boolean {
        return this.panelSource.isParapetPanel;
    }

    public readonly origin: THREE.Vector3;

    readonly cornerCladdings: WallCornerCladdingCell[];

    readonly leftWingCladdings: WallPanelCladdingCell[];

    readonly rightWingCladdings: WallPanelCladdingCell[]

    createGeometry(): ExtrusionGeometry[] {
        return [
            ...this.cornerCladdings.flatMap(x => x.createGeometry()),
            ...this.leftWingCladdings.map(x => x.createGeometry()),
            ...this.rightWingCladdings.map(x => x.createGeometry()),
            ...this.wingPlanesGeometry
        ];
    }

    update(elementName: string) {
        this._elementName = elementName;
    }

    getSignedDistanceToLeftWing(point: THREE.Vector3) {
        return getSingedDistanceToXYPlane(point, this.leftWingHooksCoordinateSystem);
    }

    getSignedDistanceToRightWing(point: THREE.Vector3) {
        return getSingedDistanceToXYPlane(point, this.rightWingHooksCoordinateSystem);
    }

    getLeftWingWallCoordinateSystem(): THREE.Matrix4 {
        return this.leftWallBoundaryLoop.transform;
    }

    getRightWingWallCoordinateSystem(): THREE.Matrix4 {
        return this.rightWallBoundaryLoop.transform;
    }

    setPanelType(newPanelTypeId: string) {
        this.panelSource.panelTypeId = newPanelTypeId;
    }

    setCustomPanelType(customPanelTypeId: string | null) {
        this.panelSource.customPanelTypeId = customPanelTypeId;

        for (const claddingCell of this.leftWingCladdings)
            claddingCell.switchToCustom(!!customPanelTypeId);

        for (const claddingCell of this.rightWingCladdings)
            claddingCell.switchToCustom(!!customPanelTypeId);

        for (const claddingCell of this.cornerCladdings)
            claddingCell.switchToCustom(!!customPanelTypeId);
    }

    computeLeftWingPanelTypeTitleMatrix(titleSize: THREE.Vector3): THREE.Matrix4 {
        return this.computeWingPanelTypeTitleMatrix(titleSize, this.leftWingHooksCoordinateSystem, this.isReversed ? this.rightWing : this.leftWing);
    }

    computeRightWingPanelTypeTitleMatrix(titleSize: THREE.Vector3): THREE.Matrix4 {
        return this.computeWingPanelTypeTitleMatrix(titleSize, this.rightWingHooksCoordinateSystem, this.isReversed ? this.leftWing : this.rightWing);
    }

    findTopmostCornerCladding(): WallCornerCladdingCell {
        let cell = this.cornerCladdings[0];

        for (const cornerCell of this.cornerCladdings)
            if (isMoreThan(cornerCell.leftBox.max.y, cell.leftBox.max.y))
                cell = cornerCell;

        return cell;
    }

    private computeWingPanelTypeTitleMatrix(titleSize: THREE.Vector3, wingMatrix: THREE.Matrix4, wingLength: number): THREE.Matrix4 {
        const position = new THREE.Vector3(
            0.5 * (wingLength - titleSize.x),
            0.5 * (this.height - titleSize.y),
            editorCladdingGeometryThickness + zFightingFixDistance
        ).applyMatrix4(wingMatrix);

        return new THREE.Matrix4().copy(wingMatrix).setPosition(position);
    }
}

const tempOrigin = new THREE.Vector3();
const tempNormal = new THREE.Vector3();
const tempRadiusVector = new THREE.Vector3();

const getSingedDistanceToXYPlane = (point: THREE.Vector3, coordinateSystem: THREE.Matrix4) => {
    tempOrigin.setFromMatrixPosition(coordinateSystem);
    tempNormal.setFromMatrixColumn(2, coordinateSystem);

    tempRadiusVector.subVectors(point, tempOrigin);

    return tempNormal.dot(tempRadiusVector);
}