import { ICoordinateSystem } from "../../../responses/coordinateSystem";
import { IWallFace, ModelWallFaceOpeningEditState } from "../../../responses/wallFace";
import { claddingCellGap } from "../defaults";
import { BoundaryLoop } from "./boundaryLoop";

export class BoundaryLoopsFactory {
    createLoop(wallFace: IWallFace, globalOffset: THREE.Vector3): BoundaryLoop {
        const transform = new THREE.Matrix4()
            .makeBasis(
                new THREE.Vector3().copy(wallFace.transform.basisX),
                new THREE.Vector3().copy(wallFace.transform.basisY),
                new THREE.Vector3().copy(wallFace.transform.basisZ))
            .setPosition(new THREE.Vector3().copy(wallFace.transform.origin).add(globalOffset));

        const boundaryLoop = new BoundaryLoop(wallFace.boundary, transform);

        const openings = wallFace
            .openings
            .filter(x => !(x.editState === ModelWallFaceOpeningEditState.Removed || x.editState === ModelWallFaceOpeningEditState.RemovedCreatedInStudio));

        for (const loop of openings) {
            const openingLoop = new BoundaryLoop(loop.boundary, transform);

            boundaryLoop.addInnerLoop(openingLoop);
        }

        return boundaryLoop;
    }

    createLoopFromFlatBox(box: THREE.Box3, transform: ICoordinateSystem, globalOffset: THREE.Vector3): BoundaryLoop {
        const loopTransform = new THREE.Matrix4()
            .makeBasis(
                new THREE.Vector3().copy(transform.basisX),
                new THREE.Vector3().copy(transform.basisY),
                new THREE.Vector3().copy(transform.basisZ))
            .setPosition(new THREE.Vector3().copy(transform.origin).add(globalOffset));

        return this.createLoopFromBox(box, loopTransform);
    }

    createLoopFromBox(box: THREE.Box3, transform: THREE.Matrix4): BoundaryLoop {
        const points = [
            box.min,
            new THREE.Vector3(box.max.x, box.min.y, 0),
            box.max,
            new THREE.Vector3(box.min.x, box.max.y, 0),
            box.min
        ];

        return new BoundaryLoop(points, transform);
    }

    createDividedHorizontally(boundaryLoop: BoundaryLoop, point: THREE.Vector2 | THREE.Vector3): BoundaryLoop[] {
        const box = boundaryLoop.box;

        const firstLoopBox = new THREE.Box3(box.min, new THREE.Vector3(box.max.x, point.y - claddingCellGap, 0));
        const secondLoopBox = new THREE.Box3(new THREE.Vector3(box.min.x, point.y + claddingCellGap, 0), box.max);

        return [
            this.createLoopFromBox(firstLoopBox, boundaryLoop.transform),
            this.createLoopFromBox(secondLoopBox, boundaryLoop.transform)
        ]
    }

    createDividedVertically(boundaryLoop: BoundaryLoop, point: THREE.Vector2 | THREE.Vector3): BoundaryLoop[] {
        const box = boundaryLoop.box;

        const firstLoopBox = new THREE.Box3(box.min, new THREE.Vector3(point.x - claddingCellGap, box.max.y, 0));
        const secondLoopBox = new THREE.Box3(new THREE.Vector3(point.x + claddingCellGap, box.min.y, 0), box.max);

        return [
            this.createLoopFromBox(firstLoopBox, boundaryLoop.transform),
            this.createLoopFromBox(secondLoopBox, boundaryLoop.transform)
        ]
    }

    createMerged(boundaryLoop: BoundaryLoop, other: BoundaryLoop): BoundaryLoop {
        const box = boundaryLoop.box;
        const otherBox = other.box;

        const min = new THREE.Vector3(Math.min(box.min.x, otherBox.min.x), Math.min(box.min.y, otherBox.min.y), 0);
        const max = new THREE.Vector3(Math.max(box.max.x, otherBox.max.x), Math.max(box.max.y, otherBox.max.y), 0);

        const mergedBox = new THREE.Box3(min, max);

        return this.createLoopFromBox(mergedBox, boundaryLoop.transform);
    }

    createFromBoxIntersection(boundaryLoop: BoundaryLoop, other: BoundaryLoop): BoundaryLoop {
        const box = boundaryLoop.box;
        const otherBox = other.box;

        const min = new THREE.Vector3(Math.max(box.min.x, otherBox.min.x), Math.max(box.min.y, otherBox.min.y), 0);
        const max = new THREE.Vector3(Math.min(box.max.x, otherBox.max.x), Math.min(box.max.y, otherBox.max.y), 0);

        const intersectionBox = new THREE.Box3(min, max);

        return this.createLoopFromBox(intersectionBox, boundaryLoop.transform);
    }
}