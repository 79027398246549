import React, { useRef, useState } from "react"
import ReactDOM from "react-dom";
import Modal from "@weave-design/modal";
import Label from '@weave-design/label';
import Input from '@weave-design/input';
import Dropdown from "@weave-design/dropdown"
import Button from '@weave-design/button';
import Spacer from '@weave-design/spacer';
import IconButton from '@weave-design/icon-button';
import { Folder24 } from '@weave-design/icons';
import { unzip, setOptions } from 'unzipit';
import { ProcessingPane } from "../processing-pane/processingPane";
import { ProcessingFailure } from "../processing-failure/processingFailure";
import { CustomPanelTypeUploader } from "../forge/custom-component-library/uploader/customPanelTypeUploader";
import { CustomCornerTypeUploader } from "../forge/custom-component-library/uploader/customCornerTypeUploader";
import { CustomZShapedTypeUploader } from "../forge/custom-component-library/uploader/customZShapedTypeUploader";
import { CustomPanelType } from "../../responses/customPanelTypes";
import "./customComponentUpload.css"

type Props = {
    closeUploadDialog: () => void;
    pushNewComponent: (component: CustomPanelType) => void;
}

export const CustomComponentUpload = ({ closeUploadDialog, pushNewComponent }: Props) => {
    const [sourceFileName, setSourceFileName] = useState("");
    const [inventorAssemblies, setInventorAssemblies] = useState<string[]>([]);
    const [selectedInventorAssembly, setSelectedInventorAssembly] = useState<string>();
    const [componentType, setComponentType] = useState<CustomComponentType>("Panel");
    const [uploading, setUploading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>();
    const selectedFile = useRef<File>();

    const listZipAssemblyFiles = async (file: File) => {
        const assemblies: string[] = [];
        setOptions({ numWorkers: 0 });

        const { entries } = await unzip(file);

        Object.entries(entries).forEach(([name]) => {
            if (name.toLowerCase().endsWith('.iam') && !name.toLowerCase().includes('oldversions')) {
                assemblies.push(name);
            }
        });

        return assemblies;
    }

    const onFileSelected = async (data: React.ChangeEvent<HTMLInputElement>) => {
        const files = data.target.files;

        if (!(files && files.length > 0))
            return;

        const file = files[0];

        const fileName = file.name.toLowerCase();

        if (!fileName.endsWith(".zip"))
            return;

        selectedFile.current = file;

        setSourceFileName(fileName);

        const assemblyFiles = await listZipAssemblyFiles(file);

        setInventorAssemblies(assemblyFiles);

        const preselectedAssembly = assemblyFiles.find(x => x.toLowerCase().indexOf("general") >= 0);

        setSelectedInventorAssembly(preselectedAssembly || assemblyFiles[0]);
    }

    const createUploader = () => {
        switch (componentType) {
            case "Panel":
                return new CustomPanelTypeUploader();

            case "Corner":
                return new CustomCornerTypeUploader();

            case "Z-Shaped":
                return new CustomZShapedTypeUploader();

            default:
                throw new Error("Type not supported!");
        }
    }

    const uploadCustomComponent = async () => {
        setUploading(true);

        const uploader = createUploader();

        const uploadResult = await uploader.uploadCustomComponent(selectedFile.current!, selectedInventorAssembly!);

        setUploading(false);

        if (uploadResult.isSuccess) {
            pushNewComponent(uploadResult.item);

            closeUploadDialog();
        } else
            setErrorMessage(uploadResult.message);
    }

    return <>
        <Modal
            open={true}
            title="New custom component"
            onCloseClick={closeUploadDialog}
            stylesheet={modalStyles}>
            <div id="upload-file-form">
                <div className="fileBrowseContainer">
                    <div className="stretch">
                        <Label variant="top">Model ZIP file:</Label>
                        <Input
                            variant="box"
                            value={sourceFileName}
                            disabled={true}
                        />
                    </div>

                    <div className="browseButton">
                        <label htmlFor="modelFileInput">
                            <IconButton
                                icon={<Folder24 />}
                                title="Choose panel dwg file"
                                onClick={() => { document.getElementById("modelFileInput")?.click(); }}
                            />
                        </label>
                        <input id="modelFileInput"
                            type="file"
                            accept=".zip"
                            onChange={(e) => onFileSelected(e)}
                        />
                    </div>
                </div>
                <Spacer spacing="l" />
                <Label variant="top">Main assembly (3D model):</Label>
                <Dropdown
                    options={inventorAssemblies}
                    value={selectedInventorAssembly || ""}
                    onChange={(newValue: string) => setSelectedInventorAssembly(newValue)}
                    stylesheet={assemblySelectorStyles}
                    disabled={inventorAssemblies.length === 0} />

                <Spacer spacing="l" />
                <Label variant="top">Custom component type:</Label>
                <Dropdown
                    options={customComponentTypes}
                    value={componentType}
                    onChange={setComponentType} />

                <div className="buttonsContainer" style={{ position: "absolute", bottom: 16, right: 24 }}>
                    <Button
                        size="standard"
                        title="Create"
                        type="primary"
                        onClick={uploadCustomComponent}
                        disabled={sourceFileName === "" || selectedInventorAssembly === undefined || selectedInventorAssembly === ""} />
                    <div style={{ width: '14px' }} />
                    <Button
                        id="cancel_button"
                        size="standard"
                        title="Cancel"
                        type="secondary"
                        onClick={closeUploadDialog} />
                </div>
            </div>
        </Modal>

        {uploading && ReactDOM.createPortal(<ProcessingPane
            isVisible={true}
            message="Creating a custom component..."
            title="Custom component"
        />, document.getElementById("root")!)}

        {errorMessage && ReactDOM.createPortal(<ProcessingFailure
            title="Failed to create a custom component"
            message={errorMessage}
            closeProcessingErrorDialog={() => setErrorMessage(undefined)}
            isVisible={true} />, document.getElementById("root")!)}
    </>
}

type CustomComponentType = "Panel" | "Corner" | "Z-Shaped";

const customComponentTypes: CustomComponentType[] = ["Panel", "Corner", "Z-Shaped"];

const modalStyles = (styles: any) => {
    return {
        ...styles,
        modal: {
            ...styles.modal,
            window: { // by design
                ...styles.modal.window,
                width: "570px",
                height: "427px"
            },
            bodyContent: {
                ...styles.modal.bodyContent,
                overflow: "hidden" // no scrollbar
            }
        }
    }
};

const assemblySelectorStyles = (styles: any) => {
    return {
        ...styles,
        menu: {
            ...styles.menu,
            maxHeight: "180px"
        }
    }
}