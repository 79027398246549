import { isAlmostEqual } from "@dextall/shared";
import { FaceBoundaryLoop } from "./faceBoundaryLoop";
import { ModelElementFace } from "./modelElementFace";

export class OuterBoundaryLoopsFilter {
    private readonly allBoundaryLoops: FaceBoundaryLoop[];

    constructor(
        private readonly normal: THREE.Vector3,
        private readonly origin: THREE.Vector3, 
        faces: ModelElementFace[]) {
        this.allBoundaryLoops = faces.flatMap(x => x.getBoundaryLoops()).filter(x => x.isCounterClockwise());
    }

    findOuterBoundaryLoops(): FaceBoundaryLoop[] {
        const boundaryLoops = [];

        if (this.allBoundaryLoops.length === 0)
            return [];

        const currentLoops = this.allBoundaryLoops.filter(x => x.face.isPanelWallFace(this.normal, this.origin));

        if (currentLoops.length === 0) {
            const compareLoops = (x: FaceBoundaryLoop, y: FaceBoundaryLoop) => {
                const xDistance = Math.abs(x.face.getSignedDistanceTo(this.origin));
                const yDistance = Math.abs(y.face.getSignedDistanceTo(this.origin));

                if (isAlmostEqual(xDistance, yDistance))
                    return 0;

                return xDistance < yDistance ? -1 : 1;
            }

            const nearestLoop = this.allBoundaryLoops
                .filter(x => x.face.isCollinearToPlane(this.normal) || x.face.isCollinearToPlane(new THREE.Vector3().copy(this.normal).negate()))
                .sort(compareLoops)[0];

            if (!nearestLoop)
                return [];

            currentLoops.push(nearestLoop);
        }

        const traversedLoops = new Set();

        while (currentLoops.length > 0) {
            const currentLoop = currentLoops.pop()!;

            if (traversedLoops.has(currentLoop.key))
                continue;

            traversedLoops.add(currentLoop.key);

            boundaryLoops.push(currentLoop);

            const neighbours = this.findNeighbours(currentLoop).filter(x => !traversedLoops.has(x.key));

            for (const neighbour of neighbours)
                currentLoops.push(neighbour);
        }

        return boundaryLoops;
    }

    private findNeighbours(boundaryLoop: FaceBoundaryLoop) {
        return this.allBoundaryLoops.filter(x => x.isNeighbourLoop(boundaryLoop))
    }
}