import { GizmoBase } from "../../gizmos/gizmoBase";

export type GizmoDirections = [THREE.Vector3, THREE.Vector3] | [THREE.Vector3, THREE.Vector3, THREE.Vector3];

export class CustomComponentInsertionPointGizmo extends GizmoBase {
    constructor(viewer: Autodesk.Viewing.Viewer3D, private readonly directions: GizmoDirections, private readonly position: THREE.Vector3, mesh: THREE.Mesh) {
        const includeAxis = directions.length === 3 ? "XYZ" : "XY";

        const transformControl = new THREE.TransformControls(viewer.impl.camera, viewer.impl.canvas, "translate", includeAxis);

        super(transformControl, mesh);

        const matrix = createCoordinateSystem(position, directions);

        this.mesh.applyMatrix(new THREE.Matrix4().getInverse(mesh.matrix).multiply(matrix));

        const boundingBox = viewer.model.getBoundingBox();
        this.transformControl.setSize(boundingBox.getBoundingSphere().radius * 5);
        this.transformControl.attach(this.mesh);
        this.transformControl.setSpace("local");
    }

    restore() {
        this.apply(createCoordinateSystem(this.position, this.directions));
    }

    setPosition(position: THREE.Vector3) {
        this.position.copy(position);

        this.restore();
    }

    dispose() {

    }
}

const createCoordinateSystem = (position: THREE.Vector3, directions: GizmoDirections): THREE.Matrix4 => {
    const basisX = directions[0];
    const basisY = directions[1];
    const basisZ = directions[2] || new THREE.Vector3().crossVectors(basisX, basisY);

    return new THREE.Matrix4()
        .makeBasis(basisX, basisY, basisZ)
        .setPosition(position);
}