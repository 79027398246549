export class CustomComponentFit {
    constructor(private readonly viewer: Autodesk.Viewing.Viewer3D) {

    }

    computeDistancesToFit(): THREE.Vector3 {
        const boundingBox = this.viewer.impl.get3DBounds([{ model: this.viewer.model, selection: [] }]);
        const size = boundingBox.getSize();

        const fov = this.viewer.navigation.getVerticalFov();
        const fovHalfWorldWidth = Math.tan(THREE.Math.degToRad(fov * 0.5));

        const aspect = 1.0 / this.viewer.getCamera().aspect;

        //xz plane (plan)
        const fitYOffset = 0.5 * Math.max(aspect * size.x, size.z) / fovHalfWorldWidth;
        const planWidthTan = 0.5 * aspect * size.x / fitYOffset;

        //xy plane (front)
        const fitZOffset = 0.5 * Math.max(aspect * size.x, size.y) / fovHalfWorldWidth;
        const frontWidthTan = 0.5 * aspect * size.x / fitZOffset;
        const frontHeightTan = 0.5 * size.y / fitZOffset;

        //yz plane (side)
        const fitXOffset = 0.5 * Math.max(aspect * size.y, size.z) / fovHalfWorldWidth;
        const sideWidthTan = 0.5 * aspect * size.y / fitXOffset;

        const extraCoefficient = 1.0 + (2.0 * this.viewer.navigation.FIT_TO_VIEW_VERTICAL_MARGIN / (1.0 - 2.0 * this.viewer.navigation.FIT_TO_VIEW_VERTICAL_MARGIN));

        const maxYOffset = 0.5 * extraCoefficient * aspect * size.x / Math.min(planWidthTan, frontWidthTan);
        const maxZOffset = 0.5 * extraCoefficient * size.y / Math.min(sideWidthTan, frontHeightTan);
        const maxXOffset = 0.5 * extraCoefficient * size.y / Math.min(planWidthTan, frontHeightTan);

        return new THREE.Vector3(maxXOffset, maxYOffset, maxZOffset);
    }
}