import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { getLeftHandedTransformForViewer } from "./matricesUtils";

type Geometry = {
    geometry: THREE.BufferGeometry;
    matrix: THREE.Matrix4;
    material: THREE.Material;
}

type BoxGeometryRegistration = {
    type: "box";
    size: THREE.Vector3;
}

type ShapeExtrusionGeometryRegistration = {
    type: "shape"
}

type NamedShapeExtrusionGeometryRegistration = {
    type: "named-shape";
    name: string;
    size: THREE.Vector3;
}

export type BoxExtrusionGeometry = Geometry & BoxGeometryRegistration;

export type ShapeExtrusionGeometry = Geometry & ShapeExtrusionGeometryRegistration;

export type NamedShapeExtrusionGeometry = Geometry & NamedShapeExtrusionGeometryRegistration

export type ExtrusionGeometryRegistration = BoxGeometryRegistration | ShapeExtrusionGeometryRegistration | NamedShapeExtrusionGeometryRegistration;

export type ExtrusionGeometry = Geometry & ExtrusionGeometryRegistration;

type ExtrusionRegistrationOptions = {
    thickness: number;
    geometryGroupName?: string;
}

type ExtrusionCreationOptions = ExtrusionRegistrationOptions & {
    material: THREE.Material;
    zOffset?: number;
}

export class CladdingCellExtrusionGeometryFactory {
    createCladdingCellExtrusion(boundaryLoop: BoundaryLoop, options: ExtrusionCreationOptions): ExtrusionGeometry {
        return boundaryLoop.isSimple()
            ? this.createBox(boundaryLoop, options)
            : this.createExtrusion(boundaryLoop, options);
    }

    createExtrusionRegistration(boundaryLoop: BoundaryLoop, options: ExtrusionRegistrationOptions): ExtrusionGeometryRegistration {
        const box = boundaryLoop.box.getSize();

        if (boundaryLoop.isSimple())
            return { type: "box", size: new THREE.Vector3(box.x, box.y, options.thickness) };

        if (options.geometryGroupName)
            return { type: "named-shape", name: options.geometryGroupName, size: new THREE.Vector3(box.x, box.y, options.thickness) };

        return { type: "shape" };
    }

    private createExtrusion(boundaryLoop: BoundaryLoop, options: ExtrusionCreationOptions): ShapeExtrusionGeometry | NamedShapeExtrusionGeometry {
        const boundaryLoopMatrix = getLeftHandedTransformForViewer(boundaryLoop, options.zOffset || 0);

        const center = boundaryLoop.box.getCenter();

        const boxMatrix = new THREE.Matrix4().setPosition(center);

        const matrix = new THREE.Matrix4().multiplyMatrices(boundaryLoopMatrix, boxMatrix);

        const geometry = this.createBoundaryLoopExtrusionGeometry(boundaryLoop, options.thickness);

        if (options.geometryGroupName) {
            const box = boundaryLoop.box.getSize();

            return {
                type: "named-shape",
                geometry,
                matrix,
                material: options.material,
                name: options.geometryGroupName,
                size: new THREE.Vector3(box.x, box.y, options.thickness)
            }
        }

        return {
            geometry,
            matrix,
            material: options.material,
            type: "shape"
        };
    }

    private createBox(boundaryLoop: BoundaryLoop, options: ExtrusionCreationOptions): BoxExtrusionGeometry {
        const boundaryLoopMatrix = getLeftHandedTransformForViewer(boundaryLoop, options.zOffset || 0);

        const center = boundaryLoop.box.getCenter().add(new THREE.Vector3(0, 0, 0.5 * options.thickness));

        const boxMatrix = new THREE.Matrix4().setPosition(center);

        const matrix = new THREE.Matrix4().multiplyMatrices(boundaryLoopMatrix, boxMatrix);

        const size = boundaryLoop.box.getSize();
        size.z = options.thickness;

        const geometry = new THREE.BoxBufferGeometry(size.x, size.y, size.z);

        geometry.setAttribute("boxSize", createBoxSizeAttribute(size, geometry.attributes.position.count));

        return {
            geometry,
            matrix,
            material: options.material,
            size: new THREE.Vector3(size.x, size.y, options.thickness),
            type: "box"
        };
    }

    private createBoundaryLoopExtrusionGeometry(boundaryLoop: BoundaryLoop, thickness: number) {
        const center = boundaryLoop.box.getCenter();

        const matrix = new THREE.Matrix4().getInverse(new THREE.Matrix4().setPosition(center));

        const shape = boundaryLoop.createShape(matrix);

        const extrudeSettings = {
            steps: 1,
            amount: thickness,
            bevelEnabled: false
        };

        const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);

        return new THREE.BufferGeometry().fromGeometry(geometry);
    }
}

export const createBoxSizeAttribute = (boxSize: THREE.Vector3, count: number): THREE.Float32BufferAttribute => {
    const sizes = new Float32Array(count * 3);

    for (let i = 0; i < count; ++i) {
        sizes[3 * i] = boxSize.x;
        sizes[3 * i + 1] = boxSize.y;
        sizes[3 * i + 2] = boxSize.z;
    }

    return new THREE.Float32BufferAttribute(sizes, 3, false);
}

export const toMesh = (geometry: ExtrusionGeometry) => {
    const mesh = new THREE.Mesh(geometry.geometry, geometry.material);

    mesh.applyMatrix(geometry.matrix);

    return mesh;
}