import { ZShapeCorner } from "../panels/z-shape/zShapeCorner";
import { ICornerSelectionAimGeometryMaterials } from "./cornerSelectionAimGeometryMaterials";
import { ViewerOverlayCornerSelectorGeometry } from "./viewerOverlayCornerSelectorGeometry";

export class ViewerOverlayZShapeSelectorGeometry {
    private readonly cornerSelectorGeometry: ViewerOverlayCornerSelectorGeometry;

    constructor(public readonly corner: ZShapeCorner, public readonly heightIndex: number, viewer: Autodesk.Viewing.Viewer3D,
        materials: ICornerSelectionAimGeometryMaterials, drawOverlayName: string, debugCornerDirections: boolean) {
        this.cornerSelectorGeometry = new ViewerOverlayCornerSelectorGeometry(viewer, corner.wallCorner, heightIndex, materials, drawOverlayName, debugCornerDirections);
    }

    draw() {
        this.cornerSelectorGeometry.draw();
    }

    intersects(ray: THREE.Ray): boolean {
        return this.cornerSelectorGeometry.intersects(ray);
    }

    setSelected(selected: boolean) {
        this.cornerSelectorGeometry.setSelected(selected);
    }
}