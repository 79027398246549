import { ICoordinateSystem } from "./coordinateSystem";

export interface IWallFace {
    id: string;
    wallUniqueId: string;
    grids: string[];
    levels: string[];
    transform: ICoordinateSystem;
    boundary: THREE.Vector3[];
    openings: IWallFaceOpening[];
    box: Box;
    hasModifiedOpenings: boolean;
}

export enum ModelWallFaceOpeningEditState {
    Original = 0,

    Removed = 1,

    CreatedInStudio = 2,

    ChangedInStudio = 3,

    RemovedCreatedInStudio = 4
}

export interface IWallFaceOpening {
    id: string;
    boundary: THREE.Vector3[];
    editState: ModelWallFaceOpeningEditState;
    box: THREE.Box3;
    originalBox: THREE.Box3 | null;
}

export type Box = {
    min: { x: number, y: number },
    max: { x: number, y: number },
}

export interface IWallWindowSource {
    id: string;
    wallUniqueId: string;
    wallFaceId: string;
    openingId: string;
    box: Box;
    insideColor: number;
    outsideColor: number;
    windowSillColor: number;
    profileStyle: WindowProfileStyle;
    cells: IWallWindowCellSource[];
    insulatedGlassUnitId: string | null;
    userUniqueId: number | null;
}

export interface IWallWindowCellSource {
    width: number;
    height: number;
    offset: THREE.Vector2;
    cellStyle: WindowCellStyle;
    handlePosition: PanelWindowHandlePosition;
}

export interface IWallWindowTemplateSource {
    id: string;
    name: string;
    insideColor: number;
    outsideColor: number;
    windowSillColor: number;
    profileStyle: WindowProfileStyle;
    previewImageUrl: string;
    isDefault: string;
    cells: IWallWindowCellSource[];
}

export enum WindowProfileStyle {
    Vertical = 1,

    Horizontal = 2
}

export enum WindowCellStyle {
    Fixed = 1,

    Casement = 2,

    Hopper = 3,

    DualAction = 4
}

export enum PanelWindowHandlePosition {
    None = 0,

    Top = 1,

    Bottom = 2,

    Left = 3,

    Right = 4
}