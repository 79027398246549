import { zFightingFixDistance } from "@dextall/panels-defaults";
import { ICustomPanelSource } from "../../../responses/customPanelSource";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { editorCladdingGeometryThickness } from "../defaults";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { UpdateCustomPanelOffsetCommand } from "../../../commands/updateCustomPanelOffsetCommand";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import customPanelsColorFactory from "../colors/customComponentColorsFactory";

export class CustomPanel {
    private readonly thickness = editorCladdingGeometryThickness;
    private readonly material = customPanelsColorFactory.getDefaultMaterial();

    constructor(private readonly panelSource: ICustomPanelSource,
        private _panelBoundaryLoop: BoundaryLoop,
        public readonly panelDbId: number,
        private readonly claddingExtrusionFactory: CladdingCellExtrusionGeometryFactory) {

    }

    get id(): string {
        return this.panelSource.id;
    }

    get customPanelTypeId(): string {
        return this.panelSource.customPanelTypeId;
    }

    get wallFaceId(): string {
        return this.panelSource.wallFaceId;
    }

    get length(): number {
        return this.panelSource.length;
    }

    get height(): number {
        return this.panelSource.height;
    }

    get offsetX(): number {
        return this.panelSource.offsetX;
    }

    get offsetY(): number {
        return this.panelSource.offsetY;
    }

    get elementName(): string {
        return this.panelSource.elementName;
    }

    get userUniqueId(): number {
        return this.panelSource.userUniqueId;
    }

    get panelBoundaryLoop(): BoundaryLoop {
        return this._panelBoundaryLoop;
    }

    createGeometry(): ExtrusionGeometry {
        return this.claddingExtrusionFactory.createCladdingCellExtrusion(this.panelBoundaryLoop, {
            thickness: this.thickness,
            material: this.material
        });
    }

    computePanelTypeTitleMatrix(titleSize: THREE.Vector3): THREE.Matrix4 {
        const panelTransform = this.panelBoundaryLoop.transform;

        const panelBox = this.panelBoundaryLoop.box;

        const position = new THREE.Vector3(
            0.5 * (panelBox.min.x + panelBox.max.x - titleSize.x),
            0.5 * (panelBox.min.y + panelBox.max.y - titleSize.y),
            editorCladdingGeometryThickness + zFightingFixDistance
        ).applyMatrix4(panelTransform);

        return new THREE.Matrix4().copy(panelTransform).setPosition(position);
    }

    getPanelGeometryCoordinateSystem(): THREE.Matrix4 {
        const { transform, box } = this.panelBoundaryLoop;

        const position = box.getCenter().add(new THREE.Vector3(0, 0, 0.5 * editorCladdingGeometryThickness)).applyMatrix4(transform);

        return new THREE.Matrix4().copy(transform).setPosition(position);
    }

    getOffsetGizmoCoordinateSystem(): THREE.Matrix4 {
        const transform = this.panelBoundaryLoop.transform;

        const localPosition = this.panelBoundaryLoop.getBottomLeftCorner();
        localPosition.z = 0.5 * editorCladdingGeometryThickness;

        const position = localPosition.applyMatrix4(transform);

        return new THREE.Matrix4().multiplyMatrices(transform, new THREE.Matrix4().makeRotationZ(Math.PI)).setPosition(position);
    }

    setPanelType(targetPanelTypeId: string) {
        this.panelSource.customPanelTypeId = targetPanelTypeId;
    }

    setElementName(elementName: string) {
        this.panelSource.elementName = elementName;
    }

    setOffset(offset: UpdateCustomPanelOffsetCommand) {
        const { offsetX, offsetY } = offset;
        this.panelSource.offsetX = offsetX;
        this.panelSource.offsetY = offsetY;

        const box = new THREE.Box3(
            new THREE.Vector3(offsetX, offsetY, 0),
            new THREE.Vector3(offsetX + this.length, offsetY + this.height, 0)
        );

        this._panelBoundaryLoop = boundaryLoopsFactory.createLoopFromBox(box, this.panelBoundaryLoop.transform);
    }
}

const boundaryLoopsFactory = new BoundaryLoopsFactory();