import { IWallFace } from "../../../responses/wallFace";
import { isAlmostEqualOrLessThan, isMoreThan } from "@dextall/shared";
import { editorCladdingGeometryThickness } from "../defaults";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import { HooksModelEditor } from "../editors/hooksModelEditor";
import { CornerHook } from "../panels/cornerHook";
import { Hook } from "../panels/hook";
import { getWallFaceTransform } from "../panels/hooksCoordinateSystems";
import { alignmentToolCursor } from "./toolCursors";
import { ToolInterface } from "./toolInterface";
import eventBus from "../eventBus/eventDispatcher";

export const hooksAlignmentToolName = "dextall-hooks-alignment-tool";
const overlayName = "dextall-hooks-alignment-tool-overlay-name";
const dashedLineMaterialName = "dextall-hooks-alignment-tool-dashed-line"

export class HooksAlignmentTool extends ToolInterface {
    private readonly wallFaces = new Map<string, IWallFace>();
    private readonly hookIds: number[] = [];
    private viewer: Autodesk.Viewing.GuiViewer3D | null = null;
    private material: THREE.LineDashedMaterial | null = null;
    private offset = new THREE.Vector3();
    private suspended: boolean = false;
    private selectionHook: SelectionHook | null = null;
    private selectionLine: THREE.Line | null = null;
    private referenceHook: ReferenceHook | null = null;
    private referenceLine: THREE.Line | null = null;

    constructor(private readonly hooksEditor: HooksModelEditor, modelFaces: IWallFace[]) {
        super();
        this.names = [hooksAlignmentToolName];

        for (const wallFace of modelFaces)
            this.wallFaces.set(wallFace.id, wallFace);
    }

    getPriority(): number {
        return 42;
    }

    getCursor(): string | null {
        return alignmentToolCursor;
    }

    activate(_name: string, viewer: Autodesk.Viewing.GuiViewer3D): void {
        this.viewer = viewer;
        viewer.impl.createOverlayScene(overlayName);
        viewer.setAggregateSelection([]);

        this.material = new THREE.LineDashedMaterial({ color: 0x0000ff, linewidth: 2, scale: 1, dashSize: 0.5, gapSize: 0.25, });

        viewer.impl.matman().addMaterial(dashedLineMaterialName, this.material!, true);

        this.offset = new THREE.Vector3().copy(viewer.model.getGlobalOffset()).negate();
        this.suspended = false;
        this.hookIds.splice(0, 0, ...this.hooksEditor.getHookIds());
    }

    deactivate(): void {
        this.clearSelectionLine();
        this.clearReferenceLine();
        this.viewer?.impl.removeOverlayScene(overlayName);
        this.viewer?.impl.matman().removeMaterial(dashedLineMaterialName);
        this.viewer = null;
        this.suspended = false;
        this.referenceHook = null;
        this.selectionHook = null;
        this.selectionLine = null;
        this.hookIds.length = 0;
    }

    handleButtonDown(_event: MouseEvent, _button: number) {
        this.suspended = true;
        return false;
    }

    handleButtonUp(_event: MouseEvent, _button: number) {
        this.suspended = false;
        return false;
    }

    handleMouseMove(event: MouseEvent) {
        if (this.suspended || !this.viewer)
            return false;

        const selectionHook = this.findHook(event);

        if (areSelectionHooksEqual(selectionHook, this.selectionHook))
            return false;

        this.selectionHook = selectionHook;

        this.drawSelectionLine();

        return false;
    }

    handleSingleClick(_event: MouseEvent, button: number): boolean {
        if (!(this.viewer && this.selectionHook && button === 0))
            return false;

        if (this.referenceHook === null) {
            const alignableHookIds = new Set<number>(this.findAlignableHookIds(this.selectionHook));

            this.referenceHook = { ...this.selectionHook, alignableHookIds };

            this.drawReferenceLine();

            return true;
        }

        if (this.referenceHook && this.selectionHook) {
            this.alignHook(this.selectionHook.hook, this.referenceHook.hook, this.referenceHook.type);

            this.selectionHook = null;

            this.drawSelectionLine();

            return true;
        }

        return false;
    }

    handleKeyDown(event: KeyboardEvent, _keyCode: number): boolean {
        if (this.viewer && event.code === "Space" && this.selectionHook && !this.referenceHook) {
            this.selectionHook.type = this.selectionHook.type === "horizontal" ? "vertical" : "horizontal";

            this.drawSelectionLine();

            return true;
        }

        if (this.viewer && event.code === "Escape" && this.referenceHook) {
            this.referenceHook = null;

            this.drawReferenceLine();

            return true;
        }

        return false;
    }

    private alignHook(hook: Hook | CornerHook, referenceHook: Hook | CornerHook, alignmentType: ReferenceType) {
        const hookLocalPosition = new THREE.Vector3()
            .setFromMatrixPosition(referenceHook.matrix)
            .applyMatrix4(new THREE.Matrix4().getInverse(hook.originMatrix));

        const x = alignmentType === "horizontal" ? hook.x : hookLocalPosition.x;
        const y = alignmentType === "vertical" ? hook.y : hookLocalPosition.y;

        hook.setPosition(x, y);

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.UpdateHookGeometry",
            payload: hook
        });

        eventBus.dispatchEvent({
            type: "Dextall.Hooks.ForceSave",
            payload: hook
        });
    }

    private findAlignableHookIds(selectionHook: SelectionHook): number[] {
        const selectedHook = selectionHook.hook;

        const selectedHookWorldPosition = new THREE.Vector3().setFromMatrixPosition(selectedHook.matrix);

        return this.hooksEditor
            .findWallFaceHooks(selectedHook.wallFaceId)
            .filter(x => x.dbId !== selectedHook.dbId)
            .filter(x => this.canAlignHook(x, selectedHookWorldPosition, selectionHook.type))
            .map(x => x.dbId);
    }

    private canAlignHook(hook: Hook | CornerHook, point: THREE.Vector3, alignmentType: ReferenceType) {
        const localPoint = new THREE.Vector3().copy(point).applyMatrix4(new THREE.Matrix4().getInverse(hook.originMatrix));

        if (alignmentType === "horizontal")
            return isMoreThan(localPoint.y, 0) && isAlmostEqualOrLessThan(localPoint.y, hook.maxY);

        return isMoreThan(localPoint.x, 0) && isAlmostEqualOrLessThan(localPoint.x, hook.maxX);
    }

    private findHook(event: MouseEvent): SelectionHook | null {
        const hitTest = this.viewer!.impl.hitTest(event.canvasX, event.canvasY, true, this.hookIds, [this.hooksEditor.hooksModelId]);

        if (!hitTest)
            return null;

        const hook = this.hooksEditor.findHook(hitTest.dbId);

        if (!hook)
            return null;

        const localPoint = new THREE.Vector3().copy(hitTest.intersectPoint).applyMatrix4(new THREE.Matrix4().getInverse(hook.matrix));

        if (this.referenceHook !== null && !this.referenceHook.alignableHookIds.has(hook.dbId))
            return null;

        const type: ReferenceType = this.referenceHook?.type || (localPoint.x < localPoint.y ? "vertical" : "horizontal");

        return { hook, type };
    }

    private drawSelectionLine() {
        this.clearSelectionLine();

        if (!this.selectionHook)
            return;

        this.selectionLine = createSelectionLine(this.selectionHook, this.material!);

        this.viewer?.impl.addOverlay(overlayName, this.selectionLine);

        this.viewer?.impl.invalidate(true, false, true);
    }

    private drawReferenceLine() {
        this.clearReferenceLine();

        if (!this.referenceHook)
            return;

        this.referenceLine = this.createReferenceLine(this.referenceHook);

        this.viewer?.impl.addOverlay(overlayName, this.referenceLine);

        this.viewer?.impl.invalidate(true, false, true);
    }

    private clearSelectionLine() {
        if (!this.selectionLine)
            return;

        this.clearLine(this.selectionLine);

        this.selectionLine = null;
    }

    private clearReferenceLine() {
        if (!this.referenceLine)
            return;

        this.clearLine(this.referenceLine);

        this.referenceLine = null;
    }

    private clearLine(line: THREE.Line) {
        this.viewer?.impl.removeOverlay(overlayName, line);

        this.viewer?.impl.invalidate(true, false, true);
    }

    private createReferenceLine(referenceHook: ReferenceHook): THREE.Line {
        const hook = referenceHook.hook;

        const wallFace = this.wallFaces.get(hook.alignmentWallFaceId);

        if (!wallFace)
            throw new Error("Invalid state!");

        const wallFaceTransform = getWallFaceTransform(wallFace.transform, this.offset);

        const hookPositionInWallFace = new THREE.Vector3().setFromMatrixPosition(hook.matrix).applyMatrix4(new THREE.Matrix4().getInverse(wallFaceTransform));

        const startLocalPoint = new THREE.Vector3(
            referenceHook.type === "horizontal" ? wallFace.box.min.x : hookPositionInWallFace.x,
            referenceHook.type === "vertical" ? wallFace.box.min.y : hookPositionInWallFace.y,
            editorCladdingGeometryThickness + 2 * zFightingFixDistance);

        const endLocalPoint = new THREE.Vector3(
            referenceHook.type === "horizontal" ? wallFace.box.max.x : hookPositionInWallFace.x,
            referenceHook.type === "vertical" ? wallFace.box.max.y : hookPositionInWallFace.y,
            editorCladdingGeometryThickness + 2 * zFightingFixDistance);

        const geometry = new THREE.Geometry();

        geometry.vertices.push(startLocalPoint.applyMatrix4(wallFaceTransform));
        geometry.vertices.push(endLocalPoint.applyMatrix4(wallFaceTransform));

        geometry.computeLineDistances();
        geometry.lineDistancesNeedUpdate = true;

        return new THREE.Line(geometry, this.material!);
    }
}

const areSelectionHooksEqual = (a: SelectionHook | null, b: SelectionHook | null) => {
    if (a === null && b === null)
        return true;

    if (a === null || b === null)
        return false

    return a.type === b.type && a.hook.dbId === b.hook.dbId;
}

const selectionLineLength = 2;

const createSelectionLine = (selectionHook: SelectionHook, material: THREE.LineDashedMaterial): THREE.Line => {
    const geometry = new THREE.Geometry();

    const transform = selectionHook.hook.matrix;

    const startLocalPoint = new THREE.Vector3(
        selectionHook.type === "horizontal" ? -0.5 * selectionLineLength : 0,
        selectionHook.type === "vertical" ? -0.5 * selectionLineLength : 0, 0);

    const endLocalPoint = new THREE.Vector3().copy(startLocalPoint).multiplyScalar(-1);

    geometry.vertices.push(startLocalPoint.applyMatrix4(transform));
    geometry.vertices.push(endLocalPoint.applyMatrix4(transform));

    geometry.computeLineDistances();
    geometry.lineDistancesNeedUpdate = true;

    return new THREE.Line(geometry, material);
}

type ReferenceType = "horizontal" | "vertical";

type SelectionHook = {
    hook: Hook | CornerHook;
    type: ReferenceType
}

type ReferenceHook = SelectionHook & {
    alignableHookIds: Set<number>;
}