import { OperationCompletedResults, WorkerContentType } from "./generatedPanelContentWorker";
import { IPanelGeneratedModelDto, PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import { BasicItemResponse } from "../../../responses/basicResponses";
import { IPanelGeneratedDrawingDto } from "../../../responses/panelGeneratedDrawingDto";
import { GeneratedPanelModelLoadedPayload } from "../eventBus/generatedModelsEventPayloads";
import repo from "../../../Repository";
import eventBus from "../eventBus/eventDispatcher";

export class GeneratedPanelContent {
    private readonly worker: Worker;
    private currentProcessingPanelTypeId: string | undefined;

    constructor(private readonly workerType: WorkerContentType) {
        this.worker = new Worker(new URL("./generatedPanelContentWorker.ts", import.meta.url), { type: "module" });

        this.workerJobDone = this.workerJobDone.bind(this);
        this.worker.onmessage = this.workerJobDone;

        this.worker.postMessage({ type: "initialize", accessToken: repo.getAccessToken(), workerType });
    }

    loadPanelTypeGeneratedModel(panelTypeId: string) {
        this.currentProcessingPanelTypeId = panelTypeId;
        this.worker.postMessage({ type: "load-generated-model", panelTypeId });
    }

    abortCheck(panelTypeId?: string) {
        const panelTypeToAbort = panelTypeId || this.currentProcessingPanelTypeId;

        if (!panelTypeToAbort)
            return;

        this.worker.postMessage({ type: "abort-model-generation-check", panelTypeId: panelTypeToAbort });

        if (this.currentProcessingPanelTypeId === panelTypeToAbort)
            this.currentProcessingPanelTypeId = undefined;
    }

    private async workerJobDone(e: MessageEvent<OperationCompletedResults>) {
        const results = e.data;

        if (results.type === "loaded"
            && results.result.isSuccess
            && results.result.item.status === PanelTypeGenerationStatus.ModelGenerationInProgress
            && results.panelTypeId === this.currentProcessingPanelTypeId) {
            this.worker.postMessage({ type: "check-model-generation", panelTypeId: results.panelTypeId });
        }

        switch (results.type) {
            case "loaded": {
                await this.dispatchContentLoadedEvent(results);
                break;
            }

            case "generated": {
                await this.dispatchContentGeneratedEvent(results);

                this.currentProcessingPanelTypeId = undefined;
                break;
            }

            default:
                break;
        }
    }

    private async dispatchContentLoadedEvent(results: OperationCompletedResults) {
        const payload = await this.createEventPayload(results);

        if (this.workerType === "panels-generator")
            eventBus.dispatchEvent({ type: "Dextall.Panels.GeneratedModel.Loaded", payload });
        else
            eventBus.dispatchEvent({ type: "Dextall.Corners.GeneratedModel.Loaded", payload });
    }

    private async dispatchContentGeneratedEvent(results: OperationCompletedResults) {
        const payload = await this.createEventPayload(results);

        if (this.workerType === "panels-generator")
            eventBus.dispatchEvent({ type: "Dextall.Panels.GeneratedModel.Generated", payload });
        else
            eventBus.dispatchEvent({ type: "Dextall.Corners.GeneratedModel.Generated", payload });
    }

    private async createEventPayload(results: OperationCompletedResults): Promise<GeneratedPanelModelLoadedPayload> {
        const drawings = await this.findDrawings(results.result);

        const { result: { item: model }, panelTypeId } = results;

        return {
            model: model!,
            panelTypeId,
            drawings
        }
    }

    private async findDrawings(model: BasicItemResponse<IPanelGeneratedModelDto>): Promise<IPanelGeneratedDrawingDto[]> {
        if (!(model.isSuccess && model.item.status === PanelTypeGenerationStatus.ModelGeneratedSuccessfully))
            return [];

        const response = this.workerType === "panels-generator"
            ? await repo.findPanelTypeModelDrawings(model.item.id)
            : await repo.findCornerPanelTypeModelDrawings(model.item.id);

        return response.isSuccess ? response.items : [];
    }
}