import { isAlmostEqual } from "@dextall/shared";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { CornerHookType, IHookSource } from "../../../responses/hookSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { IPanelSource } from "../../../responses/panelSource";
import { IFamily } from "../../../responses/panelType";
import { SystemSettings } from "../../../responses/systemSettings";
import { GeneratedPanelContent } from "../generatedModels/generatedPanelContent";
import { ModelBuilderObjectIds } from "../ids/modelBuilderObjectIds";
import { Corner } from "./corner";
import { CornerHook } from "./cornerHook";
import { CornersFactory } from "./cornersFactory";
import { createPanelTypeHooksUpdater, createCornerPanelTypeHooksUpdater } from "./entityUpdater";
import { Hook } from "./hook";
import { HooksFactory, createCornerHookSource, createPanelHookSource } from "./hooksFactory";
import { IModelHook } from "./modelHook";
import { Panel } from "./panel";
import { PanelFamily } from "./panelFamily";
import { PanelType } from "./panelType";
import { PanelsFactory } from "./panelsFactory";
import { PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import { GeneratedHooksModelContent } from "../generatedModels/generatedHooksModelContent";
import { ICustomCornerType, ICustomPanelType, ICustomZShapeType } from "../../../responses/customPanelTypes";
import { ICustomPanelSource } from "../../../responses/customPanelSource";
import { ICustomCornerSource } from "../../../responses/customCornerSource";
import { ICustomZShapedPanelSource } from "../../../responses/customZShapedPanelSource";
import { CustomPanel } from "./customPanel";
import { CustomCorner } from "./customCorner";
import { CustomZShapedPanel } from "./customZShapedPanel";
import { WallFacesCollection } from "./wallFacesCollection";
import eventBus from "../eventBus/eventDispatcher";

export class PanelFacadeDocument {
    private readonly panelsFactory: PanelsFactory;
    private readonly cornersFactory: CornersFactory;
    private readonly hooksFactory: HooksFactory;
    private readonly panelTypesById = new Map<string, PanelType<IPanelSource>>();
    private readonly panelFamiliesByTypeId = new Map<string, PanelFamily<IPanelSource>>();
    private readonly cornerPanelTypesById = new Map<string, PanelType<ICornerPanelSource>>();
    private readonly cornerPanelFamiliesByTypeId = new Map<string, PanelFamily<ICornerPanelSource>>();
    private readonly panelsByDbId = new Map<number, Panel>();
    private readonly panelsById = new Map<string, Panel>();
    private readonly customPanelsByDbId = new Map<number, CustomPanel>();
    private readonly customPanelsById = new Map<string, CustomPanel>();
    private readonly customCornersByDbId = new Map<number, CustomCorner>();
    private readonly customCornersById = new Map<string, CustomCorner>();
    private readonly customZShapedPanelsByDbId = new Map<number, CustomZShapedPanel>();
    private readonly customZShapedPanelsById = new Map<string, CustomZShapedPanel>();
    private readonly cornersByDbId = new Map<number, Corner>();
    private readonly cornersById = new Map<string, Corner>();
    private readonly hooksByDbId = new Map<number, Hook | CornerHook>();
    private readonly hooksByPanel = new Map<string, (Hook | CornerHook)[]>();
    private readonly hooksByWallFace = new Map<string, (Hook | CornerHook)[]>();
    private readonly customPanelTypes = new Map<string, ICustomPanelType>();
    private readonly customCornerTypes = new Map<string, ICustomCornerType>();
    private readonly customZShapedTypes = new Map<string, ICustomZShapeType>();
    private readonly panelTypeHooksUpdater = createPanelTypeHooksUpdater();
    private readonly cornerPanelTypeHooksUpdater = createCornerPanelTypeHooksUpdater();
    private readonly generatedPanelContent = new GeneratedPanelContent("panels-generator");
    private readonly generatedCornerContent = new GeneratedPanelContent("corners-generator");
    private readonly hooksGeneratedModelContent: GeneratedHooksModelContent;

    constructor(public readonly modelId: string, panelFamilies: IFamily[], panels: IPanelSource[], cornerFamilies: IFamily[],
        cornerPanels: ICornerPanelSource[], viewer: Autodesk.Viewing.GuiViewer3D, wallFacesCollection: WallFacesCollection,
        wallCorners: IModelCorner[], customPanelTypes: ICustomPanelType[], customCornerTypes: ICustomCornerType[],
        customZShapedTypes: ICustomZShapeType[], customPanels: ICustomPanelSource[], customCorners: ICustomCornerSource[],
        customZShapedPanels: ICustomZShapedPanelSource[], systemSettings: SystemSettings,
        private readonly objectIds: ModelBuilderObjectIds) {
        this.hooksFactory = new HooksFactory(viewer, wallFacesCollection, wallCorners, objectIds);

        this.hooksGeneratedModelContent = new GeneratedHooksModelContent(modelId);

        for (const familySource of panelFamilies)
            fillPanelTypes<IPanelSource>(familySource, this.panelTypesById, this.panelFamiliesByTypeId);

        for (const familySource of cornerFamilies)
            fillPanelTypes<ICornerPanelSource>(familySource, this.cornerPanelTypesById, this.cornerPanelFamiliesByTypeId);

        for (const customPanelType of customPanelTypes)
            this.customPanelTypes.set(customPanelType.id, customPanelType);

        for (const customCornerType of customCornerTypes)
            this.customCornerTypes.set(customCornerType.id, customCornerType);

        for (const customZShapedType of customZShapedTypes)
            this.customZShapedTypes.set(customZShapedType.id, customZShapedType);

        this.panelsFactory = new PanelsFactory(viewer, wallFacesCollection, systemSettings, objectIds);

        for (const panel of panels)
            this.addPanel(panel);

        for (const panel of customPanels)
            this.addCustomPanel(panel);

        this.cornersFactory = new CornersFactory(viewer, wallCorners, wallFacesCollection, objectIds);

        for (const cornerPanel of cornerPanels)
            this.addCornerPanel(cornerPanel);

        for (const corner of customCorners)
            this.addCustomCorner(corner);

        for (const panel of customZShapedPanels)
            this.addCustomZShapedPanel(panel);

        for (const panel of this.panels)
            this.createPanelHooks(panel);

        for (const panel of this.cornerPanels)
            this.createCornerPanelHooks(panel);
    }

    get panelTypes(): PanelType<IPanelSource>[] {
        return Array.from(this.panelTypesById.values())
    }

    get cornerTypes(): PanelType<ICornerPanelSource>[] {
        return Array.from(this.cornerPanelTypesById.values());
    }

    get panels(): Panel[] {
        return this.panelTypes.flatMap(x => x.panels);
    }

    get customPanels(): CustomPanel[] {
        return Array.from(this.customPanelsByDbId.values());
    }

    get cornerPanels(): Corner[] {
        return this.cornerTypes.flatMap(x => x.panels);
    }

    get customCorners(): CustomCorner[] {
        return Array.from(this.customCornersByDbId.values());
    }

    get customZShapedPanels(): CustomZShapedPanel[] {
        return Array.from(this.customZShapedPanelsByDbId.values());
    }

    get hooks(): (Hook | CornerHook)[] {
        return Array.from(this.hooksByDbId.values());
    }

    findPanelType(panelTypeId: string): PanelType<IPanelSource> | undefined {
        return this.panelTypesById.get(panelTypeId);
    }

    findPanelFamily(panelTypeId: string): PanelFamily<IPanelSource> | undefined {
        return this.panelFamiliesByTypeId.get(panelTypeId);
    }

    findCornerPanelType(panelTypeId: string): PanelType<ICornerPanelSource> | undefined {
        return this.cornerPanelTypesById.get(panelTypeId);
    }

    findCornerPanelFamily(panelTypeId: string): PanelFamily<ICornerPanelSource> | undefined {
        return this.cornerPanelFamiliesByTypeId.get(panelTypeId);
    }

    findPanel(dbId: number): Panel | undefined {
        return this.panelsByDbId.get(dbId);
    }

    findPanelById(id: string): Panel | undefined {
        return this.panelsById.get(id);
    }

    findPanelByUserId(userUniqueId: number): Panel | undefined {
        return Array.from(this.panelsById.values()).find(x => x.userUniqueId === userUniqueId);
    }

    findCustomPanelByUserId(userUniqueId: number): CustomPanel | undefined {
        return this.customPanels.find(x => x.userUniqueId === userUniqueId);
    }

    findCustomPanel(dbId: number): CustomPanel | undefined {
        return this.customPanelsByDbId.get(dbId);
    }

    findCustomPanelById(id: string): CustomPanel | undefined {
        return this.customPanelsById.get(id);
    }

    findCustomCorner(dbId: number): CustomCorner | undefined {
        return this.customCornersByDbId.get(dbId);
    }

    findCustomCornerById(id: string): CustomCorner | undefined {
        return this.customCornersById.get(id);
    }

    findCustomCornerByUserId(userUniqueId: number): CustomCorner | undefined {
        return Array.from(this.customCornersByDbId.values()).find(x => x.userUniqueId === userUniqueId);
    }

    findCustomCornerByTypeName(typeName: string): CustomCorner | undefined {
        const searchTerm = typeName.trim().toUpperCase();

        const customCornerType = Array.from(this.customCornerTypes.values()).find(x => x.name.toUpperCase() === searchTerm);

        if (!customCornerType)
            return undefined;

        return this.customCorners.find(x => x.customPanelTypeId === customCornerType.id);
    }

    findCustomCornerByElementName(elementName: string): CustomCorner | undefined {
        const searchTerm = elementName.trim().toUpperCase();

        return this.customCorners.find(x => x.elementName.toUpperCase() === searchTerm);
    }

    findCustomZShapedPanel(dbId: number): CustomZShapedPanel | undefined {
        return this.customZShapedPanelsByDbId.get(dbId);
    }

    findCustomZShapedPanelById(id: string): CustomZShapedPanel | undefined {
        return this.customZShapedPanelsById.get(id);
    }

    findCustomZShapedPanelByUserId(userUniqueId: number): CustomZShapedPanel | undefined {
        return Array.from(this.customZShapedPanelsByDbId.values()).find(x => x.userUniqueId === userUniqueId);
    }

    findCustomZShapedPanelByTypeName(typeName: string): CustomZShapedPanel | undefined {
        const searchTerm = typeName.trim().toUpperCase();

        const customPanelType = Array.from(this.customZShapedTypes.values()).find(x => x.name.toUpperCase() === searchTerm);

        if (!customPanelType)
            return undefined;

        return this.customZShapedPanels.find(x => x.customPanelTypeId === customPanelType.id);
    }

    findCustomZShapedPanelByElementName(elementName: string): CustomZShapedPanel | undefined {
        const searchTerm = elementName.trim().toUpperCase();

        return Array.from(this.customZShapedPanels.values()).find(x => x.elementName.trim().toUpperCase() === searchTerm);
    }

    findPanelByElementName(elementName: string): Panel | undefined {
        const searchTerm = elementName.trim().toUpperCase();

        return Array.from(this.panelsById.values()).find(x => x.elementName.trim().toUpperCase() === searchTerm);
    }

    findPanelByTypeName(typeName: string): Panel | undefined {
        const searchTerm = typeName.trim().toUpperCase();

        return Array.from(this.panelTypesById.values())
            .filter(x => x.name.toUpperCase() === searchTerm)
            .flatMap(x => x.panels)
            .filter(x => !x.customPanelTypeId)
            .find(x => x);
    }

    findPanelByCustomTypeName(typeName: string): Panel | undefined {
        const searchTerm = typeName.trim().toUpperCase();

        const customPanelType = Array.from(this.customPanelTypes.values()).find(x => x.name.toUpperCase() === searchTerm);

        if (!customPanelType)
            return undefined;

        return this.panels.find(x => x.customPanelTypeId === customPanelType.id);
    }

    findCornerByCustomTypeName(typeName: string): Corner | undefined {
        const searchTerm = typeName.trim().toUpperCase();

        const customPanelType = Array.from(this.customCornerTypes.values()).find(x => x.name.toUpperCase() === searchTerm);

        if (!customPanelType)
            return undefined;

        return this.cornerPanels.find(x => x.customPanelTypeId === customPanelType.id);
    }

    findCorner(dbId: number): Corner | undefined {
        return this.cornersByDbId.get(dbId);
    }

    findCornerById(id: string): Corner | undefined {
        return this.cornersById.get(id);
    }

    findCornerByUserId(userUniqueId: number): Corner | undefined {
        return Array.from(this.cornersByDbId.values()).find(x => x.userUniqueId === userUniqueId);
    }

    findCornerByElementName(elementName: string): Corner | undefined {
        const searchTerm = elementName.trim().toUpperCase();

        return Array.from(this.cornersByDbId.values()).find(x => x.elementName.trim().toUpperCase() === searchTerm);
    }

    findCornerByTypeName(typeName: string): Corner | undefined {
        const searchTerm = typeName.trim().toUpperCase();

        return Array.from(this.cornerPanelTypesById.values())
            .filter(x => x.name.toUpperCase() === searchTerm)
            .flatMap(x => x.panels)
            .find(x => x);
    }

    findCustomPanelByTypeName(typeName: string): CustomPanel | undefined {
        const searchTerm = typeName.trim().toUpperCase();

        const customPanelType = Array.from(this.customPanelTypes.values()).find(x => x.name.toUpperCase() === searchTerm);

        if (!customPanelType)
            return undefined;

        return this.customPanels.find(x => x.customPanelTypeId === customPanelType.id);
    }

    findCustomPanelByElementName(elementName: string): CustomPanel | undefined {
        const searchTerm = elementName.trim().toUpperCase();

        return this.customPanels.find(x => x.elementName.toUpperCase() === searchTerm);
    }

    findHook(dbId: number): Hook | CornerHook | undefined {
        return this.hooksByDbId.get(dbId);
    }

    findWallFaceHooks(wallFaceId: string): (Hook | CornerHook)[] {
        return this.hooksByWallFace.get(wallFaceId) || [];
    }

    findPanelHooks(panelId: string): (Hook | CornerHook)[] {
        return this.hooksByPanel.get(panelId) || [];
    }

    findCustomPanelType(customPanelTypeId: string): ICustomPanelType | undefined {
        return this.customPanelTypes.get(customPanelTypeId);
    }

    findCustomCornerType(customPanelTypeId: string): ICustomCornerType | undefined {
        return this.customCornerTypes.get(customPanelTypeId);
    }

    findCustomZShapedPanelType(customPanelTypeId: string): ICustomZShapeType | undefined {
        return this.customZShapedTypes.get(customPanelTypeId);
    }

    findValidCustomPanelTypesForPanel(panelId: string): ICustomPanelType[] {
        const panel = this.findPanelById(panelId);

        if (!panel)
            return [];

        return this.findValidCustomPanelTypes(panel);
    }

    findValidCustomPanelTypesForCustomPanel(panelId: string): ICustomPanelType[] {
        const panel = this.findCustomPanelById(panelId);

        if (!panel)
            return [];

        return this.findValidCustomPanelTypes(panel);
    }

    findValidCustomCornerTypesForPanel(panelId: string): ICustomCornerType[] {
        const panel = this.findCornerById(panelId);

        if (!panel)
            return [];

        return this.findValidCustomCornerTypes(panel);
    }

    findValidCustomCornerTypesForCustomCorner(panelId: string): ICustomCornerType[] {
        const panel = this.findCustomCornerById(panelId);

        if (!panel)
            return [];

        const customCornerTypes: ICustomCornerType[] = [];

        for (const customCornerType of this.customCornerTypes.values())
            if (customCornerType.id === panel.customPanelTypeId
                || (isAlmostEqual(customCornerType.angle, panel.angle)
                    && isAlmostEqual(customCornerType.leftWingLength, panel.leftWing)
                    && isAlmostEqual(customCornerType.rightWingLength, panel.rightWing)
                    && isAlmostEqual(customCornerType.height, panel.height)))
                customCornerTypes.push(customCornerType);

        return customCornerTypes;
    }

    findValidCustomZShapePanelType(panelId: string): ICustomZShapeType[] {
        const panel = this.findCustomZShapedPanelById(panelId);

        if (!panel)
            return [];

        const customTypes: ICustomZShapeType[] = [];

        for (const customType of this.customZShapedTypes.values())
            if (customType.id === panel.customPanelTypeId
                || (isAlmostEqual(customType.angle1, panel.angle1)
                    && isAlmostEqual(customType.angle2, panel.angle2)
                    && isAlmostEqual(customType.leftWingLength, panel.leftWing)
                    && isAlmostEqual(customType.shelfLength, panel.shelf)
                    && isAlmostEqual(customType.rightWingLength, panel.rightWing)
                    && isAlmostEqual(customType.height, panel.height)
                ))
                customTypes.push(customType);

        return customTypes;
    }

    createNewPanelHook(panel: Panel, localPoint: THREE.Vector2): Hook[] {
        const hooks: Hook[] = [];

        const panelType = this.findPanelType(panel.panelTypeId);

        if (!panelType)
            return [];

        for (const panelInstance of panelType.panels) {
            const panelHooks = this.findPanelHooks(panelInstance.id) as Hook[];
            const hookInstance = this.hooksFactory.createNewPanelHook(panelInstance, localPoint);

            hooks.push(hookInstance);

            this.storePanelHooks(panelHooks.concat([hookInstance]), panelInstance);
        }

        panelType.addHook(createPanelHookSource(localPoint));

        this.panelTypeHooksUpdater.push(panelType, "create");

        this.onPanelTypeUpdated(panelType);

        return hooks;
    }

    createNewCornerPanelHook(corner: Corner, onLeftWing: boolean, localPoint: THREE.Vector2): CornerHook[] {
        const hooks: CornerHook[] = [];

        const panelType = this.findCornerPanelType(corner.panelTypeId);

        if (!panelType)
            return [];

        for (const panelInstance of panelType.panels) {
            const panelHooks = this.findPanelHooks(panelInstance.id) as CornerHook[];
            const hookInstance = this.hooksFactory.createNewCornerHook(panelInstance, onLeftWing, localPoint);

            hooks.push(hookInstance);

            this.storeCornerHooks(panelHooks.concat([hookInstance]), panelInstance);
        }

        panelType.addHook(createCornerHookSource(onLeftWing, localPoint));

        this.cornerPanelTypeHooksUpdater.push(panelType, "create");

        this.onCornerPanelTypeUpdated(panelType);

        return hooks;
    }

    removeHook(hook: Hook | CornerHook): number[] {
        return hook instanceof Hook
            ? this.removePanelHook(hook)
            : this.removeCornerHook(hook);
    }

    updateHook(hook: Hook | CornerHook): (Hook | CornerHook)[] {
        return hook instanceof Hook
            ? this.updatePanelHook(hook)
            : this.updateCornerPanelHook(hook);
    }

    updateHookFrom(hookData: IModelHook): (Hook | CornerHook)[] {
        const hook = this.findHook(hookData.dbId)!;

        hook.update(hookData);

        return this.updateHook(hook);
    }

    addPanelType(panelTypeId: string, sourcePanelTypeId: string, name: string) {
        const family = this.findPanelFamily(sourcePanelTypeId)!;

        const sourcePanelType = this.findPanelType(sourcePanelTypeId)!;

        const hooks: IHookSource[] = sourcePanelType.hooks.map(x => { return { x: x.x, y: x.y, hookType: x.hookType, cornerHookType: x.cornerHookType, profileSide: x.profileSide } });

        const newPanelType = new PanelType<IPanelSource>({ id: panelTypeId, name, hooks });

        family.addPanelType(newPanelType);

        this.panelTypesById.set(newPanelType.id, newPanelType);
        this.panelFamiliesByTypeId.set(newPanelType.id, family);
    }

    addCornerType(panelTypeId: string, sourcePanelTypeId: string, name: string) {
        const family = this.findCornerPanelFamily(sourcePanelTypeId)!;

        const sourcePanelType = this.findCornerPanelType(sourcePanelTypeId)!;

        const hooks: IHookSource[] = sourcePanelType.hooks.map(x => { return { x: x.x, y: x.y, hookType: x.hookType, cornerHookType: x.cornerHookType, profileSide: x.profileSide } });

        const newPanelType = new PanelType<ICornerPanelSource>({ id: panelTypeId, name, hooks });

        family.addPanelType(newPanelType);

        this.cornerPanelTypesById.set(newPanelType.id, newPanelType);
        this.cornerPanelFamiliesByTypeId.set(newPanelType.id, family);
    }

    // returns an array of panel hooks db ids
    setPanelType(panelId: string, targetPanelTypeId: string): number[] {
        const panel = this.findPanelById(panelId)!;

        const currentPanelType = this.findPanelType(panel.panelTypeId)!;

        currentPanelType.removePanel(panel);

        const targetPanelType = this.findPanelType(targetPanelTypeId)!;

        targetPanelType.addPanel(panel);

        panel.setPanelType(targetPanelTypeId);

        const hookDbIds: number[] = [];

        const hooks = this.findPanelHooks(panelId) as Hook[];

        for (const hook of hooks) {
            this.clearPanelHook(panel, hook);

            hookDbIds.push(hook.dbId);

            this.objectIds.freeId(hook.dbId);
        }

        this.createPanelHooks(panel);

        return hookDbIds;
    }

    // returns an array of panel hooks db ids
    setCornerPanelType(panelId: string, targetPanelTypeId: string): number[] {
        const panel = this.findCornerById(panelId)!;

        const currentPanelType = this.findCornerPanelType(panel.panelTypeId)!;

        currentPanelType.removePanel(panel);

        const targetPanelType = this.findCornerPanelType(targetPanelTypeId)!;

        targetPanelType.addPanel(panel);

        panel.setPanelType(targetPanelTypeId);

        const hookDbIds: number[] = [];

        const hooks = this.findPanelHooks(panelId) as CornerHook[];

        for (const hook of hooks) {
            this.clearCornerPanelHook(panel, hook);

            hookDbIds.push(hook.dbId);

            this.objectIds.freeId(hook.dbId);
        }

        this.createCornerPanelHooks(panel);

        return hookDbIds;
    }

    loadPanelTypeModel(panel: Panel | string | undefined) {
        if (panel)
            this.generatedPanelContent.loadPanelTypeGeneratedModel(panel instanceof Panel ? panel.panelTypeId : panel)
        else
            this.generatedPanelContent.abortCheck();
    }

    loadCornerTypeModel(panel: Corner | string | undefined) {
        if (panel)
            this.generatedCornerContent.loadPanelTypeGeneratedModel(panel instanceof Corner ? panel.panelTypeId : panel);
        else
            this.generatedCornerContent.abortCheck();
    }

    loadHooksModel() {
        this.hooksGeneratedModelContent.loadHooksGeneratedModel();
    }

    isCustomPanelHook(panelId: string): boolean {
        const panel = this.findPanelById(panelId);

        if (panel)
            return !!panel.customPanelTypeId;

        const corner = this.findCornerById(panelId);

        return !!corner?.customPanelTypeId;
    }

    addCustomPanel(panelSource: ICustomPanelSource): CustomPanel {
        const panel = this.panelsFactory.createCustomPanel(panelSource);

        this.customPanelsByDbId.set(panel.panelDbId, panel);
        this.customPanelsById.set(panel.id, panel);

        return panel;
    }

    addCustomCorner(cornerSource: ICustomCornerSource): CustomCorner {
        const corner = this.cornersFactory.createCustomCorner(cornerSource);

        this.customCornersByDbId.set(corner.panelDbId, corner);
        this.customCornersById.set(corner.id, corner);

        return corner;
    }

    addCustomZShapedPanel(panelSource: ICustomZShapedPanelSource): CustomZShapedPanel {
        const panel = this.cornersFactory.createCustomZShapedPanel(panelSource);

        this.customZShapedPanelsByDbId.set(panel.panelDbId, panel);
        this.customZShapedPanelsById.set(panel.id, panel);

        return panel;
    }

    removeCustomPanel(panelId: number | CustomPanel) {
        const panel = typeof panelId === "number"
            ? this.findCustomPanel(panelId)
            : panelId;

        if (!panel)
            return;

        this.customPanelsByDbId.delete(panel.panelDbId);
        this.customPanelsById.delete(panel.id);

        this.objectIds.freeId(panel.panelDbId);
    }

    removeCustomCorner(cornerDbId: number | CustomCorner) {
        const corner = typeof cornerDbId === "number"
            ? this.findCustomCorner(cornerDbId)
            : cornerDbId;

        if (!corner)
            return;

        this.customCornersByDbId.delete(corner.panelDbId);
        this.customCornersById.delete(corner.id);

        this.objectIds.freeId(corner.panelDbId);
    }

    removeCustomZShapedPanel(panelId: number | CustomZShapedPanel) {
        const panel = typeof panelId === "number"
            ? this.findCustomZShapedPanel(panelId)
            : panelId;

        if (!panel)
            return;

        this.customZShapedPanelsByDbId.delete(panel.panelDbId);
        this.customZShapedPanelsById.delete(panel.id);

        this.objectIds.freeId(panel.panelDbId);
    }

    private addPanel(panelSource: IPanelSource) {
        const panelType = this.panelTypesById.get(panelSource.panelTypeId);

        if (!panelType)
            throw new Error("Invalid state!");

        const panel = this.panelsFactory.create(panelSource);

        panelType.addPanel(panel);

        this.panelsByDbId.set(panel.panelDbId, panel);
        this.panelsById.set(panel.id, panel);
    }

    private addCornerPanel(cornerSource: ICornerPanelSource) {
        const panelType = this.cornerPanelTypesById.get(cornerSource.panelTypeId);

        if (!panelType)
            throw new Error("Invalid state!");

        const corner = this.cornersFactory.create(cornerSource);

        panelType.addPanel(corner);

        this.cornersByDbId.set(corner.panelDbId, corner);
        this.cornersById.set(corner.id, corner);
    }

    private createPanelHooks(panel: Panel) {
        const panelType = this.findPanelType(panel.panelTypeId)!;

        const hooks = this.hooksFactory.createPanelHooks(panel, panelType);

        this.storePanelHooks(hooks, panel);
    }

    private createCornerPanelHooks(panel: Corner) {
        const panelType = this.findCornerPanelType(panel.panelTypeId)!;

        const hooks = this.hooksFactory.createCornerHooks(panel, panelType);

        this.storeCornerHooks(hooks, panel);
    }

    private updatePanelHook(hook: Hook): Hook[] {
        const panelHooks = this.findPanelHooks(hook.panelId);

        const index = panelHooks.findIndex(x => x === hook);

        const panel = this.findPanelById(hook.panelId)!;

        const panelType = this.findPanelType(panel.panelTypeId)!;

        const hooks: Hook[] = [];

        for (const panel of panelType.panels) {
            const hookInstance = this.findPanelHooks(panel.id)[index] as Hook;

            hookInstance.update(hook);

            hooks.push(hookInstance);
        }

        panelType.updateHook(index, hook);

        this.panelTypeHooksUpdater.push(panelType, "update");

        this.onPanelTypeUpdated(panelType);

        return hooks;
    }

    private updateCornerPanelHook(hook: CornerHook): CornerHook[] {
        const panelHooks = this.findPanelHooks(hook.panelId);

        const index = panelHooks.findIndex(x => x === hook);

        const panel = this.findCornerById(hook.panelId)!;

        const panelType = this.findCornerPanelType(panel.panelTypeId)!;

        const hooks: CornerHook[] = [];

        for (const panel of panelType.panels) {
            const hookInstance = this.findPanelHooks(panel.id)[index] as CornerHook;

            hookInstance.update(hook);

            hooks.push(hookInstance);
        }

        panelType.updateHook(index, hook);

        this.cornerPanelTypeHooksUpdater.push(panelType, "update");

        this.onCornerPanelTypeUpdated(panelType);

        return hooks;
    }

    private removePanelHook(hook: Hook): number[] {
        const panelHooks = this.findPanelHooks(hook.panelId);

        const index = panelHooks.findIndex(x => x === hook);

        const panel = this.findPanelById(hook.panelId)!;

        const panelType = this.findPanelType(panel.panelTypeId)!;

        const hookDbIds: number[] = [];

        for (const panel of panelType.panels) {
            const hookInstance = this.findPanelHooks(panel.id)[index] as Hook;

            this.clearPanelHook(panel, hookInstance);

            hookDbIds.push(hookInstance.dbId);

            this.objectIds.freeId(hookInstance.dbId);
        }

        panelType.removeHook(index);

        this.panelTypeHooksUpdater.push(panelType, "delete");

        this.onPanelTypeUpdated(panelType);

        return hookDbIds;
    }

    private removeCornerHook(hook: CornerHook): number[] {
        const panelHooks = this.findPanelHooks(hook.panelId);

        const index = panelHooks.findIndex(x => x === hook);

        const panel = this.findCornerById(hook.panelId)!;

        const panelType = this.findCornerPanelType(panel.panelTypeId)!;

        const hookDbIds: number[] = [];

        for (const panel of panelType.panels) {
            const hookInstance = this.findPanelHooks(panel.id)[index] as CornerHook;

            this.clearCornerPanelHook(panel, hookInstance);

            hookDbIds.push(hookInstance.dbId);

            this.objectIds.freeId(hookInstance.dbId);
        }

        panelType.removeHook(index);

        this.cornerPanelTypeHooksUpdater.push(panelType, "delete");

        this.onCornerPanelTypeUpdated(panelType);

        return hookDbIds;
    }

    private storePanelHooks(hooks: Hook[], panel: Panel) {
        this.hooksByPanel.set(panel.id, hooks);

        const wallFaceHooks = this.hooksByWallFace.get(panel.wallFaceId) || [];

        this.hooksByWallFace.set(panel.wallFaceId, wallFaceHooks.concat(hooks));

        for (const hook of hooks)
            this.hooksByDbId.set(hook.dbId, hook);
    }

    private storeCornerHooks(hooks: CornerHook[], panel: Corner) {
        this.hooksByPanel.set(panel.id, hooks);

        const leftWallFace = panel.leftWallFaceId;
        const rightWallFace = panel.rightWallFaceId;

        const leftWallFaceHooks = this.hooksByWallFace.get(leftWallFace) || [];
        const rightWallFaceHooks = this.hooksByWallFace.get(rightWallFace) || [];

        this.hooksByWallFace.set(leftWallFace, leftWallFaceHooks.concat(hooks.filter(x => x.wallFaceId === leftWallFace)));
        this.hooksByWallFace.set(rightWallFace, rightWallFaceHooks.concat(hooks.filter(x => x.wallFaceId === rightWallFace)));

        for (const hook of hooks)
            this.hooksByDbId.set(hook.dbId, hook);
    }

    private clearPanelHook(panel: Panel, hook: Hook) {
        const panelHooks = this.findPanelHooks(panel.id).filter(x => x !== hook);

        this.hooksByPanel.set(panel.id, panelHooks);

        const wallFaceHooks = this.hooksByWallFace.get(panel.wallFaceId) || [];

        this.hooksByWallFace.set(panel.wallFaceId, wallFaceHooks.filter(x => x !== hook));

        this.hooksByDbId.delete(hook.dbId);
    }

    private clearCornerPanelHook(panel: Corner, hook: CornerHook) {
        const panelHooks = this.findPanelHooks(panel.id).filter(x => x !== hook);

        this.hooksByPanel.set(panel.id, panelHooks);

        const wallFaceId = hook.cornerHookType === CornerHookType.CornerLeftWing ? panel.leftWallFaceId : panel.rightWallFaceId;

        const wallFaceHooks = this.hooksByWallFace.get(wallFaceId) || [];

        this.hooksByWallFace.set(wallFaceId, wallFaceHooks.filter(x => x !== hook));

        this.hooksByDbId.delete(hook.dbId);
    }

    private findValidCustomPanelTypes(panel: Panel | CustomPanel): ICustomPanelType[] {
        const customPanelTypes: ICustomPanelType[] = [];

        for (const customPanelType of this.customPanelTypes.values()) {
            if (customPanelType.id === panel.customPanelTypeId
                || (isAlmostEqual(panel.length, customPanelType.length)
                    && isAlmostEqual(panel.height, customPanelType.height)))
                customPanelTypes.push(customPanelType);
        }

        return customPanelTypes;
    }

    private findValidCustomCornerTypes(panel: Corner): ICustomCornerType[] {
        const customCornerTypes: ICustomCornerType[] = [];

        for (const customCornerType of this.customCornerTypes.values()) {
            if (customCornerType.id === panel.customPanelTypeId
                || (isAlmostEqual(panel.isReversed ? panel.rightWing : panel.leftWing, customCornerType.leftWingLength)
                    && isAlmostEqual(panel.isReversed ? panel.leftWing : panel.rightWing, customCornerType.rightWingLength)
                    && isAlmostEqual(panel.height, customCornerType.height)
                    && isAlmostEqual(panel.angle, customCornerType.angle)))
                customCornerTypes.push(customCornerType);
        }

        return customCornerTypes;
    }

    private onPanelTypeUpdated(panelType: PanelType<IPanelSource>) {
        this.generatedPanelContent.abortCheck(panelType.id);
        this.abortHooksGeneratedModelContentLoader();

        eventBus.dispatchEvent({
            type: "Dextall.Panels.GeneratedModel.Loaded",
            payload: {
                model: { id: "", bubble: null, status: PanelTypeGenerationStatus.None },
                panelTypeId: panelType.id,
                drawings: []
            }
        });
    }

    private onCornerPanelTypeUpdated(panelType: PanelType<ICornerPanelSource>) {
        this.generatedCornerContent.abortCheck(panelType.id);
        this.abortHooksGeneratedModelContentLoader();

        eventBus.dispatchEvent({
            type: "Dextall.Corners.GeneratedModel.Loaded",
            payload: {
                model: { id: "", bubble: null, status: PanelTypeGenerationStatus.None },
                panelTypeId: panelType.id,
                drawings: []
            }
        })
    }

    private abortHooksGeneratedModelContentLoader() {
        this.hooksGeneratedModelContent.abort();

        eventBus.dispatchEvent({
            type: "Dextall.HooksRevitModel.Loaded",
            payload: {
                model: { id: "", status: PanelTypeGenerationStatus.None }
            }
        })
    }
}

function fillPanelTypes<T extends IPanelSource | ICornerPanelSource>(familySource: IFamily, panelTypesById: Map<string, PanelType<T>>, panelFamiliesByTypeId: Map<string, PanelFamily<T>>) {
    const panelFamily = new PanelFamily<T>(familySource);

    for (const panelType of panelFamily.panelTypes) {
        panelTypesById.set(panelType.id, panelType);
        panelFamiliesByTypeId.set(panelType.id, panelFamily);
    }
}