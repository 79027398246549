import { IWallFace, ModelWallFaceOpeningEditState } from "../../../responses/wallFace";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { CladdingCellExtrusionGeometryFactory } from "../geometry/claddingCellExtrusionGeometryFactory";
import { ViewerModelCollector } from "../viewer-utils/viewerModelCollector";
import { convertToModelGeometry, mergeVertices, createModelGeometryIndex } from "../csg/bufferGeometryUtils";
import { getFragments } from "../viewer-utils/viewerObjectTreeUtils";
import { FaceBoundaryLoop } from "../wallgeometry/faceBoundaryLoop";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import { WallWireframeBuilder } from "../wallgeometry/wallWireframeBuilder";
import { CSG } from "../csg/CSG";
import { WallFragmentOuterBoundaryLoopsFactory } from "../wallgeometry/wallFragmentOuterBoundaryLoopsFactory";
import { WallFacesCollection } from "../panels/wallFacesCollection";

type ModelWall = {
    dbId: number;
    wallUniqueId: string;
    thickness: number;
}

export class WallsModelEditor {
    private readonly modelWalls = new Map<string, ModelWall>();
    private readonly replacedWallFragmentIds = new Map<string, number[]>();
    private readonly wallConstructedGeometries = new Map<string, CSG[]>();
    private readonly boundaryLoopsFactory = new BoundaryLoopsFactory();
    private readonly extrusionGeometryFactory = new CladdingCellExtrusionGeometryFactory();
    private readonly offset: THREE.Vector3;
    private readonly material = new THREE.MeshBasicMaterial();

    constructor(
        private readonly viewer: Autodesk.Viewing.GuiViewer3D,
        private readonly wallFacesCollection: WallFacesCollection,
        private readonly facesOffset: number,
        private readonly packNormals: (geometry: THREE.BufferGeometry) => THREE.BufferGeometry) {
        this.offset = new THREE.Vector3().copy(viewer.model.getGlobalOffset()).negate();
    }

    async init() {
        const collector = new ViewerModelCollector(this.viewer.model);

        const wallIds = await collector.findElementsByCategory("Walls");

        const walls = await this.getWalls(wallIds);

        for (const wall of walls)
            this.modelWalls.set(wall.wallUniqueId, wall);

        for (const wall of this.wallFacesCollection.asArray())
            await this.replaceWall(wall);
    }

    dispose() {
        this.modelWalls.clear();
        this.replacedWallFragmentIds.clear();
        this.wallConstructedGeometries.clear();
    }

    private async replaceWall(wall: IWallFace, force: boolean = false) {
        const modelWall = this.modelWalls.get(wall.wallUniqueId);

        if (!modelWall)
            return;

        if (!(force || wall.hasModifiedOpenings || this.isGeometryReplaced(wall)))
            return;

        if (this.viewer.model.isConsolidated())
            this.viewer.model.unconsolidate();

        const wallConstructedGeometries = this.createWallConstructedGeometry(wall);

        const openings = wall
            .openings
            .filter(x => !(x.editState === ModelWallFaceOpeningEditState.Removed || x.editState === ModelWallFaceOpeningEditState.RemovedCreatedInStudio))
            .flatMap(x => this.createOpeningBoxGeometry(wall, x.box));

        const fragmentsList = this.viewer.model.getFragmentList();
        const geometryList = this.viewer.model.getGeometryList();

        for (const csg of wallConstructedGeometries.geometries) {
            const matrix = new THREE.Matrix4();
            const source = csg.source!;
            const geometry = convertToModelGeometry(mergeVertices(this.createWallGeometry(csg, openings).toGeometry(source.vbstride)), source);

            const wireFrameBuilder = new WallWireframeBuilder(this.viewer.model, geometry, source.fragmentId);

            wireFrameBuilder.createWireframe();

            createModelGeometryIndex(geometry);

            const instanceCount = source.instanceCount || 1;

            const svfId = instanceCount < 2
                ? source.svfId
                : geometryList.geoms.length;

            geometryList.addGeometry(this.packNormals(geometry), 1, svfId);

            geometry.svfid = svfId;
            geometry.instanceCount = 1;
            geometry.internalInstanceCount = 1;
            geometry.numInstances = 1;

            fragmentsList.setMesh(source.fragmentId, {
                geometry,
                material: this.getWallMaterial(source.fragmentId),
                matrix,
                is2D: false,
                isLine: false,
                isPoint: false,
                isWideLine: false,
                geomId: svfId
            }, true, false);
        }

        for (const unusedFragId of wallConstructedGeometries.unusedFragments)
            fragmentsList.setFragOff(unusedFragId, true);
    }

    private createWallGeometry(wallOriginalGeometry: CSG, openings: CSG[]) {
        return openings.reduce((acc, elem) => acc.subtract(elem), wallOriginalGeometry);
    }

    private createWallConstructedGeometry(wall: IWallFace) {
        const existingGeometry = this.wallConstructedGeometries.get(wall.wallUniqueId);

        if (existingGeometry)
            return { geometries: existingGeometry, unusedFragments: [] };

        const modelWall = this.modelWalls.get(wall.wallUniqueId)!;

        const constructiveSolidGeometries: CSG[] = [];

        const fragments = getFragments(this.viewer.model, modelWall.dbId);

        const boundaryLoopsFactory = new WallFragmentOuterBoundaryLoopsFactory(this.viewer, this.facesOffset);

        const degeneratedBoundaryLoops: FaceBoundaryLoop[] = [];
        const fragmentsWithoutBoundaryLoops: number[] = [];

        for (const fragId of fragments) {
            const boundaryLoops = boundaryLoopsFactory.createOuterBoundaryLoops(fragId, wall);

            if (boundaryLoops.length === 0) {
                fragmentsWithoutBoundaryLoops.push(fragId);
                continue;
            }

            if (boundaryLoops.length === 1) {
                const degeneratedBoundaryLoop = boundaryLoops[0];

                degeneratedBoundaryLoop.index = degeneratedBoundaryLoops.length;

                degeneratedBoundaryLoops.push(degeneratedBoundaryLoop);

                continue;
            }

            const geometry = this.createGeometryFromBoundaryLoops(boundaryLoops);

            const csg = CSG.fromGeometry(geometry);
            csg.source = boundaryLoops[0].face.source;

            constructiveSolidGeometries.push(csg);
        }

        let unusedFragments: number[] = [];

        if (degeneratedBoundaryLoops.length > 0) {
            const recreatedBoundaryLoop = boundaryLoopsFactory.createOuterBoundaryLoopsFromDegenerated(degeneratedBoundaryLoops, fragmentsWithoutBoundaryLoops, wall);

            const geometry = this.createGeometryFromBoundaryLoops(recreatedBoundaryLoop.boundaryLoops);

            const csg = CSG.fromGeometry(geometry);
            const faceSource = recreatedBoundaryLoop.source!;
            csg.source = faceSource;

            constructiveSolidGeometries.push(csg);

            unusedFragments = fragmentsWithoutBoundaryLoops.filter(x => x !== faceSource.fragmentId);
        }

        this.wallConstructedGeometries.set(wall.wallUniqueId, constructiveSolidGeometries);

        return { geometries: constructiveSolidGeometries, unusedFragments };
    }

    private createGeometryFromBoundaryLoops(boundaryLoops: FaceBoundaryLoop[]) {
        const vertices: THREE.Vector3[] = [];
        const faces: THREE.Face3[] = [];
        let faceVertexIndexOffset = 0;

        for (const boundaryLoop of boundaryLoops) {
            const triangles = boundaryLoop.triangulate();

            for (const triangle of triangles) {
                vertices[faceVertexIndexOffset + triangle.ia] = triangle.a;
                vertices[faceVertexIndexOffset + triangle.ib] = triangle.b;
                vertices[faceVertexIndexOffset + triangle.ic] = triangle.c;

                const face = new THREE.Face3(
                    faceVertexIndexOffset + triangle.ia,
                    faceVertexIndexOffset + triangle.ib,
                    faceVertexIndexOffset + triangle.ic,
                    triangle.normal);

                faces.push(face);
            }
            faceVertexIndexOffset += boundaryLoop.getPointsCount() - 1;
        }

        const geometry = new THREE.Geometry();
        geometry.vertices.push(...vertices);
        geometry.faces.push(...faces);

        return new THREE.BufferGeometry().fromGeometry(geometry);
    }

    private createOpeningBoxGeometry(wallFace: IWallFace, box: THREE.Box3): CSG {
        const boundaryLoop = this.boundaryLoopsFactory.createLoopFromFlatBox(box, wallFace.transform, this.offset);

        const modelWall = this.modelWalls.get(wallFace.wallUniqueId)!;

        const geometry = this.extrusionGeometryFactory.createCladdingCellExtrusion(boundaryLoop, {
            material: this.material,
            thickness: modelWall.thickness + 2 * zFightingFixDistance,
            zOffset: -(modelWall.thickness + this.facesOffset + zFightingFixDistance)
        });

        const mesh = new THREE.Mesh(geometry.geometry, this.material);

        mesh.applyMatrix(geometry.matrix);

        return CSG.fromMesh(mesh);
    }

    private isGeometryReplaced(wall: IWallFace) {
        return !!this.wallConstructedGeometries.get(wall.wallUniqueId);
    }

    private getWalls(dbIds: number[]): Promise<ModelWall[]> {
        return new Promise((resolve, reject) => {
            const options = {
                propFilter: ["Width", "externalId"],
                needsExternalId: true
            }

            this.viewer.model.getBulkProperties2(dbIds, options, r => {
                const modelWalls = r.map<ModelWall>(x => {
                    const thicknessProperty = x.properties.find(p => p.attributeName === "Width");

                    const thickness = thicknessProperty !== undefined
                        ? Autodesk.Viewing.Private.convertToDisplayUnits(thicknessProperty.displayValue as number, thicknessProperty.type, thicknessProperty.units!, 'ft').displayValue
                        : 0;

                    return {
                        dbId: x.dbId,
                        wallUniqueId: x.externalId!,
                        thickness
                    }
                });

                resolve(modelWalls);
            }, () => reject());
        })
    }

    private getWallMaterial(fragmentId: number): THREE.Material {
        const renderProxy = this.viewer.impl.getRenderProxy(this.viewer.model, fragmentId)!;

        return renderProxy.material;
    }
}