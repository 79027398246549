import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { windowGlassThickness, zFightingFixDistance } from "@dextall/panels-defaults";

export class PanelWindowGlass {
    constructor(private readonly boundaryLoop: BoundaryLoop, private readonly material: THREE.Material,
        private readonly claddingExtrusionFactory: CladdingCellExtrusionGeometryFactory) {

    }

    createGeometry(): ExtrusionGeometry {
        return this.claddingExtrusionFactory.createCladdingCellExtrusion(this.boundaryLoop, {
            thickness: windowGlassThickness,
            material: this.material,
            zOffset: -(windowGlassThickness + zFightingFixDistance)
        });
    }
}