import { ModelElementFacesFactory } from "./modelElementFacesFactory";

export class WallWireframeBuilder {
    constructor(private readonly model: Autodesk.Viewing.Model, private readonly geometry: THREE.BufferGeometry, private readonly fragmentId: number) { }

    createWireframe() {
        Autodesk.Viewing.Private.createWireframe(this.geometry);

        const lines = this.filterWireframeLines();

        const ibLines = this.geometry.iblines instanceof Uint16Array
            ? new Uint16Array(lines.length)
            : new Uint32Array(lines.length);

        ibLines.set(lines);

        // @ts-ignore
        this.geometry.iblines = ibLines;
    }

    private filterWireframeLines() {
        const wireFrameLines: number[] = [];

        const facesFactory = new ModelElementFacesFactory(this.model);

        const modelFaces = facesFactory.createFaces(this.geometry, new THREE.Matrix4(), this.fragmentId);

        Autodesk.Viewing.Private.VertexEnumerator.enumMeshEdges(this.geometry, (p, q, a, b) => {
            const findFaces = (point: THREE.Vector3) => {
                const faces = new Set<number>();

                for (let i = 0; i < modelFaces.length; ++i) {
                    const face = modelFaces[i];

                    if (face.isPointInside(point))
                        faces.add(i);
                }

                return faces;
            }

            const findCommonFaces = (faces: Set<number>, otherFaces: Set<number>) => {
                return Array.from(faces.values()).filter(x => otherFaces.has(x));
            }

            const commonFaces = findCommonFaces(findFaces(p), findFaces(q));

            if (commonFaces.length > 1) {
                wireFrameLines.push(a);
                wireFrameLines.push(b);
            }
        });

        return wireFrameLines;
    }
}