import React, { useEffect, useState } from "react";
import { generateModelQrCode } from "./forge/panels/stickyNoteGenerator";
import { ProcessingPane } from "./processing-pane/processingPane";
import eventBus from "./forge/eventBus/eventDispatcher";

type Props = {
    id: string;
}

export const AppPanelsStickyNotesGenerator = ({ id }: Props) => {
    const [inProgress, setInProgress] = useState(false);

    useEffect(() => {
        const notifyError = (message: string) => {
            eventBus.dispatchEvent({
                type: "Dextall.Common.Notify.Error",
                payload: message
            });
        }

        const downloadQrCode = (downloadableUrl: string) => {
            eventBus.dispatchEvent({
                type: "Dextall.QRCode.Download",
                payload: downloadableUrl
            });
        }

        const onGenerateQrCode = async () => {
            setInProgress(true);

            const result = await generateModelQrCode(id);

            setInProgress(false);

            if (result.isSuccess)
                downloadQrCode(result.item);
            else
                notifyError(result.message);
        }

        eventBus.addEventListener("Dextall.QRCode.Generate", onGenerateQrCode);

        return () => {
            eventBus.removeEventListener("Dextall.QRCode.Generate", onGenerateQrCode);
        }
    }, []);

    return <>
        {inProgress && <ProcessingPane
            isVisible={true}
            message="Generating QR code..."
            title="QR"
        />}
    </>
}