export class ExtendedSnapper {
    private readonly points: THREE.Vector3[] = [];
    private readonly originalSnapResult: GetSnapResult;
    private readonly originalIsSnapped: IsSnapped;
    private readonly originalMouseMove: OnMouseMove;
    private readonly originalCopyResults: CopyResults;
    private point: THREE.Vector3 | null = null;

    constructor(snapper: Autodesk.Viewing.Extensions.Snapping.Snapper, private readonly viewer: Autodesk.Viewing.Viewer3D, private readonly radius: number) {
        this.originalSnapResult = snapper.getSnapResult.bind(snapper);
        this.originalIsSnapped = snapper.isSnapped.bind(snapper);
        this.originalMouseMove = snapper.onMouseMove.bind(snapper);
        this.originalCopyResults = snapper.copyResults.bind(snapper);

        snapper.isSnapped = this.isSnapped.bind(this);
        snapper.getSnapResult = this.getSnapResult.bind(this);
        snapper.onMouseMove = this.onMouseMove.bind(this);
        snapper.copyResults = this.copyResults.bind(this);
    }

    setPoints(points: THREE.Vector3[]) {
        this.points.splice(0, this.points.length, ...points);
    }

    dispose() {
        // @ts-ignore
        this.originalSnapResult = null;
        // @ts-ignore
        this.originalIsSnapped = null;
        // @ts-ignore
        this.originalMouseMove = null;
        // @ts-ignore
        this.originalCopyResults = null;
        this.point = null;
        this.points.splice(0, this.points.length);
    }

    private getSnapResult(): Autodesk.Viewing.MeasureCommon.SnapResult {
        if (!this.point)
            return this.originalSnapResult();

        snapResult.circularArcCenter = this.point;
        snapResult.circularArcRadius = this.radius;
        snapResult.geomVertex = this.point;
        snapResult.intersectPoint = this.point;
        snapResult.radius = 2 * snapResult.circularArcRadius;
        snapResult.geomVertex = this.point;
        
        return snapResult;
    }

    private isSnapped(): boolean {
        return this.point ? true : this.originalIsSnapped();
    }

    private onMouseMove(mousePosition: { x: number; y: number; }): boolean {
        this.point = null;

        const { x: clientX, y: clientY } = mousePosition;

        const originalMouseMoveResults = this.originalMouseMove(mousePosition);

        if (this.points.length === 0)
            return originalMouseMoveResults;

        const snapResults = this.snap(clientX, clientY);

        if (snapResults === null)
            return originalMouseMoveResults;

        const originalSnapResult = this.originalSnapResult();

        if (originalSnapResult.isEmpty()) {
            this.point = snapResults.point;

            return true;
        }

        const modelHitTest = this.viewer.impl.hitTest(clientX, clientY, false);

        if (!modelHitTest || modelHitTest.distance > snapResults.distance) {
            this.point = snapResults.point;

            return true;
        }

        return originalMouseMoveResults;
    }

    private copyResults(destiny: any) {
        return this.point ? this.getSnapResult().copyTo(destiny) : this.originalCopyResults(destiny);
    }

    private snap(clientX: number, clientY: number): ExtendedPointSnapResults | null {
        const viewPortVector = this.viewer.impl.clientToViewport(clientX, clientY);

        const ray = new THREE.Ray();
        this.viewer.impl.viewportToRay(viewPortVector, ray);

        return this.points
            .map(x => this.snapToPoint(x, ray))
            .filter((x): x is ExtendedPointSnapResults => !!x)
            .sort((a, b) => a.distance - b.distance)[0] || null;
    }

    private snapToPoint(point: THREE.Vector3, ray: THREE.Ray): ExtendedPointSnapResults | null {
        if (ray.distanceToPoint(point) > this.radius)
            return null;

        const distance = ray.origin.distanceTo(point);

        return { point, distance };
    }
}

type GetSnapResult = () => Autodesk.Viewing.MeasureCommon.SnapResult;
type IsSnapped = () => boolean;
type OnMouseMove = (mousePosition: { x: number; y: number; }) => boolean;
type CopyResults = (destiny: any) => void;

type ExtendedPointSnapResults = { point: THREE.Vector3, distance: number };

const snapResult = new Autodesk.Viewing.MeasureCommon.SnapResult();
snapResult.geomType = Autodesk.Viewing.MeasureCommon.SnapType.SNAP_CIRCULARARC;
snapResult.isArc = true;
snapResult.geomEdge = new THREE.Geometry();