import React from "react";
import Typography from "@weave-design/typography";
import { ICustomZShapeType, CustomPanelType, CustomPanelTypeStatus } from "../../responses/customPanelTypes";
import { CustomComponentCard } from "./customComponentCard";
import { formatLength } from "./customComponentCommon";
import "./customPanelComponentCard.css";

type Props = {
    panel: ICustomZShapeType;
    selectLibraryComponent: (component: CustomPanelType) => void;
}

export const CustomZShapedComponentCard = ({ panel, selectLibraryComponent }: Props) => {
    return <CustomComponentCard
        panel={panel}
        selectLibraryComponent={selectLibraryComponent}
        typeIcon="custom-panel-component-card-stats-custom-z-shaped-type"
        panelProperties={<>
            <div><Typography>Angles: {panel.angle1}°, {panel.angle2}°</Typography></div>
            <div><Typography>Left wing: {panel.status === CustomPanelTypeStatus.Published ? formatLength(panel.leftWingLength) : "???"}</Typography></div>
            <div><Typography>Shelf: {panel.status === CustomPanelTypeStatus.Published ? formatLength(panel.shelfLength) : "???"}</Typography></div>
            <div><Typography>Right wing: {panel.status === CustomPanelTypeStatus.Published ? formatLength(panel.rightWingLength) : "???"}</Typography></div>
            <div><Typography>Height: {panel.status === CustomPanelTypeStatus.Published ? formatLength(panel.height) : "???"}</Typography></div>
        </>} />
}