import { IPanelSourceOpening } from "../../../responses/panelSource";
import { SystemSettings } from "../../../responses/systemSettings";
import { IWallWindowCellSource } from "../../../responses/wallFace";
import { isLessThan } from "@dextall/shared";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { claddingCellGap } from "../defaults";
import { windowCellBorderThickness, windowGlassThickness, zFightingFixDistance } from "@dextall/panels-defaults";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import colorsFactory from "../colors/colorsFactory";

export class PanelWindowCell {
    private readonly boundaryLoop: BoundaryLoop;
    private readonly material: THREE.Material;

    constructor(panelWindowDefinition: IPanelSourceOpening, cellSource: IWallWindowCellSource, systemSetting: SystemSettings,
        transform: THREE.Matrix4, private readonly isOutsideComponent: boolean, boundaryLoopsFactory: BoundaryLoopsFactory,
        private readonly claddingExtrusionFactory: CladdingCellExtrusionGeometryFactory) {

        const windowOverlapsSetting = systemSetting.windowOverlaps;

        const originalCellBox = new THREE.Box3(
            new THREE.Vector3(panelWindowDefinition.box.min.x + cellSource.offset.x, panelWindowDefinition.box.min.y + cellSource.offset.y, 0),
            new THREE.Vector3(panelWindowDefinition.box.min.x + cellSource.offset.x + cellSource.width,
                panelWindowDefinition.box.min.y + cellSource.offset.y + cellSource.height, 0));

        const minX = Math.max(originalCellBox.min.x, panelWindowDefinition.box.min.x + windowOverlapsSetting.left);
        const maxX = Math.min(originalCellBox.max.x, panelWindowDefinition.box.max.x - windowOverlapsSetting.right);

        if (isLessThan(maxX - minX, claddingCellGap))
            throw new Error("Invalid state");

        const maxY = Math.min(originalCellBox.max.y, panelWindowDefinition.box.max.y - windowOverlapsSetting.top);

        if (isLessThan(maxY - originalCellBox.min.y, claddingCellGap))
            throw new Error("Invalid state");

        const geometryLoopBox = new THREE.Box3(
            new THREE.Vector3(minX, originalCellBox.min.y, 0),
            new THREE.Vector3(maxX, maxY, 0));

        this.boundaryLoop = boundaryLoopsFactory.createLoopFromBox(geometryLoopBox, transform);

        const holeBox = new THREE.Box3(
            new THREE.Vector3().copy(geometryLoopBox.min).add(new THREE.Vector3(claddingCellGap, claddingCellGap, 0)),
            new THREE.Vector3().copy(geometryLoopBox.max).add(new THREE.Vector3(-claddingCellGap, -claddingCellGap, 0)));

        this.boundaryLoop.addInnerLoop(boundaryLoopsFactory.createLoopFromBox(holeBox, transform));

        const color = isOutsideComponent ? panelWindowDefinition.outsideColor : panelWindowDefinition.insideColor;

        this.material = colorsFactory.getColor(`RAL ${color}`)!;
    }

    createGeometry(): ExtrusionGeometry {
        const offset = this.isOutsideComponent ? 0 : -(windowCellBorderThickness + windowGlassThickness + zFightingFixDistance);

        return this.claddingExtrusionFactory.createCladdingCellExtrusion(this.boundaryLoop, {
            material: this.material,
            thickness: windowCellBorderThickness,
            zOffset: offset,
            geometryGroupName: "window-cell"
        })
    }
}