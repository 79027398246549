import { isAlmostEqualToZero } from "@dextall/shared";

export class TriangleEdge {
    constructor(
        public readonly a: THREE.Vector3,
        public readonly ia: number,
        public readonly b: THREE.Vector3,
        public readonly ib: number
    ) { }

    getKey(): string {
        return `${Math.min(this.ia, this.ib)}-${Math.max(this.ia, this.ib)}`;
    }

    isDegenerated(): boolean {
        return isAlmostEqualToZero(this.a.distanceTo(this.b));
    }
}