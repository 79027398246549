export type StickyNoteSourceType = "panels" | "corners" | "custom-panels" | "custom-corners" | "custom-z-panels";

export enum StickyNoteGenerationStatus {
    NotGenerated = 0,

    InProgress = 1,

    GeneratedSuccessfully = 2,

    GenerationFailed = 3
}

export type StickyNote = {
    status: StickyNoteGenerationStatus;
    downloadableUrl: string;
}