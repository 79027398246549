import { CornerHookType, HookProfileSide, HookType, IHookSource } from "../../../responses/hookSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { IWallFace } from "../../../responses/wallFace";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { HooksGeometryFactory } from "../geometry/hooksGeometryFactory";
import { ModelBuilderObjectIds } from "../ids/modelBuilderObjectIds";
import { HooksCoordinateSystems } from "./hooksCoordinateSystems";
import { CornerHook } from "./cornerHook";
import { Hook } from "./hook";
import { Panel } from "./panel";
import { Corner } from "./corner";
import { IPanelSource } from "../../../responses/panelSource";
import { PanelType } from "./panelType";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { WallFacesCollection } from "./wallFacesCollection";

export class HooksFactory {
    private readonly coordinates: HooksCoordinateSystems;
    private readonly hookGeometry: ExtrusionGeometry[];

    constructor(viewer: Autodesk.Viewing.GuiViewer3D,
        wallFacesCollection: WallFacesCollection,
        wallCorners: IModelCorner[],
        private readonly objectIds: ModelBuilderObjectIds) {

        this.coordinates = new HooksCoordinateSystems(wallFacesCollection, wallCorners, viewer);

        const hooksGeometryFactory = new HooksGeometryFactory();

        this.hookGeometry = hooksGeometryFactory.createGeometry();
    }

    createPanelHooks(panel: Panel, panelType: PanelType<IPanelSource>): Hook[] {
        if (panelType.hooks.length === 0)
            return [];

        const wallFaceTransform = this.coordinates.getPanelWallFaceTransform(panel);

        if (!wallFaceTransform)
            throw Error("Can't get wall face transform");

        const hooks: Hook[] = [];

        const panelOrigin = new THREE.Vector3().copy(panel.box.min);

        const panelSize = new THREE.Vector3().subVectors(panel.box.max, panel.box.min);

        for (const hookSource of panelType.hooks) {
            const dbId = this.objectIds.getNewId();

            const hook = new Hook(hookSource, panelOrigin, wallFaceTransform, this.hookGeometry, panel.id, panel.wallFaceId, dbId, panelSize.x, panelSize.y);

            hooks.push(hook);
        }

        return hooks;
    }

    createCornerHooks(panel: Corner, panelType: PanelType<ICornerPanelSource>): CornerHook[] {
        if (panelType.hooks.length === 0)
            return [];

        const hooks: CornerHook[] = [];

        for (const hookSource of panelType.hooks) {
            const isOnLeftWing = hookSource.cornerHookType === CornerHookType.CornerLeftWing;

            const wingTransform = this.coordinates.getCornerWingTransform(panel, isOnLeftWing);

            if (!wingTransform)
                throw new Error("Can't get wing transform!");

            const dbId = this.objectIds.getNewId();

            const maxX = this.coordinates.getMaxX(panel, isOnLeftWing);

            const wallFaceId = this.coordinates.getCornerWingWallFaceId(panel, isOnLeftWing);

            const alignmentWallFaceId = this.coordinates.getCornerAlignmentWallFaceId(panel, isOnLeftWing);

            const hook = new CornerHook(hookSource, wingTransform, this.hookGeometry, panel.id, wallFaceId, alignmentWallFaceId, dbId, maxX, panel.height);

            hooks.push(hook);
        }

        return hooks;
    }

    createNewPanelHook(panel: Panel, localPoint: THREE.Vector2): Hook {
        const wallFaceTransform = panel.wallFaceTransform;

        const panelOrigin = panel.box.min;

        const newHookSource = createPanelHookSource(localPoint);

        const dbId = this.objectIds.getNewId();

        return new Hook(newHookSource, panelOrigin, wallFaceTransform, this.hookGeometry, panel.id, panel.wallFaceId, dbId, panel.length, panel.height);
    }

    createNewCornerHook(corner: Corner, onLeftWing: boolean, localPoint: THREE.Vector2): CornerHook {
        const wingTransform = onLeftWing ? corner.leftWingHooksCoordinateSystem : corner.rightWingHooksCoordinateSystem;

        const newHookSource = createCornerHookSource(onLeftWing, localPoint);

        const dbId = this.objectIds.getNewId();

        const maxX = this.coordinates.getMaxX(corner, onLeftWing);

        const wallFaceId = onLeftWing ? corner.leftWallFaceId : corner.rightWallFaceId;

        const alignmentWallFaceId = this.coordinates.getCornerAlignmentWallFaceId(corner, onLeftWing);

        return new CornerHook(newHookSource, wingTransform, this.hookGeometry, corner.id, wallFaceId, alignmentWallFaceId, dbId, maxX, corner.height);
    }
}

export const createPanelHookSource = (localPoint: THREE.Vector2): IHookSource => {
    return {
        cornerHookType: CornerHookType.None,
        hookType: HookType.NotAdjustable,
        profileSide: HookProfileSide.Inner,
        x: localPoint.x,
        y: localPoint.y
    }
}

export const createCornerHookSource = (onLeftWing: boolean, localPoint: THREE.Vector2): IHookSource => {
    return {
        cornerHookType: onLeftWing ? CornerHookType.CornerLeftWing : CornerHookType.CornerRightWing,
        hookType: HookType.NotAdjustable,
        profileSide: HookProfileSide.Inner,
        x: localPoint.x,
        y: localPoint.y
    }
}