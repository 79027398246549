import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom";
import { ContentNotReady } from "./components/contentNotReady";
import { AppLoadingSpinner } from "./components/appLoadingSpinner";
import { CustomPanelTypeShape, CustomPanelType } from "./responses/customPanelTypes";
import { Toolbar } from "./components/custom-components-library/toolbar";
import { ComponentsLibraryDialog } from "./components/custom-components-library/componentsLibraryDialog";
import { CustomComponentEditor } from "./components/custom-components-library/customComponentEditor";
import { CustomPanelComponentPropertiesEditor } from "./components/custom-components-library/customPanelComponentPropertiesEditor";
import { CustomComponentUpload } from "./components/custom-components-library/customComponentUpload";
import { CustomCornerComponentPropertiesEditor } from "./components/custom-components-library/customCornerComponentPropertiesEditor";
import { CustomZShapedComponentPropertiesEditor } from "./components/custom-components-library/customZShapedComponentPropertiesEditor";
import repo from "./Repository";

export const LibraryApp = () => {
    const [loading, setLoading] = useState(true);
    const [libraryOpened, setLibraryOpened] = useState(true);
    const [newCustomPanelDialogOpened, setNewCustomPanelDialogOpened] = useState(false);
    const [selectedCustomComponent, setSelectedCustomComponent] = useState<CustomPanelType>();
    const customPanelTypes = useRef<CustomPanelType[]>([]);
    const { hash } = useLocation();

    useEffect(() => {
        if (!hash)
            return;

        repo.setAccessToken(hash.substring(1));

        const initializeViewer = () => {
            return new Promise<void>((resolve) => {
                const options: Autodesk.Viewing.InitializerOptions = { env: 'Local' };

                Autodesk.Viewing.Initializer(options, () => resolve());
            });
        }

        Promise.all([
            repo.findAllCustomPanelTypes(),
            repo.findAllCustomCornerTypes(),
            repo.findAllCustomZShapedTypes(),
            initializeViewer()
        ]).then(x => {
            const [customPanelTypesResponse, customCornerTypesResponse, customZShapedTypedResponse] = x;

            const panelTypes: CustomPanelType[] = customPanelTypesResponse.items || [];
            const cornerTypes = customCornerTypesResponse.items || [];
            const zShapedTypes = customZShapedTypedResponse.items || [];

            customPanelTypes.current = panelTypes.concat(cornerTypes).concat(zShapedTypes);

            setLoading(false);
        });

        return () => {
            Autodesk.Viewing.shutdown();
        }

    }, []);

    if (!hash)
        return <div style={{ height: "100vh" }}><ContentNotReady /></div>

    if (loading)
        return <AppLoadingSpinner />

    const selectLibraryComponent = (component: CustomPanelType) => {
        setSelectedCustomComponent(component);
        setLibraryOpened(false);
    }

    const setPublished = (component: CustomPanelType) => {
        const components = customPanelTypes.current.map(x => x.id === component.id ? component : x);

        customPanelTypes.current = components;
    }

    const pushNewComponent = (component: CustomPanelType) => {
        customPanelTypes.current.push(component);

        setSelectedCustomComponent(component);
    }

    return <div style={{ height: "100vh" }}>
        <Toolbar libraryOpened={libraryOpened} setLibraryOpened={setLibraryOpened} setNewCustomPanelDialogOpened={setNewCustomPanelDialogOpened.bind(null, true)} />
        <div style={{ height: "calc(100vh - 40px)", width: "100%" }}>
            {libraryOpened && <ComponentsLibraryDialog
                customPanelTypes={customPanelTypes.current}
                selectLibraryComponent={selectLibraryComponent}
                closeDialog={() => setLibraryOpened(false)} />}
            {selectedCustomComponent && <CustomComponentEditor customComponent={selectedCustomComponent} children={<>
                {selectedCustomComponent.shapeType === CustomPanelTypeShape.Panel && <CustomPanelComponentPropertiesEditor
                    component={selectedCustomComponent}
                    setPublished={setPublished} />}
                {selectedCustomComponent.shapeType === CustomPanelTypeShape.Corner && <CustomCornerComponentPropertiesEditor
                    component={selectedCustomComponent}
                    setPublished={setPublished} />}
                {selectedCustomComponent.shapeType === CustomPanelTypeShape.ZShape && <CustomZShapedComponentPropertiesEditor
                    component={selectedCustomComponent}
                    setPublished={setPublished}
                />}
            </>} />}
            {newCustomPanelDialogOpened && <CustomComponentUpload
                pushNewComponent={pushNewComponent}
                closeUploadDialog={setNewCustomPanelDialogOpened.bind(null, false)} />}
        </div>
    </div>
}