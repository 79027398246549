import { OperationCompletedResults } from "./generatedHooksModelContentWorker";
import { PanelTypeGenerationStatus } from "../../../responses/panelGeneratedModelDto";
import repo from "../../../Repository";
import eventBus from "../eventBus/eventDispatcher";

export class GeneratedHooksModelContent {
    private readonly worker: Worker;

    constructor(private readonly modelId: string) {
        this.worker = new Worker(new URL("./generatedHooksModelContentWorker.ts", import.meta.url), { type: "module" });

        this.workerJobDone = this.workerJobDone.bind(this);
        this.worker.onmessage = this.workerJobDone;

        this.worker.postMessage({ type: "initialize", accessToken: repo.getAccessToken() });
    }

    loadHooksGeneratedModel() {
        this.worker.postMessage({ type: "load-hooks-generated-model", modelId: this.modelId });
    }

    abort() {
        this.worker.postMessage({ type: "abort-model-generation-check" })
    }

    private async workerJobDone(e: MessageEvent<OperationCompletedResults>) {
        const results = e.data;

        if (results.type === "loaded"
            && results.result.isSuccess
            && results.result.item.status === PanelTypeGenerationStatus.ModelGenerationInProgress) {
            this.worker.postMessage({ type: "check-model-generation", modelId: this.modelId })
        }

        if (!results.result.isSuccess)
            return;

        const model = results.result.item;

        switch (results.type) {
            case "loaded": {
                eventBus.dispatchEvent({ type: "Dextall.HooksRevitModel.Loaded", payload: { model } });
                break;
            }

            case "generated": {
                eventBus.dispatchEvent({ type: "Dextall.HooksRevitModel.Generated", payload: { model } });
                break;
            }
        }
    }
}