import { ToolInterface } from "../../viewer-tools/toolInterface";
import { CustomComponentInsertionPointGizmo, GizmoDirections } from "./customComponentInsertionPointGizmo";
import { isAlmostEqualToZero } from "@dextall/shared";
import eventBus from "../../eventBus/eventDispatcher";

const overlayName = "dextall-component-insertion-point-gizmo";

export class CustomComponentInsertionPointGizmoTool extends ToolInterface {
    private viewer: Autodesk.Viewing.Viewer3D | null = null;
    private gizmo: CustomComponentInsertionPointGizmo | null = null;

    constructor(private readonly position: THREE.Vector3, private readonly directions: GizmoDirections, private readonly mesh: THREE.Mesh) {
        super();

        this.names = ["dextall-component-insertion-point-gizmo-tool"];

        this.updateGizmo = this.updateGizmo.bind(this);
    }

    activate(_name: string, viewer: Autodesk.Viewing.GuiViewer3D): void {
        this.viewer = viewer;
        this.viewer.impl.createOverlayScene(overlayName);
        this.gizmo = new CustomComponentInsertionPointGizmo(viewer, this.directions, this.position, this.mesh);

        this.viewer.impl.addOverlay(overlayName, this.gizmo.transformControl);
        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateGizmo);
    }

    deactivate(): void {
        this.disposeGizmo();
        if (this.viewer) {
            this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.updateGizmo);
            this.viewer.impl.removeOverlayScene(overlayName);
        }

        this.viewer = null;
        this.gizmo = null;
    }

    getPriority() {
        return 42; // Use any number higher than 0 (the priority of all default tools)
    }

    updatePosition(position: THREE.Vector3, force?: boolean) {
        if (!force && isAlmostEqualToZero(position.distanceToSquared(this.position), 1e-4))
            return;

        this.position.copy(position);
        this.gizmo?.setPosition(position);
        this.invalidate()
    }

    handleButtonDown(event: MouseEvent, button: number): boolean {
        if (!(this.viewer && this.gizmo && button === 0))
            return false;

        const result = this.gizmo.handleButtonDown(event);

        if (result)
            this.invalidate()

        return result;
    }

    handleButtonUp(event: MouseEvent, button: number): boolean {
        if (!(this.viewer && this.gizmo && button === 0))
            return false;

        const isDragging = this.gizmo.isDraggingGizmo()

        const result = this.gizmo.handleButtonUp(event);

        if (result)
            this.invalidate();

        if (isDragging)
            eventBus.dispatchEvent({
                type: "Dextall.CustomComponentLibrary.Panel.Position.Gizmo.Changed",
                payload: this.gizmo.getPosition()
            });

        return result || isDragging;
    }

    handleMouseMove(event: MouseEvent): boolean {
        if (!(this.viewer && this.gizmo))
            return false;

        const result = this.gizmo.handleMouseMove(event);

        if (result)
            this.invalidate();

        return result;
    }

    disposeGizmo() {
        if (this.viewer && this.gizmo)
            this.viewer.impl.removeOverlay(overlayName, this.gizmo.transformControl);

        this.gizmo?.dispose();
        this.gizmo = null;
    }

    private updateGizmo() {
        this.gizmo?.update();
    }

    private invalidate() {
        this.viewer?.impl.invalidate(true, false, true);
    }
}