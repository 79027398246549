import { ViewerModelUtils } from "../viewer-utils/viewerModelUtils";

export class SnapGrid {
    constructor(model: Autodesk.Viewing.Model) {
        const isMetric = ViewerModelUtils.isMetric(model);

        this.snapGridSize = isMetric
            ? Autodesk.Viewing.Private.convertUnits("mm", "ft", 1, 100)
            : Autodesk.Viewing.Private.convertUnits("inch", "ft", 1, 6);
    }

    public readonly snapGridSize: number;

    snap(value: number): number {
        return Math.round(value / this.snapGridSize) * this.snapGridSize
    }
}