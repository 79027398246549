import { PanelFacadeDocument } from "../panels/panelFacadeDocument";
import { BasicItemResponse } from "../../../responses/basicResponses";
import repo from "../../../Repository";

export class HooksGeneratedModelEditor {
    constructor(private readonly panelFacadeDocument: PanelFacadeDocument) {
        
    }

    loadHooksModel() {
        this.panelFacadeDocument.loadHooksModel();
    }

    async startHooksModelGeneration(): Promise<BasicItemResponse<string>> {
        const result = await repo.startRevitHooksModelGeneration(this.panelFacadeDocument.modelId);

        if (result.isSuccess)
            this.loadHooksModel();

        return result;
    }
}