import { ForgeCustomComponentNavigationExtension } from "./forgeCustomComponentNavigationExtension";
import { ForgeCustomPanelComponentEditorExtension } from "./forgeCustomPanelComponentEditorExtension";
import { ForgeCustomCornerComponentEditorExtension } from "./forgeCustomCornerComponentEditorExtension";
import { ForgeCustomZShapedComponentEditorExtension } from "./forgeCustomZShapedComponentEditorExtension";

export const registerExtensions = () => {
    Autodesk.Viewing.theExtensionManager.registerExtension("Dextall.ForgeCustomComponentNavigationExtension", ForgeCustomComponentNavigationExtension);
    Autodesk.Viewing.theExtensionManager.registerExtension("Dextall.ForgeCustomPanelComponentEditorExtension", ForgeCustomPanelComponentEditorExtension);
    Autodesk.Viewing.theExtensionManager.registerExtension("Dextall.ForgeCustomCornerComponentEditorExtension", ForgeCustomCornerComponentEditorExtension);
    Autodesk.Viewing.theExtensionManager.registerExtension("Dextall.ForgeCustomZShapedComponentEditorExtension", ForgeCustomZShapedComponentEditorExtension);
}