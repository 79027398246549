import { roundToDecimalSigns } from "@dextall/shared";
import { CustomPanelTypeStatus } from "../../responses/customPanelTypes";

export const formatLength = (value: number): string => {
    const metricValue = `${roundToDecimalSigns(Autodesk.Viewing.Private.convertUnits("ft", "mm", 1, value), 2)} mm`;
    const imperialValue = Autodesk.Viewing.Private.formatValueWithUnits(value, "ft-and-fractional-in", 3, 3);

    return `${metricValue} (${imperialValue})`;
}

export const getStatusBadgeColor = (status: CustomPanelTypeStatus) => {
    switch (status) {
        case CustomPanelTypeStatus.Published:
            return "green"

        case CustomPanelTypeStatus.Failed:
            return "red"

        default:
            return "custom"
    }
}