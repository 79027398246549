import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { IPanelSource } from "../../../responses/panelSource";
import { IFamily } from "../../../responses/panelType";
import { PanelType } from "./panelType";

export class PanelFamily<T extends IPanelSource | ICornerPanelSource> {
    private readonly _panelTypes = new Map<string, PanelType<T>>();

    constructor(familySource: IFamily) {
        this.id = familySource.id;

        for (const panelTypeSource of familySource.types) {
            const panelType = new PanelType<T>(panelTypeSource);

            this.addPanelType(panelType);
        }
    }

    readonly id: string;

    get panelTypes(): PanelType<T>[] {
        return Array.from(this._panelTypes.values());
    }

    findPanelType(id: string): PanelType<T> | undefined {
        return this._panelTypes.get(id);
    }

    addPanelType(panelType: PanelType<T>) {
        this._panelTypes.set(panelType.id, panelType);
    }
}