import { isAlmostEqualToZero, isMoreThan } from "@dextall/shared";
import { TriangleEdge } from "./triangleEdge";

const computeTriangleNormal = (a: THREE.Vector3, b: THREE.Vector3, c: THREE.Vector3) => {
	const bNormal = new THREE.Vector3().subVectors(c, b).cross(new THREE.Vector3().subVectors(a, b)).normalize();

	if (isMoreThan(bNormal.length(), 0))
		return bNormal;

	const cNormal = new THREE.Vector3().subVectors(c, a).cross(new THREE.Vector3().subVectors(b, c)).normalize();

	if (isMoreThan(cNormal.length(), 0))
		return cNormal;

	return new THREE.Vector3().subVectors(b, a).cross(new THREE.Vector3().subVectors(c, a)).normalize();
}

export class Triangle {
	constructor(a: THREE.Vector3, b: THREE.Vector3, c: THREE.Vector3,
		public readonly ia: number,
		public readonly ib: number,
		public readonly ic: number,
		matrix: THREE.Matrix4) {
		this.a = new THREE.Vector3().copy(a).applyMatrix4(matrix);
		this.b = new THREE.Vector3().copy(b).applyMatrix4(matrix);
		this.c = new THREE.Vector3().copy(c).applyMatrix4(matrix);
		this.ia = ia;
		this.ib = ib;
		this.ic = ic;
		this.normal = computeTriangleNormal(this.a, this.b, this.c);
		this.plane = new THREE.Plane().setFromNormalAndCoplanarPoint(this.normal, this.a);
	}

	public readonly a: THREE.Vector3;

	public readonly b: THREE.Vector3;

	public readonly c: THREE.Vector3;

	public readonly normal: THREE.Vector3;

	public readonly plane: THREE.Plane;

	isDegenerated(): boolean {
		return isAlmostEqualToZero(this.normal.length());
	}

	getEdges(): TriangleEdge[] {
		const edges = [];
		edges.push(new TriangleEdge(this.a, this.ia, this.b, this.ib));
		edges.push(new TriangleEdge(this.b, this.ib, this.c, this.ic));
		edges.push(new TriangleEdge(this.c, this.ic, this.a, this.ia));

		return edges;
	}
}