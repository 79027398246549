import { IWallFace } from "../../../responses/wallFace";
import { FaceBoundaryLoop } from "./faceBoundaryLoop";
import { ModelElementFace, ModelElementFaceSource } from "./modelElementFace";
import { ModelElementFacesFactory } from "./modelElementFacesFactory";
import { OuterBoundaryLoopsFilter } from "./outerBoundaryLoopsFilter";

type RecreatedBoundaryLoop = {
    boundaryLoops: FaceBoundaryLoop[];
    source: ModelElementFaceSource;
}

export class WallFragmentOuterBoundaryLoopsFactory {
    constructor(
        private readonly viewer: Autodesk.Viewing.Viewer3D,
        private readonly facesOffset: number
    ) { }

    createOuterBoundaryLoops(fragmentId: number, wallFace: IWallFace): FaceBoundaryLoop[] {
        const faces = this.createFaces(fragmentId);

        const { normal, origin } = this.getWallFacePlane(wallFace);

        const outerLoopsFilter = new OuterBoundaryLoopsFilter(normal, origin, faces);

        return outerLoopsFilter.findOuterBoundaryLoops();
    }

    createOuterBoundaryLoopsFromDegenerated(degeneratedBoundaryLoops: FaceBoundaryLoop[], fragmentsWithoutBoundaryLoops: number[], wallFace: IWallFace): RecreatedBoundaryLoop {
        const boundaryLoops = Array.from(degeneratedBoundaryLoops);

        const otherLoops = fragmentsWithoutBoundaryLoops
            .flatMap(x => this.createFaces(x))
            .flatMap(x => x.getBoundaryLoops())
            .filter(x => x.isCounterClockwise());

        for (let i = 0; i < otherLoops.length; ++i) {
            otherLoops[i].index = degeneratedBoundaryLoops.length + i;

            const isIncluded = !!boundaryLoops.find(x => x.isNeighbourLoop(otherLoops[i]))

            if (isIncluded)
                boundaryLoops.push(otherLoops[i]);
        }

        const { normal, origin } = this.getWallFacePlane(wallFace);

        const face = degeneratedBoundaryLoops.map(x => x.face).find(x => x.isPanelWallFace(normal, origin)) || degeneratedBoundaryLoops[0].face;

        return { boundaryLoops, source: face.source! };
    }

    private createFaces(fragmentId: number): ModelElementFace[] {
        const renderProxy = this.viewer.impl.getRenderProxy(this.viewer.model, fragmentId);

        if (!renderProxy)
            return [];

        const geometry = renderProxy.geometry as THREE.BufferGeometry;

        const facesFactory = new ModelElementFacesFactory(this.viewer.model);

        return facesFactory.createFaces(geometry, renderProxy.matrixWorld, fragmentId);
    }

    private getWallFacePlane(wallFace: IWallFace) {
        const normal = new THREE.Vector3().copy(wallFace.transform.basisZ);

        const origin = new THREE.Vector3()
            .copy(wallFace.transform.origin)
            .add(new THREE.Vector3().copy(wallFace.transform.basisZ).multiplyScalar(-1 * this.facesOffset))
            .add(new THREE.Vector3().copy(this.viewer.model.getGlobalOffset()).negate());

        return { origin, normal }
    }
}