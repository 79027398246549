import React from "react"
import Typography from "@weave-design/typography";
import { CustomPanelType, CustomPanelTypeStatus, ICustomPanelType } from "../../responses/customPanelTypes";
import { formatLength } from "./customComponentCommon";
import { CustomComponentCard } from "./customComponentCard";
import "./customPanelComponentCard.css";

type Props = {
    panel: ICustomPanelType;
    selectLibraryComponent: (component: CustomPanelType) => void;
}

export const CustomPanelComponentCard = ({ panel, selectLibraryComponent }: Props) => {
    return <CustomComponentCard
        panel={panel}
        selectLibraryComponent={selectLibraryComponent}
        typeIcon="custom-panel-component-card-stats-custom-panel-type"
        panelProperties={<>
            <div><Typography>Width: {panel.status === CustomPanelTypeStatus.Published ? formatLength(panel.length) : "???"}</Typography></div>
            <div><Typography>Height: {panel.status === CustomPanelTypeStatus.Published ? formatLength(panel.height) : "???"}</Typography></div>
        </>} />
}