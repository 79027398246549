import { ModelElementFaceSource } from '../wallgeometry/modelElementFace';
import { NBuf } from './nBuf';
import { Node } from './node';
import { Polygon } from './polygon';
import { Vector } from './vector';
import { Vertex } from './vertex';

/**
 * Holds a binary space partition tree representing a 3D solid. Two solids can
 * be combined using the `union()`, `subtract()`, and `intersect()` methods.
 */
export class CSG {
    source?: ModelElementFaceSource;

    static fromPolygons(polygons: Polygon[]): CSG {
        const csg = new CSG();
        csg.polygons = polygons;
        return csg;
    }

    static fromGeometry(geom: THREE.BufferGeometry): CSG {
        let polys = [];
        const posattr = geom.attributes.position;
        const normalattr = geom.attributes.normal;
        const uvattr = geom.attributes.uv;
        const colorattr = geom.attributes.color;
        let index;

        if (geom.index) {
            index = geom.index.array!;
        } else {
            index = new Array((posattr.array!.length / posattr.itemSize) | 0);
            for (let i = 0; i < index.length; i++) index[i] = i;
        }

        const triCount = (index.length / 3) | 0;
        polys = new Array(triCount);

        for (let i = 0, pli = 0, l = index.length; i < l; i += 3, pli++) {
            const vertices = new Array(3);
            for (let j = 0; j < 3; j++) {
                const vi = index[i + j];
                const vp = vi * 3;
                const vt = vi * 2;
                const x = posattr.array![vp];
                const y = posattr.array![vp + 1];
                const z = posattr.array![vp + 2];
                const nx = normalattr.array![vp];
                const ny = normalattr.array![vp + 1];
                const nz = normalattr.array![vp + 2];
                const u = uvattr?.array![vt];
                const v = uvattr?.array![vt + 1];

                vertices[j] = new Vertex(
                    new Vector(x, y, z),
                    new Vector(nx, ny, nz),
                    new Vector(u, v, 0),
                    colorattr &&
                    new Vector(
                        colorattr.array![vt],
                        colorattr.array![vt + 1],
                        colorattr.array![vt + 2]
                    )
                );
            }

            polys[pli] = new Polygon(vertices);
        }
        return CSG.fromPolygons(polys.filter((p) => !isNaN(p.plane.normal.x)));
    }

    static toGeometry(csg: CSG, vbStride: number): THREE.BufferGeometry {
        const polygons = csg.polygons;

        const triCount = 3 * polygons.reduce((acc, elem) => acc + elem.vertices.length - 2, 0);

        const geom = Autodesk.Viewing.Private.createBufferGeometry();

        const vertices = new NBuf(Float32Array, triCount, 3);
        const vertexBuffer = new NBuf(Float32Array, triCount, vbStride);
        const normals = new NBuf(Uint16Array, triCount, 3);

        for (const p of polygons) {
            const pvs = p.vertices;
            const pvlen = pvs.length;
            for (let j = 3; j <= pvlen; j++) {
                vertices.write(pvs[0].pos);
                vertices.write(pvs[j - 2].pos);
                vertices.write(pvs[j - 1].pos);
                vertexBuffer.write(pvs[0].pos);
                vertexBuffer.write(pvs[j - 2].pos);
                vertexBuffer.write(pvs[j - 1].pos);
                normals.write(pvs[0].normal);
                normals.write(pvs[j - 2].normal);
                normals.write(pvs[j - 1].normal);
            }
        }

        const positionAttribute = new THREE.BufferAttribute(vertices.array, 3);
        positionAttribute.bytesPerItem = 4;

        geom.setAttribute('position', positionAttribute);


        // @ts-ignore
        geom.vb = vertexBuffer.array;
        geom.vbstride = vbStride;

        const indices = new Uint16Array(positionAttribute.count);
        for (let i = 0; i < indices.length; ++i)
            indices[i] = i;

        // @ts-ignore
        geom.ib = indices;
        
        const indexAttribute = new THREE.BufferAttribute(indices, 1);
        indexAttribute.bytesPerItem = 2;

        geom.setIndex(indexAttribute);

        const normalAttribute = new THREE.BufferAttribute(normals.array, 3);
        normalAttribute.bytesPerItem = 2;

        geom.setAttribute("normal", normalAttribute);


        return geom;
    }

    static fromMesh(mesh: THREE.Mesh): CSG {
        const csg = CSG.fromGeometry(mesh.geometry as THREE.BufferGeometry);
        const ttvv0 = new THREE.Vector3();
        const tmpm3 = new THREE.Matrix3();
        tmpm3.getNormalMatrix(mesh.matrix);
        for (let i = 0; i < csg.polygons.length; i++) {
            const p = csg.polygons[i];
            for (let j = 0; j < p.vertices.length; j++) {
                const v = p.vertices[j];
                v.pos.copy(ttvv0.copy(v.pos.toVector3()).applyMatrix4(mesh.matrix));
                v.normal.copy(ttvv0.copy(v.normal.toVector3()).applyMatrix3(tmpm3));
            }
        }
        return csg;
    }

    private polygons = new Array<Polygon>();

    clone(): CSG {
        const csg = new CSG();
        csg.polygons = this.polygons
            .map((p) => p.clone())
            .filter((p) => Number.isFinite(p.plane.w));
        return csg;
    }

    toPolygons(): Polygon[] {
        return this.polygons;
    }

    union(csg: CSG): CSG {
        const a = new Node(this.clone().polygons);
        const b = new Node(csg.clone().polygons);
        a.clipTo(b);
        b.clipTo(a);
        b.invert();
        b.clipTo(a);
        b.invert();
        a.build(b.allPolygons());
        return CSG.fromPolygons(a.allPolygons());
    }

    subtract(csg: CSG): CSG {
        const a = new Node(this.clone().polygons);
        const b = new Node(csg.clone().polygons);
        a.invert();
        a.clipTo(b);
        b.clipTo(a);
        b.invert();
        b.clipTo(a);
        b.invert();
        a.build(b.allPolygons());
        a.invert();
        return CSG.fromPolygons(a.allPolygons());
    }

    intersect(csg: CSG): CSG {
        const a = new Node(this.clone().polygons);
        const b = new Node(csg.clone().polygons);
        a.invert();
        b.clipTo(a);
        b.invert();
        a.clipTo(b);
        b.clipTo(a);
        a.build(b.allPolygons());
        a.invert();
        return CSG.fromPolygons(a.allPolygons());
    }

    // Return a new CSG solid with solid and empty space switched. This solid is
    // not modified.
    inverse(): CSG {
        const csg = this.clone();
        for (const p of csg.polygons) {
            p.flip();
        }
        return csg;
    }

    toGeometry(vbStride: number): THREE.BufferGeometry {
        return CSG.toGeometry(this, vbStride);
    }
}