import React, { useState } from "react"
import ReactDOM from "react-dom";
import Button from "@weave-design/button";
import { Save24 } from "@weave-design/icons";
import { BasicResponse } from "../../responses/basicResponses";
import { ProcessingPane } from "../processing-pane/processingPane";
import { ProcessingFailure } from "../processing-failure/processingFailure";
import "./customComponentPublishContol.css"

type Props = {
    disabled: boolean;
    publishComponent: () => Promise<BasicResponse>;
}

export const CustomComponentPublishContol = ({ disabled, publishComponent }: Props) => {
    const [asyncOperation, setAsyncOperation] = useState<AsyncOperation>();
    const [processingError, setProcessingError] = useState<string>();

    const onPublishRequested = async () => {
        setAsyncOperation({ title: "Publishing...", message: "Publishing custom component..." });

        const result = await publishComponent();

        setAsyncOperation(undefined);

        if (!result.isSuccess)
            setProcessingError(result.message);
    }

    return <>
        <div className="custom-component-publish-control-container">
            <div className="custom-component-publish-control-button-container">
                <Button
                    title="Publish"
                    icon={<Save24 />}
                    type="primary"
                    size="standard"
                    disabled={disabled}
                    onClick={onPublishRequested} />
            </div>
        </div>

        {asyncOperation && ReactDOM.createPortal(<ProcessingPane
            isVisible={true}
            message={asyncOperation.message}
            title={asyncOperation.title}
        />, document.getElementById("root")!)}

        {processingError && ReactDOM.createPortal(<ProcessingFailure
            title="Failed to publish custom component"
            message={processingError}
            closeProcessingErrorDialog={() => setProcessingError(undefined)}
            isVisible={true} />, document.getElementById("root")!)}
    </>
}

type AsyncOperation = {
    title: string;
    message: string;
}