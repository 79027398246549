import { IPanelSourceOpening } from "../../../responses/panelSource";
import { SystemSettings } from "../../../responses/systemSettings";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { PanelWindowCell } from "./panelWindowCell";
import { PanelWindowGlass } from "./panelWindowGlass";
import { PanelWindowSill } from "./panelWindowSill";

export class PanelWindow {
    private readonly windowGlass: PanelWindowGlass;
    private readonly windowSill: PanelWindowSill;
    private readonly windowCells: PanelWindowCell[] = [];

    constructor(panelWindowDefinition: IPanelSourceOpening, systemSetting: SystemSettings, transform: THREE.Matrix4,
        claddingExtrusionFactory: CladdingCellExtrusionGeometryFactory, boundaryLoopsFactory: BoundaryLoopsFactory, glassMaterial: THREE.Material) {

        const windowOverlapsSetting = systemSetting.windowOverlaps;

        const windowBox = new THREE.Box3(
            new THREE.Vector3(panelWindowDefinition.box.min.x + windowOverlapsSetting.left, panelWindowDefinition.box.min.y, 0),
            new THREE.Vector3(panelWindowDefinition.box.max.x - windowOverlapsSetting.right, panelWindowDefinition.box.max.y - windowOverlapsSetting.top, 0)
        );

        const glassBoundaryLoop = boundaryLoopsFactory.createLoopFromBox(windowBox, transform);

        this.windowGlass = new PanelWindowGlass(glassBoundaryLoop, glassMaterial, claddingExtrusionFactory);
        this.windowSill = new PanelWindowSill(panelWindowDefinition, systemSetting, transform, boundaryLoopsFactory, claddingExtrusionFactory);

        for (const cellSource of panelWindowDefinition.cells) {
            this.windowCells.push(new PanelWindowCell(panelWindowDefinition, cellSource, systemSetting, transform, true, boundaryLoopsFactory, claddingExtrusionFactory));
            this.windowCells.push(new PanelWindowCell(panelWindowDefinition, cellSource, systemSetting, transform, false, boundaryLoopsFactory, claddingExtrusionFactory));
        }
    }

    createGeometry(): ExtrusionGeometry[] {
        return [
            this.windowGlass.createGeometry(),
            this.windowSill.createGeometry(),
            ...this.windowCells.map(x => x.createGeometry())
        ];
    }
}