import axios, { AxiosError } from 'axios';
import { UpdateElementCommand } from './commands/updateElementCommand';
import { BasicItemResponse, BasicItemsResponse, BasicResponse } from './responses/basicResponses';
import { ICornerPanelSource } from './responses/cornerPanelSource';
import { IForgeToken } from "./responses/forgeToken";
import { IInsulatedGlassUnit } from './responses/insulatedGlassUnit';
import { IModelCorner } from './responses/modelCorner';
import { IPanelSource } from './responses/panelSource';
import { ISourceModel } from "./responses/sourceModel";
import { SystemSettings } from './responses/systemSettings';
import { IWallFace } from './responses/wallFace';
import { IFamily } from './responses/panelType';
import { UpdateHooksCommand } from './commands/updateHooksCommand';
import { CreatePanelTypeCommand } from './commands/createPanelTypeCommand';
import { RenamePanelTypeCommand } from './commands/renamePanelTypeCommand';
import { ChangePanelTypeCommand } from './commands/changePanelTypeCommand';
import { FontGeometry } from './responses/fontGeometry';
import { IPanelGeneratedModelDto } from './responses/panelGeneratedModelDto';
import { IPanelGeneratedDrawingDto } from './responses/panelGeneratedDrawingDto';
import { IHooksGeneratedModelDto } from './responses/hooksGeneratedModelDto';
import { ICustomCornerType, ICustomPanelType, ICustomPanelTypeCreatedResponse, ICustomZShapeType } from './responses/customPanelTypes';
import { PublishCustomCornerTypeCommand, PublishCustomPanelTypeCommand, PublishCustomZShapePanelTypeCommand } from './commands/publishCustomPanelTypeCommand';
import { ICustomPanelSource } from './responses/customPanelSource';
import { CreateCustomPanelCommand } from './commands/createCustomPanelCommand';
import { UpdateCustomPanelOffsetCommand } from './commands/updateCustomPanelOffsetCommand';
import { ICustomCornerSource } from './responses/customCornerSource';
import { CreateCustomCornerCommand } from './commands/createCustomCornerCommand';
import { UpdateCustomCornerOffsetCommand } from './commands/updateCustomCornerOffsetCommand';
import { ICustomZShapedPanelSource } from './responses/customZShapedPanelSource';
import { CreateCustomZShapedPanelCommand } from './commands/createCustomZShapedPanelCommand';
import { StickyNote, StickyNoteSourceType } from './responses/stickyNote';
import { PanelTypeGenerationStartedResponse } from './responses/panelTypeGenerationStartedResponse';

const AuthorizationHeader = 'Authorization';
const unknownFailureMessage = "Server error. Repeat operation later";

class Repository {
    private accessToken: string | undefined;

    async getForgeToken(): Promise<BasicItemResponse<IForgeToken>> {
        try {
            const response = await axios.get<BasicItemResponse<IForgeToken>>("/api/forge/token");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findModel(id: string): Promise<BasicItemResponse<ISourceModel>> {
        try {
            const response = await axios.get<BasicItemResponse<ISourceModel>>(`/api/models/${id}`);

            return response.data;

        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async loadInsulatedGlassUnits(): Promise<BasicItemsResponse<IInsulatedGlassUnit>> {
        try {
            const response = await axios.get<BasicItemsResponse<IInsulatedGlassUnit>>("https://studio.dextall.com/insulated-glass-units");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelSystemSettings(id: string): Promise<BasicItemResponse<SystemSettings>> {
        try {
            const response = await axios.get<BasicItemResponse<SystemSettings>>(`https://studio.dextall.com/models/${id}/system-settings`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findModelFaces(id: string): Promise<BasicItemsResponse<IWallFace>> {
        try {
            const response = await axios.get<BasicItemsResponse<IWallFace>>(`/api/models/${id}/wall-faces`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCorners(id: string): Promise<BasicItemsResponse<IModelCorner>> {
        try {
            const response = await axios.get<BasicItemsResponse<IModelCorner>>(`/api/models/${id}/wall-corners`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelPanels(id: string): Promise<BasicItemsResponse<IPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelSource>>(`/api/models/${id}/panels`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCornerPanels(id: string): Promise<BasicItemsResponse<ICornerPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICornerPanelSource>>(`/api/models/${id}/corners`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCustomPanels(id: string): Promise<BasicItemsResponse<ICustomPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomPanelSource>>(`/api/models/${id}/custom-panels`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCustomCorners(id: string): Promise<BasicItemsResponse<ICustomCornerSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomCornerSource>>(`/api/models/${id}/custom-corners`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCustomZShapedPanels(id: string): Promise<BasicItemsResponse<ICustomZShapedPanelSource>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomZShapedPanelSource>>(`/api/models/${id}/custom-z-panels`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelPanelFamilies(id: string): Promise<BasicItemsResponse<IFamily>> {
        try {
            const response = await axios.get<BasicItemsResponse<IFamily>>(`/api/models/${id}/panel-families`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findModelCornerPanelFamilies(id: string): Promise<BasicItemsResponse<IFamily>> {
        try {
            const response = await axios.get<BasicItemsResponse<IFamily>>(`/api/models/${id}/corner-families`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async updatePanel(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${id}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCorner(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${id}`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updatePanelTypeHooks(panelTypeId: string, command: UpdateHooksCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panel-types/${panelTypeId}/hooks`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCornerPanelTypeHooks(panelTypeId: string, command: UpdateHooksCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corner-types/${panelTypeId}/hooks`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async renamePanelType(typeId: string, command: RenamePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panel-types/${typeId}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async renameCornerPanelType(typeId: string, command: RenamePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corner-types/${typeId}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async duplicatePanelType(familyId: string, command: CreatePanelTypeCommand): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/families/${familyId}/duplicate-panel-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async duplicateCornerType(familyId: string, command: CreatePanelTypeCommand): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/families/${familyId}/duplicate-corner-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async setPanelType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${panelId}/switch-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async setCornerPanelType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${panelId}/switch-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async setPanelCustomType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${panelId}/switch-custom-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async setCornerCustomType(panelId: string, command: ChangePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${panelId}/switch-custom-type`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async resetPanelCustomType(panelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/panels/${panelId}/reset-custom-type`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async resetCornerCustomType(panelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/corners/${panelId}/reset-custom-type`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async findPanelTypeGeneratedModel(panelTypeId: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/panel-types/${panelTypeId}/generated-models`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findPanelGeneratedModel(id: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/generated/panel-models/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllPanelGeneratedModels(modelId: string): Promise<BasicItemsResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelGeneratedModelDto>>(`/api/all-types/${modelId}/generated-models`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCornerPanelTypeGeneratedModel(panelTypeId: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/corner-types/${panelTypeId}/generated-models`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findCornerPanelGeneratedModel(id: string): Promise<BasicItemResponse<IPanelGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedModelDto>>(`/api/generated/corner-models/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findPanelGeneratedDrawing(id: string): Promise<BasicItemResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedDrawingDto>>(`/api/generated/panel-drawings/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findCornerPanelGeneratedDrawing(id: string): Promise<BasicItemResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IPanelGeneratedDrawingDto>>(`/api/generated/corner-drawings/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findPanelTypeModelDrawings(panelTypeModelId: string): Promise<BasicItemsResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelGeneratedDrawingDto>>(`/api/generated/panel-models/${panelTypeModelId}/drawings`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCornerPanelTypeModelDrawings(panelTypeModelId: string): Promise<BasicItemsResponse<IPanelGeneratedDrawingDto>> {
        try {
            const response = await axios.get<BasicItemsResponse<IPanelGeneratedDrawingDto>>(`/api/generated/corner-models/${panelTypeModelId}/drawings`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findHooksGeneratedModel(sourceModelId: string): Promise<BasicItemResponse<IHooksGeneratedModelDto>> {
        try {
            const response = await axios.get<BasicItemResponse<IHooksGeneratedModelDto>>(`/api/models/${sourceModelId}/hooks-model`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async loadThreeJsFont(): Promise<FontGeometry> {
        const response = await axios.get<FontGeometry>("/font-geometry.json");

        return response.data;
    }

    async loadCustomComponentMaterialVertexShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customComponentVertex.glsl");

        return response.data;
    }

    async loadCustomComponentMaterialFragmentShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customComponentFragment.glsl");

        return response.data;
    }

    async loadCustomCornerComponentMaterialVertexShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customCornerComponentVertex.glsl");

        return response.data;
    }

    async loadCustomCornerComponentMaterialFragmentShader(): Promise<string> {
        const response = await axios.get<string>("/shaders/customCornerComponentFragment.glsl");

        return response.data;
    }

    async startPanelTypeModelGeneration(panelTypeId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/design-automation/generation/panels/${panelTypeId}/start`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startCornerPanelTypeModelGeneration(panelTypeId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/design-automation/generation/corners/${panelTypeId}/start`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startEntireModelGeneration(modelId: string, regenerateExistingTypes: boolean = false): Promise<BasicItemResponse<PanelTypeGenerationStartedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<PanelTypeGenerationStartedResponse>>("/api/design-automation/generation/model", {
                modelId,
                regenerateExistingTypes
            });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startRevitHooksModelGeneration(modelId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/design-automation/generation/hooks-model/${modelId}/start`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createGeneratedModelDownloadableUrl(id: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.get<BasicItemResponse<string>>(`/api/generated/${id}/downloadable`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createHooksGeneratedModelDownloadableUrl(id: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.get<BasicItemResponse<string>>(`/api/generated/hooks-models/${id}/downloadable`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllCustomPanelTypes(): Promise<BasicItemsResponse<ICustomPanelType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomPanelType>>("/api/custom-panel-types/all");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findPublishedCustomPanelTypes(): Promise<BasicItemsResponse<ICustomPanelType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomPanelType>>("/api/custom-panel-types/published");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCustomPanelType(id: string): Promise<BasicItemResponse<ICustomPanelType>> {
        try {
            const response = await axios.get<BasicItemResponse<ICustomPanelType>>(`/api/custom-panel-types/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllCustomCornerTypes(): Promise<BasicItemsResponse<ICustomCornerType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomCornerType>>("/api/custom-corner-types/all");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findPublishedCustomCornerTypes(): Promise<BasicItemsResponse<ICustomCornerType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomCornerType>>("/api/custom-corner-types/published");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCustomCornerType(id: string): Promise<BasicItemResponse<ICustomCornerType>> {
        try {
            const response = await axios.get<BasicItemResponse<ICustomCornerType>>(`/api/custom-corner-types/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async findAllCustomZShapedTypes(): Promise<BasicItemsResponse<ICustomZShapeType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomZShapeType>>("/api/custom-z-shaped-types/all");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findPublishedCustomZShapedTypes(): Promise<BasicItemsResponse<ICustomZShapeType>> {
        try {
            const response = await axios.get<BasicItemsResponse<ICustomZShapeType>>("/api/custom-z-shaped-types/published");

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, items: null };
        }
    }

    async findCustomZShapedType(id: string): Promise<BasicItemResponse<ICustomZShapeType>> {
        try {
            const response = await axios.get<BasicItemResponse<ICustomZShapeType>>(`/api/custom-z-shaped-types/${id}`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async publishCustomPanelTypeComponent(command: PublishCustomPanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/custom-panel-types/publish", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async publishCustomCornerTypeComponent(command: PublishCustomCornerTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/custom-corner-types/publish", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async publishCustomZShapePanelType(command: PublishCustomZShapePanelTypeCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>("/api/custom-z-shaped-types/publish", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomPanelType(name: string): Promise<BasicItemResponse<ICustomPanelTypeCreatedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelTypeCreatedResponse>>("/api/custom-panel-types/create", { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createCustomCornerType(name: string): Promise<BasicItemResponse<ICustomPanelTypeCreatedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelTypeCreatedResponse>>("/api/custom-corner-types/create", { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async createCustomZShapedType(name: string): Promise<BasicItemResponse<ICustomPanelTypeCreatedResponse>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelTypeCreatedResponse>>("/api/custom-z-shaped-types/create", { name });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async startCustomPanelTypeProcessing(id: string, assemblyFileName: string): Promise<BasicResponse> {
        const command = { id, fileName: assemblyFileName };

        try {
            const response = await axios.post<BasicResponse>("/api/custom-panel-types/process", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startCustomCornerTypeProcessing(id: string, assemblyFileName: string): Promise<BasicResponse> {
        const command = { id, fileName: assemblyFileName };

        try {
            const response = await axios.post<BasicResponse>("/api/custom-corner-types/process", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startCustomZShapedTypeProcessing(id: string, assemblyFileName: string): Promise<BasicResponse> {
        const command = { id, fileName: assemblyFileName };

        try {
            const response = await axios.post<BasicResponse>("/api/custom-z-shaped-types/process", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomPanel(command: CreateCustomPanelCommand): Promise<BasicItemResponse<ICustomPanelSource>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomPanelSource>>("/api/custom-panels/create", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async changeCustomPanelType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-panels/${panelId}/switch-type`, { targetTypeId });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomPanelElementName(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-panels/${id}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomPanelOffset(id: string, command: UpdateCustomPanelOffsetCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-panels/${id}/update-offset`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async removeCustomPanel(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/custom-panels/${id}/remove`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomCorner(command: CreateCustomCornerCommand): Promise<BasicItemResponse<ICustomCornerSource>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomCornerSource>>("/api/custom-corners/create", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async changeCustomCornerType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-corners/${panelId}/switch-type`, { targetTypeId });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomCornerElementName(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-corners/${id}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomCornerOffset(id: string, command: UpdateCustomCornerOffsetCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-corners/${id}/update-offset`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async removeCustomCorner(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/custom-corners/${id}/remove`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async createCustomZShapedPanel(command: CreateCustomZShapedPanelCommand): Promise<BasicItemResponse<ICustomZShapedPanelSource>> {
        try {
            const response = await axios.post<BasicItemResponse<ICustomZShapedPanelSource>>("/api/custom-z-panels/create", command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async changeCustomZShapedPanelType(panelId: string, targetTypeId: string): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-z-panels/${panelId}/switch-type`, { targetTypeId });

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async changeCustomZShapedPanelElementName(id: string, command: UpdateElementCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-z-panels/${id}/name`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async updateCustomZShapedPanelOffset(id: string, command: UpdateCustomCornerOffsetCommand): Promise<BasicResponse> {
        try {
            const response = await axios.patch<BasicResponse>(`/api/custom-z-panels/${id}/update-offset`, command);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async removeCustomZShapedPanel(id: string): Promise<BasicResponse> {
        try {
            const response = await axios.delete<BasicResponse>(`/api/custom-z-panels/${id}/remove`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startStickyNoteGeneration(type: StickyNoteSourceType, panelId: string): Promise<BasicResponse> {
        try {
            const response = await axios.post<BasicResponse>(`/api/${type}/${panelId}/generate-sticky-note`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage };
        }
    }

    async startModelStickyNoteGeneration(modelId: string): Promise<BasicItemResponse<string>> {
        try {
            const response = await axios.post<BasicItemResponse<string>>(`/api/models/${modelId}/sticky-notes/generate`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async getStickyNote(type: StickyNoteSourceType | "models", panelId: string): Promise<BasicItemResponse<StickyNote>> {
        try {
            const response = await axios.post<BasicItemResponse<StickyNote>>(`/api/${type}/${panelId}/downloadable`);

            return response.data;
        } catch (e) {
            if (e instanceof AxiosError && e.response?.data?.message)
                return e.response.data;

            return { isSuccess: false, message: unknownFailureMessage, item: null };
        }
    }

    async uploadFile(file: File, url: string): Promise<BasicResponse> {
        const fileName = file.name;

        try {
            await axios({
                url,
                method: "put",
                data: file,
                headers: {
                    "Content-Disposition": "attachment; filename=\"" + encodeURIComponent(fileName) + "\""
                }
            });

            return { isSuccess: true, message: null };
        } catch (e) {
            return { isSuccess: false, message: "An error occured during file upload process. Try again later!" };
        }
    }

    setAccessToken(value: string) {
        this.accessToken = value;
        axios.defaults.headers.common[AuthorizationHeader] = `Bearer ${value}`;
    }

    getAccessToken(): string {
        if (!this.accessToken)
            throw new Error("Repository was not initialized");

        return this.accessToken;
    }
}

export default new Repository();