import { createNumberComparer } from "@dextall/shared";

export class ModelBuilderObjectIds {
    private freeIds: number[] = [];
    private lastId: number = 0;

    getNewId() {
        const id = this.freeIds.pop();

        if (id)
            return id;

        ++this.lastId;

        return this.lastId;
    }

    getNewSubsequentIds(count: number) {
        const ids = [];

        for (let i = 0; i < count; i++)
            ids.push(this.getNewId());

        return ids.sort(createNumberComparer());
    }

    freeId(id: number) {
        this.freeIds.push(id);
    }

    freeMultipleIds(ids: number[]) {
        for (const id of ids)
            this.freeId(id);
    }

    clear() {
        this.lastId = 0;
        this.freeIds = [];
    }
}