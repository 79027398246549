import quickselect from "./quickselect";

export interface ITreeItemBox {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}

export interface ITreeItem<T> extends ITreeItemBox {
    payload: T | undefined;
}

interface ITreeNode<T> extends ITreeItem<T> {
    leaf: boolean;
    height: number;
    children: ITreeNode<T>[];
}

export class RTree<T> {
    private data: ITreeNode<T>;
    private _minEntries = 4;
    private _maxEntries = 9;

    constructor() {
        this.multiSelect = this.multiSelect.bind(this);
        this.createNode = this.createNode.bind(this);
        this.intersects = this.intersects.bind(this);
        this.contains = this.contains.bind(this);
        this.intersectionArea = this.intersectionArea.bind(this);
        this.enlargedArea = this.enlargedArea.bind(this);
        this.bboxMargin = this.bboxMargin.bind(this);
        this.bboxArea = this.bboxArea.bind(this);
        this.compareNodeMinY = this.compareNodeMinY.bind(this);
        this.compareNodeMinX = this.compareNodeMinX.bind(this);
        this.compareMinX = this.compareMinX.bind(this);
        this.compareMinY = this.compareMinY.bind(this);
        this.extend = this.extend.bind(this);
        this.distBBox = this.distBBox.bind(this);
        this.calcBBox = this.calcBBox.bind(this);
        this.findItem = this.findItem.bind(this);

        this.data = this.createNode();
    }

    all(): ITreeNode<T>[] {
        return this._all(this.data, []);
    }

    search(bbox: ITreeItemBox): ITreeItem<T>[] {
        var node: ITreeNode<T> | undefined = this.data,
            result: ITreeNode<T>[] = [],
            toBBox = this.toBBox;

        if (!this.intersects(bbox, node)) return result;

        var nodesToSearch = [],
            i, len, child, childBBox;

        while (node) {
            for (i = 0, len = node.children.length; i < len; i++) {

                child = node.children[i];
                childBBox = node.leaf ? toBBox(child) : child;

                if (this.intersects(bbox, childBBox)) {
                    if (node.leaf) result.push(child);
                    else if (this.contains(bbox, childBBox)) this._all(child, result);
                    else nodesToSearch.push(child);
                }
            }
            node = nodesToSearch.pop();
        }

        return result;
    }

    collides(bbox: ITreeItemBox): boolean {
        var node: ITreeNode<T> | undefined = this.data, toBBox = this.toBBox;

        if (!this.intersects(bbox, node)) return false;

        var nodesToSearch = [],
            i, len, child, childBBox;

        while (node) {
            for (i = 0, len = node.children.length; i < len; i++) {

                child = node.children[i];
                childBBox = node.leaf ? toBBox(child) : child;

                if (this.intersects(bbox, childBBox)) {
                    if (node.leaf || this.contains(bbox, childBBox)) return true;
                    nodesToSearch.push(child);
                }
            }
            node = nodesToSearch.pop();
        }

        return false;
    }

    load(data: ITreeItem<T>[]) {
        if (data.length < this._minEntries) {
            for (var i = 0, len = data.length; i < len; i++) {
                this.insert(data[i]);
            }
            return this;
        }

        // recursively build the tree with the given data from stratch using OMT algorithm
        var node = this._build(data.map(x => this.createTreeNode(x)).slice(), 0, data.length - 1, 0);

        if (!this.data.children.length) {
            // save as is if tree is empty
            this.data = node;

        } else if (this.data.height === node.height) {
            // split root if trees have the same height
            this._splitRoot(this.data, node);

        } else {
            if (this.data.height < node.height) {
                // swap trees if inserted one is bigger
                var tmpNode = this.data;
                this.data = node;
                node = tmpNode;
            }

            // insert the small tree into the large tree at appropriate level
            this._insert(node, this.data.height - node.height - 1, true);
        }

        return this;
    }

    insert(item: ITreeItem<T>): RTree<T> {
        const node = this.createTreeNode(item);

        this._insert(node, this.data.height - 1);

        return this;
    }

    clear(): RTree<T> {
        this.data = this.createNode();

        return this;
    }

    remove(itemPayload: T, equalsFn: (x: T | undefined, y: T | undefined) => boolean = (x, y) => x === y): RTree<T> {
        const item = this.all().find(x => equalsFn(x.payload, itemPayload));

        if (item)
            this._remove(item, equalsFn);

        return this;
    }

    removeWhere(expression: (x: T | undefined) => boolean): RTree<T> {
        const items = this.all().filter(x => expression(x.payload));

        for (const item of items)
            this._remove(item);

        return this;
    }

    protected toBBox(item: ITreeItem<T> | ITreeNode<T>): ITreeItemBox {
        return item;
    }

    private _all(node: ITreeNode<T>, result: ITreeNode<T>[]): ITreeNode<T>[] {
        const nodesToSearch: ITreeNode<T>[] = [];

        while (node) {
            if (node.leaf) result.push.apply(result, node.children);
            else nodesToSearch.push.apply(nodesToSearch, node.children);

            node = nodesToSearch.pop()!;
        }

        return result;
    }

    private _build(items: ITreeNode<T>[], left: number, right: number, height: number) {
        let N = right - left + 1,
            M = this._maxEntries,
            node;

        if (N <= M) {
            // reached leaf level; return leaf
            node = this.createNode(items.slice(left, right + 1));
            this.calcBBox(node, this.toBBox);
            return node;
        }

        if (!height) {
            // target height of the bulk-loaded tree
            height = Math.ceil(Math.log(N) / Math.log(M));

            // target number of root entries to maximize storage utilization
            M = Math.ceil(N / Math.pow(M, height - 1));
        }

        node = this.createNode([]);
        node.leaf = false;
        node.height = height;

        // split the items into M mostly square tiles

        let N2 = Math.ceil(N / M),
            N1 = N2 * Math.ceil(Math.sqrt(M)),
            i, j, right2, right3;

        this.multiSelect(items, left, right, N1, this.compareMinX);

        for (i = left; i <= right; i += N1) {

            right2 = Math.min(i + N1 - 1, right);

            this.multiSelect(items, i, right2, N2, this.compareMinY);

            for (j = i; j <= right2; j += N2) {

                right3 = Math.min(j + N2 - 1, right2);

                // pack each entry recursively
                node.children.push(this._build(items, j, right3, height - 1));
            }
        }

        this.calcBBox(node, this.toBBox);

        return node;
    }

    private _chooseSubtree(bbox: ITreeItemBox, node: ITreeNode<T>, level: number, path: ITreeNode<T>[]): ITreeNode<T> {
        let i: number, len: number, child: ITreeNode<T> | undefined, targetNode: ITreeNode<T> | undefined, area: number, enlargement, minArea, minEnlargement;

        while (true) {
            path.push(node);

            if (node.leaf || path.length - 1 === level) break;

            minArea = minEnlargement = Infinity;

            for (i = 0, len = node.children.length; i < len; i++) {
                child = node.children[i];
                area = this.bboxArea(child);
                enlargement = this.enlargedArea(bbox, child) - area;

                // choose entry with the least area enlargement
                if (enlargement < minEnlargement) {
                    minEnlargement = enlargement;
                    minArea = area < minArea ? area : minArea;
                    targetNode = child;

                } else if (enlargement === minEnlargement) {
                    // otherwise choose one with the smallest area
                    if (area < minArea) {
                        minArea = area;
                        targetNode = child;
                    }
                }
            }

            node = targetNode || node.children[0];
        }

        return node;
    }

    private _insert(item: ITreeNode<T>, level: number, isNode: boolean = false) {
        var toBBox = this.toBBox,
            bbox = isNode ? item : toBBox(item),
            insertPath: ITreeNode<T>[] = [];

        // find the best node for accommodating the item, saving all nodes along the path too
        var node = this._chooseSubtree(bbox, this.data, level, insertPath);

        // put the item into the node
        node.children.push(item);
        this.extend(node, bbox);

        // split on node overflow; propagate upwards if necessary
        while (level >= 0) {
            if (insertPath[level].children.length > this._maxEntries) {
                this._split(insertPath, level);
                level--;
            } else break;
        }

        // adjust bboxes along the insertion path
        this._adjustParentBBoxes(bbox, insertPath, level);
    }

    private _remove(item: ITreeNode<T>, equalsFn: (x: T | undefined, y: T | undefined) => boolean = (x, y) => x === y) {
        const bbox = this.toBBox(item);

        let node: ITreeNode<T> | undefined | null = this.data,
            path: ITreeNode<T>[] = [],
            indexes: number[] = [],
            i: number = 0,
            parent: ITreeNode<T> | undefined | null,
            index: number,
            goingUp: boolean = false;

        // depth-first iterative tree traversal
        while (node || path.length) {
            if (!node) { // go up
                node = path.pop();
                parent = path[path.length - 1];
                i = indexes.pop()!;
                goingUp = true;
            }

            if (!node)
                return this;

            if (node.leaf) { // check current node
                index = this.findItem(item, node.children, equalsFn);

                if (index !== -1) {
                    // item found, remove the item and condense tree upwards
                    node.children.splice(index, 1);
                    path.push(node);
                    this._condense(path);
                    return this;
                }
            }

            if (!goingUp && !node.leaf && this.contains(node, bbox)) { // go down
                path.push(node);
                indexes.push(i);
                i = 0;
                parent = node;
                node = node.children[0];

            } else if (parent) { // go right
                i++;
                node = parent.children[i];
                goingUp = false;

            } else node = null; // nothing found
        }
    }

    private _split(insertPath: ITreeNode<T>[], level: number) {
        const node = insertPath[level],
            M = node.children.length,
            m = this._minEntries;

        this._chooseSplitAxis(node, m, M);

        const splitIndex = this._chooseSplitIndex(node, m, M);

        const newNode = this.createNode(node.children.splice(splitIndex, node.children.length - splitIndex));
        newNode.height = node.height;
        newNode.leaf = node.leaf;

        this.calcBBox(node, this.toBBox);
        this.calcBBox(newNode, this.toBBox);

        if (level) insertPath[level - 1].children.push(newNode);
        else this._splitRoot(node, newNode);
    }

    private _adjustParentBBoxes(bbox: ITreeItemBox, path: ITreeNode<T>[], level: number) {
        // adjust bboxes along the given tree path
        for (let i = level; i >= 0; i--) {
            this.extend(path[i], bbox);
        }
    }

    private _splitRoot(node: ITreeNode<T>, newNode: ITreeNode<T>) {
        this.data = this.createNode([node, newNode]);
        this.data.height = node.height + 1;
        this.data.leaf = false;
        this.calcBBox(this.data, this.toBBox);
    }

    private _chooseSplitIndex(node: ITreeNode<T>, m: number, M: number) {
        let i: number, bbox1: ITreeItemBox, bbox2: ITreeItemBox, overlap: number, area: number, minOverlap: number, minArea: number, index: number = -1;

        minOverlap = minArea = Infinity;

        for (i = m; i <= M - m; i++) {
            bbox1 = this.distBBox(node, 0, i, this.toBBox);
            bbox2 = this.distBBox(node, i, M, this.toBBox);

            overlap = this.intersectionArea(bbox1, bbox2);
            area = this.bboxArea(bbox1) + this.bboxArea(bbox2);

            // choose distribution with minimum overlap
            if (overlap < minOverlap) {
                minOverlap = overlap;
                index = i;

                minArea = area < minArea ? area : minArea;

            } else if (overlap === minOverlap) {
                // otherwise choose distribution with minimum area
                if (area < minArea) {
                    minArea = area;
                    index = i;
                }
            }
        }

        return index;
    }

    private _chooseSplitAxis(node: ITreeNode<T>, m: number, M: number) {
        const compareMinX = node.leaf ? this.compareMinX : this.compareNodeMinX,
            compareMinY = node.leaf ? this.compareMinY : this.compareNodeMinY,
            xMargin = this._allDistMargin(node, m, M, compareMinX),
            yMargin = this._allDistMargin(node, m, M, compareMinY);

        // if total distributions margin value is minimal for x, sort by minX,
        // otherwise it's already sorted by minY
        if (xMargin < yMargin) node.children.sort(compareMinX);
    }

    private _allDistMargin(node: ITreeNode<T>, m: number, M: number, compare: (a: ITreeItemBox, b: ITreeItemBox) => number): number {
        node.children.sort(compare);

        let toBBox = this.toBBox,
            leftBBox = this.distBBox(node, 0, m, toBBox),
            rightBBox = this.distBBox(node, M - m, M, toBBox),
            margin = this.bboxMargin(leftBBox) + this.bboxMargin(rightBBox),
            i, child;

        for (i = m; i < M - m; i++) {
            child = node.children[i];
            this.extend(leftBBox, node.leaf ? toBBox(child) : child);
            margin += this.bboxMargin(leftBBox);
        }

        for (i = M - m - 1; i >= m; i--) {
            child = node.children[i];
            this.extend(rightBBox, node.leaf ? toBBox(child) : child);
            margin += this.bboxMargin(rightBBox);
        }

        return margin;
    }

    private _condense(path: ITreeNode<T>[]) {
        // go through the path, removing empty nodes and updating bboxes
        for (let i = path.length - 1, siblings; i >= 0; i--) {
            if (path[i].children.length === 0) {
                if (i > 0) {
                    siblings = path[i - 1].children;
                    siblings.splice(siblings.indexOf(path[i]), 1);

                } else this.clear();

            } else this.calcBBox(path[i], this.toBBox);
        }
    }

    private findItem(item: ITreeItem<T>, items: ITreeItem<T>[], equalsFn: (x: T | undefined, y: T | undefined) => boolean): number {
        for (var i = 0; i < items.length; i++) {
            if (equalsFn(item.payload, items[i].payload)) return i;
        }
        return -1;
    }


    private createTreeNode(item: ITreeItem<T>) {
        const node = this.createNode();

        node.minX = item.minX;
        node.minY = item.minY;
        node.maxX = item.maxX;
        node.maxY = item.maxY;
        node.payload = item.payload;

        return node;
    }

    private createNode(children: ITreeNode<T>[] = []): ITreeNode<T> {
        return {
            children: children,
            height: 1,
            leaf: true,
            minX: Infinity,
            minY: Infinity,
            maxX: -Infinity,
            maxY: -Infinity,
            payload: undefined
        }
    }

    private calcBBox(node: ITreeNode<T>, toBBox: (item: ITreeItem<T> | ITreeNode<T>) => ITreeItemBox): ITreeNode<T> {
        return this.distBBox(node, 0, node.children.length, toBBox, node);
    }

    // min bounding rectangle of node children from k to p-1
    private distBBox(node: ITreeNode<T>, k: number, p: number, toBBox: (item: ITreeItem<T> | ITreeNode<T>) => ITreeItemBox, destNode?: ITreeNode<T> | undefined): ITreeNode<T> {
        if (!destNode) destNode = this.createNode([]);
        destNode.minX = Infinity;
        destNode.minY = Infinity;
        destNode.maxX = -Infinity;
        destNode.maxY = -Infinity;

        for (var i = k, child; i < p; i++) {
            child = node.children[i];
            this.extend(destNode, node.leaf ? toBBox(child) : child);
        }

        return destNode!;
    }

    private bboxArea(a: ITreeItemBox): number {
        return (a.maxX - a.minX) * (a.maxY - a.minY);
    }

    private bboxMargin(a: ITreeItemBox): number {
        return (a.maxX - a.minX) + (a.maxY - a.minY);
    }

    private enlargedArea(a: ITreeItemBox, b: ITreeItemBox): number {
        return (Math.max(b.maxX, a.maxX) - Math.min(b.minX, a.minX)) *
            (Math.max(b.maxY, a.maxY) - Math.min(b.minY, a.minY));
    }

    private intersectionArea(a: ITreeItemBox, b: ITreeItemBox): number {
        var minX = Math.max(a.minX, b.minX),
            minY = Math.max(a.minY, b.minY),
            maxX = Math.min(a.maxX, b.maxX),
            maxY = Math.min(a.maxY, b.maxY);

        return Math.max(0, maxX - minX) *
            Math.max(0, maxY - minY);
    }

    private contains(a: ITreeItemBox, b: ITreeItemBox): boolean {
        return a.minX <= b.minX &&
            a.minY <= b.minY &&
            b.maxX <= a.maxX &&
            b.maxY <= a.maxY;
    }

    private intersects(a: ITreeItemBox, b: ITreeItemBox): boolean {
        return b.minX <= a.maxX &&
            b.minY <= a.maxY &&
            b.maxX >= a.minX &&
            b.maxY >= a.minY;
    }

    private extend(a: ITreeItemBox, b: ITreeItemBox): ITreeItemBox {
        a.minX = Math.min(a.minX, b.minX);
        a.minY = Math.min(a.minY, b.minY);
        a.maxX = Math.max(a.maxX, b.maxX);
        a.maxY = Math.max(a.maxY, b.maxY);
        return a;
    }

    private compareNodeMinX(a: ITreeItemBox, b: ITreeItemBox): number { return a.minX - b.minX; }

    private compareNodeMinY(a: ITreeItemBox, b: ITreeItemBox): number { return a.minY - b.minY; }

    private compareMinX(a: ITreeItemBox, b: ITreeItemBox): number { return this.compareNodeMinX(a, b); }

    private compareMinY(a: ITreeItemBox, b: ITreeItemBox): number { return this.compareNodeMinY(a, b); }

    private multiSelect(arr: ITreeNode<T>[], left: number, right: number, n: number, compare: (a: ITreeItemBox, b: ITreeItemBox) => number) {
        let stack = [left, right],
            mid;

        while (stack.length) {
            right = stack.pop()!;
            left = stack.pop()!;

            if (right - left <= n) continue;

            mid = left + Math.ceil((right - left) / n / 2) * n;
            quickselect(arr, mid, left, right, compare);

            stack.push(left, mid, mid, right);
        }
    }
}