import { Plane } from "./plane";
import { Vertex } from "./vertex";

export class Polygon {
    plane: Plane;

    constructor(public vertices: Vertex[]) {
        this.plane = Plane.fromPoints(
            vertices[0].pos,
            vertices[1].pos,
            vertices[2].pos
        );
    }

    clone(): Polygon {
        return new Polygon(this.vertices.map((v) => v.clone()));
    }

    flip(): void {
        this.vertices.reverse().map((v) => v.flip());
        this.plane.flip();
    }
}