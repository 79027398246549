import { StickyNote, StickyNoteGenerationStatus, StickyNoteSourceType } from "../../../responses/stickyNote";
import { BasicItemResponse } from "../../../responses/basicResponses";
import repo from "../../../Repository";

export const generatePanelQrCode = async (type: StickyNoteSourceType, id: string): Promise<BasicItemResponse<string>> => {
    const stickyNoteResponse = await repo.getStickyNote(type, id);

    if (!stickyNoteResponse.isSuccess)
        return { ...stickyNoteResponse };

    let stickyNote = stickyNoteResponse.item;

    if (stickyNote.status === StickyNoteGenerationStatus.GeneratedSuccessfully)
        return { isSuccess: true, message: null, item: stickyNote.downloadableUrl };

    if (stickyNote.status === StickyNoteGenerationStatus.NotGenerated || stickyNote.status === StickyNoteGenerationStatus.GenerationFailed) {
        const startQrCodeGenerationResponse = await repo.startStickyNoteGeneration(type, id);

        if (!startQrCodeGenerationResponse.isSuccess)
            return { ...startQrCodeGenerationResponse, item: null };
    }

    stickyNote = await waitForGeneration(type, id);

    return stickyNote.status === StickyNoteGenerationStatus.GeneratedSuccessfully
        ? { isSuccess: true, message: null, item: stickyNote.downloadableUrl }
        : { isSuccess: false, message: "Failed to generate panel QR code", item: null };
}

export const generateModelQrCode = async (modelId: string): Promise<BasicItemResponse<string>> => {
    const startModelStickyNotesGenerationResponse = await repo.startModelStickyNoteGeneration(modelId);

    if (!startModelStickyNotesGenerationResponse.isSuccess)
        return { ...startModelStickyNotesGenerationResponse };

    const id = startModelStickyNotesGenerationResponse.item;

    const stickyNote = await waitForGeneration("models", id);

    return stickyNote.status === StickyNoteGenerationStatus.GeneratedSuccessfully
        ? { isSuccess: true, message: null, item: stickyNote.downloadableUrl }
        : { isSuccess: false, message: "Failed to generate panel QR code", item: null };
}

const waitForGeneration = (type: StickyNoteSourceType | "models", id: string) => {
    return new Promise<StickyNote>(resolve => {
        const interval = 7000;

        const checkStickyNote = async () => {
            const stickyNoteResponse = await repo.getStickyNote(type, id);

            if (stickyNoteResponse.isSuccess && stickyNoteResponse.item.status !== StickyNoteGenerationStatus.InProgress)
                resolve(stickyNoteResponse.item);
            else
                setTimeout(checkStickyNote, interval);
        }

        setTimeout(checkStickyNote, interval);
    });
}