import { CornerHookType, HookProfileSide, HookType, IHookSource } from "../../../responses/hookSource";
import { editorCladdingGeometryThickness } from "../defaults";
import { zFightingFixDistance } from "@dextall/panels-defaults";
import { ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { IModelHook } from "./modelHook";

export class Hook implements IModelHook {
    private _x: number;
    private _y: number;
    private _hookType: HookType;
    private _profileSide: HookProfileSide;

    constructor(private readonly hookSource: IHookSource,
        panelOrigin: THREE.Vector3,
        wallFaceTransform: THREE.Matrix4,
        private readonly basicGeometry: ExtrusionGeometry[],
        public readonly panelId: string,
        public readonly wallFaceId: string,
        public readonly dbId: number,
        public readonly maxX: number,
        public readonly maxY: number) {
        this._x = hookSource.x;
        this._y = hookSource.y;
        this._hookType = hookSource.hookType;
        this._profileSide = hookSource.profileSide;

        this.originMatrix = new THREE.Matrix4().multiplyMatrices(wallFaceTransform, new THREE.Matrix4().setPosition(panelOrigin));
    }

    get hookType(): HookType {
        return this._hookType;
    }

    get profileSide(): HookProfileSide {
        return this._profileSide;
    }

    get cornerHookType(): CornerHookType {
        return this.hookSource.cornerHookType;
    }

    get x(): number {
        return this._x;
    }

    get y(): number {
        return this._y;
    }

    get matrix(): THREE.Matrix4 {
        return this.computeGeometryTransform();
    }

    get alignmentWallFaceId(): string {
        return this.wallFaceId;
    }

    public readonly originMatrix: THREE.Matrix4;

    createGeometry(): ExtrusionGeometry[] {
        const transform = this.computeGeometryTransform();

        return this.basicGeometry.map(x => { return { ...x, matrix: new THREE.Matrix4().multiplyMatrices(transform, x.matrix) } });
    }

    update(hookSource: IModelHook) {
        this.setPosition(hookSource.x, hookSource.y);
        this._hookType = hookSource.hookType;
        this._profileSide = hookSource.profileSide;
    }

    setPosition(x: number, y: number) {
        this._x = x;
        this._y = y;
    }

    private computeGeometryTransform() {
        return new THREE.Matrix4()
            .multiplyMatrices(this.originMatrix,
                new THREE.Matrix4().setPosition(new THREE.Vector3(this.x, this.y, editorCladdingGeometryThickness + zFightingFixDistance)));
    }
}