import { ZShapeGeometry, ZShapeGeometryType } from "@dextall/corner-geometry";
import { ZShapeOptions } from "@dextall/corner-geometry/build/types/src/options";
import { ExtrusionGeometry } from "./claddingCellExtrusionGeometryFactory";
import { ZShapeCladdingGeometryAttributes } from "./zShapeCladdingGeometryAttributes";

export class ZShapeCladdingCellGeometry {
    private readonly wingGeometries: THREE.BufferGeometry[];
    private readonly shelfDirection: THREE.Vector3;
    private readonly shelfStartOuterPoint: THREE.Vector3;
    private readonly shelfEndOuterPoint: THREE.Vector3;
    private readonly rightWingDirection: THREE.Vector3;

    constructor(
        private readonly angle1: number,
        private readonly angle2: number,
        private readonly leftWing: number,
        private readonly shelf: number,
        private readonly rightWing: number,
        private readonly height: number,
        private readonly thickness: number,
        private readonly leftWingDirection: THREE.Vector3
    ) {
        const options: ZShapeOptions = {
            angle1,
            angle2,
            leftWing,
            shelf,
            rightWing,
            height,
            thickness,
            offsetDirection: "out",
            separateWingGeometries: true
        };

        const shape = new ZShapeGeometry(options);

        this.wingGeometries = shape.createGeometry();

        if (this.wingGeometries.length === 3) {
            const attributes = new ZShapeCladdingGeometryAttributes(shape);

            attributes.apply(this.wingGeometries[0], "left-wing");
            attributes.apply(this.wingGeometries[1], "shelf");
            attributes.apply(this.wingGeometries[2], "right-wing");
        }

        const shelfDirection = shape.getShelfDirection();
        this.shelfDirection = new THREE.Vector3(shelfDirection.x, shelfDirection.y, 0);

        const shelfStartOuterPoint = shape.getShelfStartOuterPoint();
        this.shelfStartOuterPoint = new THREE.Vector3(shelfStartOuterPoint.x, shelfStartOuterPoint.y, 0);

        const shelfEndOuterPoint = shape.getShelfEndOuterPoint();
        this.shelfEndOuterPoint = new THREE.Vector3(shelfEndOuterPoint.x, shelfEndOuterPoint.y, 0);

        const rightWingDirection = shape.getRightWingDirection();
        this.rightWingDirection = new THREE.Vector3(rightWingDirection.x, rightWingDirection.y, 0);
    }

    createGeometry(origin: THREE.Vector3, leftWingMaterial: THREE.Material, shelfMaterial: THREE.Material, rightWingMaterial: THREE.Material): ExtrusionGeometry[] {
        if (this.wingGeometries.length !== 3)
            return [];

        const matrix = this.getCornerCoordinateSystem(origin);

        const leftWingGeometry: ExtrusionGeometry = {
            type: "named-shape",
            name: "z-shape-left-wing",
            geometry: this.wingGeometries[0],
            material: leftWingMaterial,
            matrix,
            size: new THREE.Vector3(this.leftWing, this.height, this.angle1)
        };

        const shelfGeometry: ExtrusionGeometry = {
            type: "named-shape",
            name: `z-shape-shelf-${this.angle1}`,
            geometry: this.wingGeometries[1],
            material: shelfMaterial,
            matrix,
            size: new THREE.Vector3(this.shelf, this.height, this.angle2)
        };

        const rightWingGeometry: ExtrusionGeometry = {
            type: "named-shape",
            name: `z-shape-right-wing-${this.shelf}-${this.angle1}`,
            geometry: this.wingGeometries[2],
            material: rightWingMaterial,
            matrix,
            size: new THREE.Vector3(this.rightWing, this.height, this.angle2)
        }

        return [leftWingGeometry, shelfGeometry, rightWingGeometry];
    }

    getCornerCoordinateSystem(origin: THREE.Vector3) {
        basisX.copy(this.leftWingDirection).negate();
        basisY.crossVectors(basisX, down);

        return new THREE.Matrix4().makeBasis(basisX, basisY, basisZ).setPosition(origin);
    }

    getWingMatrix(origin: THREE.Vector3, geometryType: ZShapeGeometryType): THREE.Matrix4 {
        switch (geometryType) {
            case "left-wing":
                return this.getLeftWingMatrix(origin);

            case "shelf":
                return this.getShelfMatrix(origin);

            case "right-wing":
                return this.getRightWingMatrix(origin);

            default:
                throw new Error("Invalid geometry type!");
        }
    }

    private getLeftWingMatrix(origin: THREE.Vector3): THREE.Matrix4 {
        const originMatrix = this.getCornerCoordinateSystem(origin);

        temp.set(-this.leftWing, -this.thickness, 0).applyMatrix4(originMatrix);

        return originMatrix.multiply(rotationX).setPosition(temp);
    }

    private getShelfMatrix(origin: THREE.Vector3): THREE.Matrix4 {
        const rotationMatrix = this.getCornerCoordinateSystem(zero);

        temp.copy(this.shelfDirection).applyMatrix4(rotationMatrix);

        basisX.copy(temp);
        basisY.crossVectors(basisX, down);

        const matrix = new THREE.Matrix4().makeBasis(basisX, basisY, basisZ).setPosition(origin);

        temp.copy(this.shelfStartOuterPoint).applyMatrix4(matrix);

        return matrix.multiply(rotationX).setPosition(temp);
    }

    private getRightWingMatrix(origin: THREE.Vector3): THREE.Matrix4 {
        const rotationMatrix = this.getCornerCoordinateSystem(zero);

        temp.copy(this.rightWingDirection).applyMatrix4(rotationMatrix);

        basisX.copy(temp);
        basisY.crossVectors(basisX, down);

        const matrix = new THREE.Matrix4().makeBasis(basisX, basisY, basisZ).setPosition(origin);

        temp.copy(this.shelfEndOuterPoint).applyMatrix4(matrix);

        return matrix.multiply(rotationX).setPosition(temp);
    }
}

const basisX = new THREE.Vector3();
const basisY = new THREE.Vector3();
const basisZ = new THREE.Vector3(0, 0, 1);
const down = new THREE.Vector3(0, 0, -1);
const zero = new THREE.Vector3();
const temp = new THREE.Vector3();
const rotationX = new THREE.Matrix4().makeRotationX(0.5 * Math.PI);