export interface IHookSource {
    x: number;
    y: number;
    hookType: HookType;
    profileSide: HookProfileSide;
    cornerHookType: CornerHookType;
}

export enum HookType {
    AdjustableLeft = 1,

    AdjustableRight = 2,

    NotAdjustable = 3
}

export enum HookProfileSide {
    Inner = 1,

    Outer = 2
}

export enum CornerHookType {
    None = 0,

    CornerLeftWing = 1,

    CornerRightWing = 2
}