import { isAlmostEqual, isAlmostEqualOrLessThan, isAlmostEqualToZero } from "@dextall/shared";

export class BoundaryLoop {
    private readonly inversedTransform: THREE.Matrix4;

    constructor(private readonly points: THREE.Vector3[], public readonly transform: THREE.Matrix4, inversedTransform?: THREE.Matrix4) {
        this.inversedTransform = inversedTransform || new THREE.Matrix4().getInverse(this.transform);
        this.box = new THREE.Box3().setFromPoints(this.points);
    }

    readonly box: THREE.Box3;

    readonly innerLoops: BoundaryLoop[] = [];

    clone(): BoundaryLoop {
        const points = [...this.points];
        const loop = new BoundaryLoop(points, this.transform, this.inversedTransform);

        for (const innerLoop of this.innerLoops)
            loop.addInnerLoop(innerLoop.clone());

        return loop;
    }

    isSimple(): boolean {
        if (this.innerLoops.length > 0)
            return false;

        const area = this.area();

        const size = this.box.getSize();

        const boxArea = size.x * size.y;

        return isAlmostEqual(area, boxArea);
    }

    createShape(transform?: THREE.Matrix4): THREE.Shape {
        const shape = new THREE.Shape();

        const points = transform
            ? this.points.map(x => new THREE.Vector3().copy(x).applyMatrix4(transform))
            : this.points;

        this.makeShape(shape, points);

        for (const loop of this.innerLoops) {
            const hole = new THREE.Path();

            const loopPoints = transform
                ? loop.points.map(x => new THREE.Vector3().copy(x).applyMatrix4(transform))
                : loop.points;

            this.makeShape(hole, loopPoints);

            shape.holes.push(hole);
        }

        return shape;
    }

    containsLoop(loop: BoundaryLoop): boolean {
        return this.containsPoint(loop.box.min) && this.containsPoint(loop.box.max);
    }

    containsPoint(point: THREE.Vector2 | THREE.Vector3): boolean {
        return isAlmostEqualOrLessThan(this.box.min.x, point.x)
            && isAlmostEqualOrLessThan(this.box.min.y, point.y)
            && isAlmostEqualOrLessThan(point.x, this.box.max.x)
            && isAlmostEqualOrLessThan(point.y, this.box.max.y);
    }

    addInnerLoop(loop: BoundaryLoop) {
        this.innerLoops.push(loop);
    }

    removeInnerLoop(loop: BoundaryLoop | number) {
        const index = loop instanceof BoundaryLoop ? this.innerLoops.findIndex(x => x === loop) : loop;

        if (index === -1)
            return;

        this.innerLoops.splice(index, 1);
    }

    clearInnerLoops() {
        this.innerLoops.length = 0;
    }

    hitTest(point: THREE.Vector3): THREE.Vector3 | null {
        const localPoint = this.toLocalCoordinates(point);

        const hitTestResult = isAlmostEqualOrLessThan(this.box.min.x, localPoint.x) && isAlmostEqualOrLessThan(localPoint.x, this.box.max.x)
            && isAlmostEqualOrLessThan(this.box.min.y, localPoint.y) && isAlmostEqualOrLessThan(localPoint.y, this.box.max.y);

        return hitTestResult ? localPoint : null;
    }

    toLocalCoordinates(point: THREE.Vector3, targetPoint: THREE.Vector3 | null = null): THREE.Vector3 {
        targetPoint = targetPoint || new THREE.Vector3();

        return targetPoint.copy(point).applyMatrix4(this.inversedTransform);
    }

    toWorldCoordinates(localPoint: THREE.Vector3, targetPoint: THREE.Vector3 | null = null): THREE.Vector3 {
        targetPoint = targetPoint || new THREE.Vector3();

        return targetPoint.copy(localPoint).applyMatrix4(this.transform);
    }

    isClockwise(): boolean {
        return this.area() < 0;
    }

    area(): number {
        const n = this.points.length;
        let a = 0.0;

        for (let p = n - 1, q = 0; q < n; p = q++) {
            a += this.points[p].x * this.points[q].y - this.points[q].x * this.points[p].y;
        }

        return a * 0.5;
    }

    hasHoles(): boolean {
        return this.innerLoops.length > 0;
    }

    getCenter(): THREE.Vector3 {
        return new THREE.Vector3().addVectors(this.box.min, this.box.max).multiplyScalar(0.5);
    }

    getBottomLeftCorner(): THREE.Vector3 {
        return new THREE.Vector3(this.box.min.x, this.box.min.y, 0);
    }

    getBottomRightCorner(): THREE.Vector3 {
        return new THREE.Vector3(this.box.max.x, this.box.min.y, 0);
    }

    getTopLeftCorner(): THREE.Vector3 {
        return new THREE.Vector3(this.box.min.x, this.box.max.y, 0);
    }

    getTopRightCorner(): THREE.Vector3 {
        return new THREE.Vector3(this.box.max.x, this.box.max.y, 0);
    }

    isAlmostEqual(otherLoop: BoundaryLoop): boolean {
        return isAlmostEqualToZero(this.getBottomLeftCorner().distanceToSquared(otherLoop.getBottomLeftCorner()))
            && isAlmostEqualToZero(this.getTopRightCorner().distanceToSquared(otherLoop.getTopRightCorner()));
    }

    private makeShape(shape: THREE.Path, points: THREE.Vector3[]) {
        shape.moveTo(points[0].x, points[0].y);

        for (let i = 1; i < points.length; i++) {
            const { x, y } = points[i];
            shape.lineTo(x, y);
        }
    }
}