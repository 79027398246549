import { BoundaryLoop } from "../boundaries/boundaryLoop";

export const getLeftHandedTransformForViewer = (boundaryLoop: BoundaryLoop, zOffset: number) => {
    const zOffsetMatrix = new THREE.Matrix4().setPosition(new THREE.Vector3(0, 0, zOffset));

    const boundaryLoopTransform = boundaryLoop.transform;

    if (boundaryLoopTransform.determinant() > 0)
        return new THREE.Matrix4().multiplyMatrices(boundaryLoopTransform, zOffsetMatrix);

    const basisX = new THREE.Vector3();
    const basisY = new THREE.Vector3();
    const basisZ = new THREE.Vector3();

    boundaryLoopTransform.extractBasis(basisX, basisY, basisZ);

    const matrix = new THREE.Matrix4();

    matrix.makeBasis(basisX, new THREE.Vector3().copy(basisY).negate(), basisZ);

    const currentPosition = new THREE.Vector3()
        .setFromMatrixPosition(boundaryLoopTransform)
        .add(new THREE.Vector3(0, 0, boundaryLoop.box.max.y + boundaryLoop.box.min.y));
    // x ... x+min ... x+max ... x'=x+min+max

    matrix.setPosition(currentPosition);

    return new THREE.Matrix4().multiplyMatrices(matrix, zOffsetMatrix);
}