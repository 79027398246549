import ralColors from "./ralColorsFactory";

class ColorsFactory {
    private colors: Map<string, THREE.MeshPhongMaterial>;

    constructor() {
        this.colors = new Map();

        this.fillColors();
    }

    getColorCodes(): string[] {
        return Array.from(this.colors.keys());
    }

    getLastUsedColorCodes(): string[] {
        return this.getColorCodes().filter(x => this.getColor(x)?.materialManagerName);
    }

    getColor(code: string): THREE.MeshPhongMaterial | undefined {
        return this.colors.get(code);
    }

    contains(code: string) {
        return this.colors.has(code);
    }

    /* 
    * We reuse materials, but Forge viewer scene builder assign it's own references and prevent to add meshes
    * So call this method to fix the following issue - open model with saved panels, then close it and reopen again.
    * There are no more meshes in the scene builder model
    */
    cleanupForgeViewerMatmanReferences() {
        this.colors.clear();

        this.fillColors();
    }

    private fillColors() {
        for (const colorCode of ralColors.getColorCodes())
            this.colors.set(colorCode, new THREE.MeshPhongMaterial({ color: ralColors.getColor(colorCode), name: `dextall-studio-material ${colorCode}` }));
    }
}

export default new ColorsFactory();