import React, { useEffect, useRef, useState } from "react";
import Input from "@weave-design/input";
import Label from "@weave-design/label";
import { roundToDecimalSigns } from "@dextall/shared";
import { evaluate, evaluateImperial } from "@dextall/arithmetic";
import "./inputs.css"

enum EditMode {
    Off,
    Imperial,
    Metric
}

type AuxiliaryControl = {
    control: React.ReactNode;
    containerStyles: React.CSSProperties;
    width: number;
}

type Props = {
    value: number;
    title: string;
    auxiliaryControl?: AuxiliaryControl;
    disabled?: boolean;
    onChange: (value: number) => void;
}

export const ImperialAndMetricDimensionEditor = ({ value, title, auxiliaryControl, disabled, onChange }: Props) => {
    const editModeRef = useRef<EditMode>(EditMode.Off);
    const [editMode, _setEditMode] = useState<EditMode>(EditMode.Off);
    const [imperialEditValue, setImperialEditValue] = useState<string>("");
    const [metricEditValue, setMetricEditValue] = useState<string>("");
    const parentDivRef = useRef<HTMLDivElement>(null);

    const setEditMode = (mode: EditMode) => {
        _setEditMode(mode);
        editModeRef.current = mode;
    }

    useEffect(() => {
        const onKeyPressed = (event: KeyboardEvent) => {
            if (event.key !== "Enter" || editModeRef.current === EditMode.Off)
                return;

            const inputElements = (parentDivRef.current!.parentElement?.querySelectorAll("input:not([disabled])") || []) as HTMLInputElement[];

            const currentInputIndex = Array.from(inputElements).indexOf(event.target as HTMLInputElement);

            const nextIndex = currentInputIndex < inputElements.length - 1 ? currentInputIndex + 1 : 0;

            inputElements[nextIndex].focus();
        }

        parentDivRef.current?.addEventListener("keypress", onKeyPressed);

        return () => {
            parentDivRef.current?.removeEventListener("keypress", onKeyPressed);
        }
    }, []);

    useEffect(() => {
        if (editModeRef.current === EditMode.Imperial)
            setImperialEditValue(Autodesk.Viewing.Private.formatValueWithUnits(value, "ft-and-fractional-in", 3, 3));

        if (editModeRef.current === EditMode.Metric)
            setMetricEditValue(`${roundToDecimalSigns(Autodesk.Viewing.Private.convertUnits("ft", "mm", 1, value), 2)} mm`);
    }, [value]);

    const imperialValue = Autodesk.Viewing.Private.formatValueWithUnits(value, "ft-and-fractional-in", 3, 3);
    const metricValue = `${roundToDecimalSigns(Autodesk.Viewing.Private.convertUnits("ft", "mm", 1, value), 2)} mm`;

    const getImperialEditValue = () => {
        return editMode === EditMode.Imperial ? imperialEditValue : "";
    }

    const getMetricEditValue = () => {
        return editMode === EditMode.Metric ? metricEditValue : "";
    }

    const imperialChanged = (data: any) => {
        const inputValue = data.target.value;

        setImperialEditValue(inputValue);
    }

    const metricChanged = (data: any) => {
        const inputValue = data.target.value;

        setMetricEditValue(inputValue);
    }

    const imperialFocused = (focus: boolean) => {
        if (focus) {
            setEditMode(EditMode.Imperial);
            setImperialEditValue(imperialValue);
            setMetricEditValue("");
        } else if (editMode === EditMode.Imperial) {
            if (imperialEditValue !== imperialValue) {
                const evaluationResult = evaluateImperial(imperialEditValue.trim());

                if (evaluationResult.success)
                    onChange(evaluationResult.result);
            }

            setEditMode(EditMode.Off);
            setImperialEditValue("");
        }
    }

    const metricFocused = (focus: boolean) => {
        if (focus) {
            const millimeters = roundToDecimalSigns(Autodesk.Viewing.Private.convertUnits("ft", "mm", 1, value), 2);
            setEditMode(EditMode.Metric);
            setMetricEditValue(millimeters.toString());
            setImperialEditValue("");
        } else if (editMode === EditMode.Metric) {
            if (metricEditValue !== `${roundToDecimalSigns(Autodesk.Viewing.Private.convertUnits("ft", "mm", 1, value), 2)}`) {
                const evaluationResult = evaluate(metricEditValue.trim());

                if (evaluationResult.success) {
                    const targetValue = Autodesk.Viewing.Private.convertUnits("mm", "ft", 1, evaluationResult.result);

                    onChange(targetValue);
                }
            }

            setEditMode(EditMode.Off);
            setMetricEditValue("");
        }
    }

    const inputValueContainerStyles: React.CSSProperties = {};

    if (auxiliaryControl)
        inputValueContainerStyles.width = `calc(75% - ${auxiliaryControl.width}px)`;

    return <div className="property-input-container" ref={parentDivRef}>
        <div className="property-input-title-container">
            <Label>{title}</Label>
        </div>
        <div className="property-input-value-conainer" style={inputValueContainerStyles}>
            <Input
                placeholder={imperialValue}
                value={getImperialEditValue()}
                onChange={(data: any) => imperialChanged(data)}
                onFocus={() => imperialFocused(true)}
                onBlur={() => imperialFocused(false)}
                stylesheet={inputStyleSheet}
                disabled={!!disabled} />
            <div style={{ width: "15px" }}></div>
            <Input
                placeholder={metricValue}
                value={getMetricEditValue()}
                onChange={(data: any) => metricChanged(data)}
                onFocus={() => metricFocused(true)}
                onBlur={() => metricFocused(false)}
                stylesheet={inputStyleSheet}
                disabled={!!disabled} />
        </div>
        {auxiliaryControl && <div style={auxiliaryControl.containerStyles}>
            {auxiliaryControl.control}
        </div>}
    </div>
}

const inputStyleSheet = (styles: any) => {
    if (!styles.input)
        return styles;

    const inputStyle = { ...styles };

    const placeholderStyle = { color: "rgba(60, 60, 60, 0.7)" };

    inputStyle.input["&::placeholder"] = placeholderStyle;
    inputStyle.input["&::-ms-input-placeholder"] = placeholderStyle;

    return inputStyle;
}