import { ExtrusionGeometry } from "./claddingCellExtrusionGeometryFactory";

const straightStartSegmentLength = 0.5;
const straightEndSegmentLength = 1;
const radius = 0.15;
const elbowRadius = 0.3;
const circleRadius = 1;
const aimCylinderHeight = 0.1;

export class HooksGeometryFactory {
    constructor(private readonly scale: number = 0.1, private readonly material: THREE.Material = new THREE.MeshPhongMaterial({ color: "#000000" })) {

    }

    createGeometry(): ExtrusionGeometry[] {
        const straightSegmentGeometry = new THREE.CylinderBufferGeometry(this.scale * radius, this.scale * radius, this.scale * straightStartSegmentLength);
        
        const elbowSegmentGeometry = new THREE.TorusBufferGeometry(this.scale * elbowRadius, this.scale * radius, 12, 48, 0.5 * Math.PI);

        const endSegmentGeometry = new THREE.CylinderBufferGeometry(this.scale * radius, this.scale * radius, this.scale * straightEndSegmentLength);

        const circleAim = new THREE.CylinderBufferGeometry(this.scale * circleRadius, this.scale * circleRadius, this.scale * aimCylinderHeight, 32);

        const startStraightSegmentTransform = new THREE.Matrix4().makeRotationZ(0.5 * Math.PI).setPosition(new THREE.Vector3(0.5 * this.scale * straightStartSegmentLength, 0, 0));

        const elbowSegmentTransform = new THREE.Matrix4()
            .setPosition(new THREE.Vector3(this.scale * straightStartSegmentLength, -this.scale * elbowRadius, 0));

        const endStraightSegmentTransform = new THREE.Matrix4()
            .setPosition(new THREE.Vector3(this.scale * (straightStartSegmentLength + elbowRadius), this.scale * (elbowRadius - straightEndSegmentLength - 0.5*radius), 0));

        const rotation = new THREE.Matrix4().makeRotationY(-0.5 * Math.PI);

        return [{
            type: "named-shape",
            name: "hook-straight-segment-start",
            geometry: straightSegmentGeometry,
            matrix: new THREE.Matrix4().multiplyMatrices(rotation, startStraightSegmentTransform),
            material: this.material,
            size: new THREE.Vector3(1, 1, 1)
        }, {
            type: "named-shape",
            name: "hook-elbow-segment",
            geometry: elbowSegmentGeometry,
            matrix: new THREE.Matrix4().multiplyMatrices(rotation, elbowSegmentTransform),
            material: this.material,
            size: new THREE.Vector3(1, 1, 1)
        }, {
            type: "named-shape",
            name: "hook-straight-segment-end",
            geometry: endSegmentGeometry,
            matrix: new THREE.Matrix4().multiplyMatrices(rotation, endStraightSegmentTransform),
            material: this.material,
            size: new THREE.Vector3(1, 1, 1)
        }, {
            type: "named-shape",
            name: "hook-circle-aim",
            geometry: circleAim,
            matrix: new THREE.Matrix4().multiplyMatrices(rotation, new THREE.Matrix4().makeRotationZ(0.5 * Math.PI)).setPosition(new THREE.Vector3(0, 0, 0.5 * this.scale * aimCylinderHeight)),
            material: this.material,
            size: new THREE.Vector3(1, 1, 1)
        }];
    }
}