import { CustomComponentFrontViewerNavigator } from "./customComponentFrontViewerNavigator";
import { CustomComponentPlanViewerNavigator } from "./customComponentPlanViewerNavigator";
import { CustomComponentSideViewerNavigator } from "./customComponentSideViewerNavigator";
import { ICustomComponentViewerNavigator } from "./customComponentViewerNavigator";
import { CustomComponentViewerType } from "./customComponentViewerType";

export class CustomComponentViewerNavigatorFactory {
    static createNavigator(viewer: Autodesk.Viewing.GuiViewer3D, type: Exclude<CustomComponentViewerType, "3D">): ICustomComponentViewerNavigator {
        switch (type) {
            case "plan":
                return new CustomComponentPlanViewerNavigator(viewer);
            
            case "front":
                return new CustomComponentFrontViewerNavigator(viewer);

            case "side":
                return new CustomComponentSideViewerNavigator(viewer);
        
            default:
                throw new Error("Wrong custom component viewer!");
        }
    }
}