import React, { useEffect, useRef } from "react"
import { ICornerPanelSource } from "../responses/cornerPanelSource";
import { IInsulatedGlassUnit } from "../responses/insulatedGlassUnit";
import { IModelCorner } from "../responses/modelCorner";
import { IPanelSource } from "../responses/panelSource";
import { ISourceModel } from "../responses/sourceModel";
import { IFamily } from "../responses/panelType";
import { SystemSettings } from "../responses/systemSettings";
import { IWallFace } from "../responses/wallFace";
import { ModelEditorsFactory } from "./forge/editors/modelEditorsFactory";
import { ModelBufferedGeometry } from "./forge/geometry/modelBufferedGeometry";
import { Category, ViewerModelCategoriesMap, ViewerModelCollector } from "./forge/viewer-utils/viewerModelCollector";
import { createModelSelectionLock } from "./forge/viewer-utils/viewerModelSelectionLock";
import { TextGeometryFactory } from "./forge/geometry/textGeometryFactory";
import { setViewerToolsModality } from "./forge/viewer-utils/viewerToolsModality";
import { hooksAlignmentToolName } from "./forge/viewer-tools/hooksAlignmentTool";
import { newHookPlacementToolName } from "./forge/viewer-tools/newHookPlacementTool";
import { newCustomPanelPlacementToolName } from "./forge/viewer-tools/customPanelPlacementTool";
import { ICustomCornerType, ICustomPanelType, ICustomZShapeType } from "../responses/customPanelTypes";
import { ICustomPanelSource } from "../responses/customPanelSource";
import { ICustomCornerSource } from "../responses/customCornerSource";
import { WallFacesCollection } from "./forge/panels/wallFacesCollection";
import { newCustomCornerPlacementToolName } from "./forge/viewer-tools/customCornerPlacementTool";
import { ICustomZShapedPanelSource } from "../responses/customZShapedPanelSource";
import { newCustomZShapePanelToolName } from "./forge/viewer-tools/customZShapedPanelsPlacementTool";
import { BasicItemsResponse } from "../responses/basicResponses";
import { IPanelGeneratedModelDto } from "../responses/panelGeneratedModelDto";

type Props = {
    model: ISourceModel;
    modelFaces: IWallFace[];
    modelCorners: IModelCorner[];
    panels: IPanelSource[];
    cornerPanels: ICornerPanelSource[];
    panelFamilies: IFamily[];
    cornerFamilies: IFamily[];
    systemSetting: SystemSettings;
    insulationGlassUnits: IInsulatedGlassUnit[];
    customPanelTypes: ICustomPanelType[];
    customCornerTypes: ICustomCornerType[];
    customZShapedTypes: ICustomZShapeType[];
    customPanels: ICustomPanelSource[];
    customCorners: ICustomCornerSource[];
    customZShapedPanels: ICustomZShapedPanelSource[];
    textGeometryFactory: TextGeometryFactory;
    generatedModels: IPanelGeneratedModelDto[];
}

export const ModelEditorViewer = ({ model, modelFaces, modelCorners, systemSetting, panels, cornerPanels, panelFamilies,
    cornerFamilies, textGeometryFactory, customPanelTypes, customCornerTypes, customZShapedTypes,
    customPanels, customCorners, customZShapedPanels, generatedModels }: Props) => {
    const viewerDiv = useRef<HTMLDivElement>(null);
    const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();

    const extendViewer = async (forgeViewer: Autodesk.Viewing.GuiViewer3D) => {
        const sceneBuilder = forgeViewer.getExtension("Autodesk.Viewing.SceneBuilder") as Autodesk.Viewing.SceneBuilder | undefined | null;
        const renderingExtension = forgeViewer.getExtension("Autodesk.NPR") as Autodesk.Extensions.NPR.NPRExtension | undefined | null;

        if (!(sceneBuilder && renderingExtension)) {
            setTimeout(() => extendViewer(forgeViewer), 100);
            return;
        }

        renderingExtension.setParameter("style", "edging");
        renderingExtension.setParameter("edges", false);
        renderingExtension.setParameter("preserveColor", false);

        const modelBuilder = await sceneBuilder.addNewModel({ modelNameOverride: "dextall", conserveMemory: true, createWireframe: false });

        await window.Autodesk.Viewing.Document.getAecModelData(forgeViewer.model.getDocumentNode());

        const modelBufferedGeometry = new ModelBufferedGeometry(forgeViewer, modelBuilder);

        const wallFacesCollection = await WallFacesCollection.create(modelFaces, forgeViewer.model);

        const editorsFactory = new ModelEditorsFactory(forgeViewer, model.id, wallFacesCollection, modelCorners, panelFamilies, panels, cornerFamilies,
            cornerPanels, customPanelTypes, customCornerTypes, customZShapedTypes, customPanels, customCorners, customZShapedPanels, systemSetting,
            model.offsetFromWall, modelBufferedGeometry, textGeometryFactory);

        const wallsEditor = editorsFactory.createWallsEditor();

        const panelsEditor = editorsFactory.createPanelsEditor();

        const customPanelsEditor = editorsFactory.createCustomPanelsEditor();

        const cornersEditor = editorsFactory.createCornersEditor();

        const customCornersEditor = editorsFactory.createCustomCornersEditor();

        const customZShapedPanelsModelEditor = editorsFactory.createCustomZShapedPanelsEditor();

        const hooksEditor = editorsFactory.createHooksEditor();

        const hooksGeneratedModelEditor = editorsFactory.createHooksGeneratedModelEditor();

        await import("./forge/extensions/index");

        const extensions: ExtensionLoadOptions[] = [{
            id: "Dextall.ForgeWallsExtension",
            loadOptions: {
                wallsEditor
            }
        }, {
            id: "Dextall.ForgePanelsEditor",
            loadOptions: { panelsEditor }
        }, {
            id: "Dextall.ForgeCustomPanelsEditorExtension",
            loadOptions: {
                customPanelsEditor,
                wallFacesCollection
            }
        }, {
            id: "Dextall.ForgeCornersEditor",
            loadOptions: { cornersEditor }
        }, {
            id: "Dextall.ForgeCustomCornersEditorExtension",
            loadOptions: {
                customCornersEditor,
                modelCorners
            }
        }, {
            id: "Dextall.ForgeCustomZShapedPanelsEditorExtension",
            loadOptions: {
                customZShapedPanelsModelEditor,
                wallFacesCollection,
                modelCorners
            }
        }, {
            id: "Dextall.ForgeHooksEditor",
            loadOptions: {
                hooksEditor,
                panelsEditor,
                cornersEditor
            }
        }, {
            id: "Dextall.ForgeHooksAlignment",
            loadOptions: {
                hooksEditor,
                modelFaces
            }
        }, {
            id: "Dextall.ForgeSearchElementsByIdExtension",
            loadOptions: {
                panelsEditor,
                cornersEditor,
                customPanelsEditor,
                customCornersEditor,
                customZShapedPanelsModelEditor
            }
        }, {
            id: "Dextall.ForgePanel2DEditorExtension",
            loadOptions: {
                panelsEditor,
                cornersEditor,
                hooksEditor,
                customPanelsEditor,
                customCornersEditor,
                customZShapedPanelsModelEditor
            }
        }, {
            id: "Dextall.ForgeHooksModelGenerator",
            loadOptions: {
                hooksGeneratedModelEditor
            }
        }, {
            id: "Dextall.ForgeStickyNotesQRCodesExtension",
            loadOptions: {}
        }, {
            id: "Dextall.ForgeModelsGenerationList",
            loadOptions: {
                panelsEditor,
                cornersEditor,
                customPanelTypes,
                model: modelBufferedGeometry.model,
                generatedModels
            }
        }];

        await loadViewerExtensions(forgeViewer, extensions);

        setViewerToolsModality(forgeViewer, [
            hooksAlignmentToolName,
            newHookPlacementToolName,
            newCustomPanelPlacementToolName,
            newCustomCornerPlacementToolName,
            newCustomZShapePanelToolName])
    }

    const onModelLoaded = async (forgeViewer: Autodesk.Viewing.GuiViewer3D, viewerDocument: Autodesk.Viewing.Document) => {
        try {
            const root = viewerDocument.getRoot();

            const threeDModels = root.get3DModelNodes();

            const model3D = threeDModels.find(x => x.data.name === "Forge") || threeDModels.find(x => x) || root.getDefaultGeometry();

            const createLoadOptions = (hiddenCategories: Category[]): Autodesk.Viewing.ModelLoadOptions | undefined => {
                if (hiddenCategories.length === 0)
                    return undefined;

                const viewerModelCategoriesMap = new ViewerModelCategoriesMap();

                const categories: Autodesk.Viewing.ModelPropertyFilterExpression[] = [];

                for (const category of hiddenCategories) {
                    const categoryId = viewerModelCategoriesMap.findCategoryId(category);

                    if (categoryId === undefined)
                        continue;

                    categories.push({ "?CategoryId": categoryId });
                }

                return {
                    filter: {
                        property_query: {
                            "$not": {
                                "$or": categories
                            }
                        }
                    }
                }
            }

            const viewerModel = await forgeViewer.loadDocumentNode(viewerDocument, model3D, createLoadOptions(["Levels", "Windows"]));

            await forgeViewer.waitForLoadDone();

            const selectionLocker = await createModelSelectionLock(forgeViewer, viewerModel)();

            selectionLocker.lock();

            await extendViewer(forgeViewer);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (viewerDiv.current === null)
            return;

        if (viewer.current)
            viewer.current.finish();

        const config: Autodesk.Viewing.Viewer3DConfig = {
            extensions: ["Autodesk.Viewing.SceneBuilder", "Autodesk.NPR"],
            disabledExtensions: {
                modelBrowser: true,
                propertiesPanel: true,
                explode: true,
                bimwalk: true
            },
            theme: "light-theme"
        }

        const forgeViewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv.current, config);

        viewer.current = forgeViewer;

        const errorCode = forgeViewer.start();

        if (errorCode !== 0) {
            console.error(`Failed to initialize the viewer. Error code = ${errorCode}`);
            return;
        }

        const urnToLoad = `urn:${model.urn}`;

        Autodesk.Viewing.Document.load(urnToLoad, onModelLoaded.bind(null, forgeViewer), (code) => console.error(`Failed to load. Error code =${code}`));

        return () => {
            if (viewer.current) {
                viewer.current.finish();
            }
        };
    }, []);

    return <div className="viewer-container" ref={viewerDiv}></div>
}

type ExtensionLoadOptions = {
    id: string;
    loadOptions: {
        [key: string]: any
    }
}

const loadViewerExtensions = async (viewer: Autodesk.Viewing.GuiViewer3D, extensions: ExtensionLoadOptions[]) => {
    for (const extension of extensions)
        await viewer.loadExtension(extension.id, extension.loadOptions);
}