import { BasicItemResponse } from "../../../../responses/basicResponses";
import { CustomPanelTypeStatus, ICustomPanelType } from "../../../../responses/customPanelTypes";
import repo from "../../../../Repository";

export class CustomPanelTypeUploader {
    async uploadCustomComponent(file: File, assemblyFileName: string): Promise<BasicItemResponse<ICustomPanelType>> {
        const createNewCustomComponentResponse = await repo.createCustomPanelType(file.name);

        if (!createNewCustomComponentResponse.isSuccess)
            return { isSuccess: false, message: createNewCustomComponentResponse.message, item: null };

        const { id, uploadUrl } = createNewCustomComponentResponse.item;

        const uploadFileResult = await repo.uploadFile(file, uploadUrl);

        if (!uploadFileResult.isSuccess)
            return { isSuccess: false, message: uploadFileResult.message, item: null };

        const startComponentProcessingResponse = await repo.startCustomPanelTypeProcessing(id, assemblyFileName);

        if (!startComponentProcessingResponse.isSuccess)
            return { isSuccess: false, message: startComponentProcessingResponse.message, item: null };

        return this.waitForProcessingDone(id);
    }

    private waitForProcessingDone(id: string): Promise<BasicItemResponse<ICustomPanelType>> {
        return new Promise<BasicItemResponse<ICustomPanelType>>(resolve => {
            const timeout = 5000;

            const periodicalCheck = async () => {
                const response = await repo.findCustomPanelType(id);

                if (!response.isSuccess || response.item.status === CustomPanelTypeStatus.Created) {
                    setTimeout(periodicalCheck, timeout);

                    return;
                }

                resolve(response);
            }

            setTimeout(periodicalCheck, timeout);
        });
    }
}