import { isLessThan, isMoreThan } from "@dextall/shared";

type Point = THREE.Vector3 | THREE.Vector2;

type Triangle = {
    a: Point
    b: Point
    c: Point
}

export const isPointInsideTriangle = (point: Point, triangle: Triangle) => {
    const d1 = sign(point, triangle.a, triangle.b);
    const d2 = sign(point, triangle.b, triangle.c);
    const d3 = sign(point, triangle.c, triangle.a);

    const hasNegative = isLessThan(d1, 0) || isLessThan(d2, 0) || isLessThan(d3, 0);
    const hasPositive = isMoreThan(d1, 0) || isMoreThan(d2, 0) || isMoreThan(d3, 0);

    return !(hasNegative && hasPositive);
}

const sign = (p1: Point, p2: Point, p3: Point) => {
    // this is basically Z component of the cross product between (p1 - p3) and (p2 - p3) vectors
    return (p1.x - p3.x) * (p2.y - p3.y) - (p2.x - p3.x) * (p1.y - p3.y);
}