export class ToolInterface {
    protected names: string[];

    constructor() {
        this.names = ["unnamed"];
    }

    getNames() {
        return this.names;
    }

    getName() {
        return this.names[0];
    }

    getPriority() {
        return 0;
    }

    register() { }

    deregister() { }

    activate(_name: string, _viewer: Autodesk.Viewing.GuiViewer3D) {

    }

    deactivate() { }

    update(_highResTimestamp: number) {
        return false;
    }

    handleSingleClick(_event: MouseEvent, _button: number) {
        return false;
    }

    handleDoubleClick(_event: MouseEvent, _button: number) {
        return false;
    }

    handleSingleTap(_event: Event) {
        return false;
    }

    handleDoubleTap(_event: Event) {
        return false;
    }

    handleKeyDown(_event: KeyboardEvent, _keyCode: number) {
        return false;
    }

    handleKeyUp(_event: KeyboardEvent, _keyCode: number) {
        return false;
    }

    handleWheelInput(_delta: number) {
        return false;
    }

    handleButtonDown(_event: MouseEvent, _button: number) {
        return false;
    }

    handleButtonUp(_event: MouseEvent, _button: number) {
        return false;
    }

    handleMouseMove(_event: MouseEvent) {
        return false;
    }

    handleGesture(_event: Event) {
        return false;
    }

    handleBlur(_event: FocusEvent) {
        return false;
    }

    handleResize() { }

    getCursor(): string | null {
        return null;
    }
}