import React from "react"
import Modal from "@weave-design/modal";
import { CustomPanelType, CustomPanelTypeShape } from "../../responses/customPanelTypes";
import { CustomPanelComponentCard } from "./customPanelComponentCard";
import { ContentNotReady } from "../contentNotReady";
import { CustomCornerComponentCard } from "./customCornerComponentCard";
import { CustomZShapedComponentCard } from "./customZShapedComponentCard";

type Props = {
    customPanelTypes: CustomPanelType[];
    selectLibraryComponent: (component: CustomPanelType) => void;
    closeDialog: () => void;
}

export const ComponentsLibraryDialog = ({ customPanelTypes, selectLibraryComponent, closeDialog }: Props) => {
    const createCustomComponentCard = (panelType: CustomPanelType) => {
        switch (panelType.shapeType) {
            case CustomPanelTypeShape.Panel:
                return <CustomPanelComponentCard panel={panelType} selectLibraryComponent={selectLibraryComponent} key={`custom-inventor-panel-component-${panelType.id}`} />

            case CustomPanelTypeShape.Corner:
                return <CustomCornerComponentCard panel={panelType} selectLibraryComponent={selectLibraryComponent} key={`custom-inventor-corner-component-${panelType.id}`} />

            case CustomPanelTypeShape.ZShape:
                return <CustomZShapedComponentCard panel={panelType} selectLibraryComponent={selectLibraryComponent} key={`custom-inventor-z-shaped-component-${panelType.id}`} />

            default:
                throw new Error("Shape type not supported");
        }
    }

    const components = customPanelTypes.map(createCustomComponentCard);

    return <div className="library-components-dialog-container">
        <Modal
            open={true}
            title="Library"
            onCloseClick={closeDialog}
            stylesheet={modalStyles}
        >
            {components}
            {components.length === 0 && <div style={{ height: "100%" }}><ContentNotReady /></div>}
        </Modal>
    </div>
}

const modalStyles = (styles: any) => {
    const rootElement = document.getElementById("root")!;
    const bounds = rootElement.getBoundingClientRect();
    const boundsHeight = bounds.height > 0 ? bounds.height : window.screen.height;
    const windowSize = Math.min(bounds.width, boundsHeight) - 160;

    return {
        ...styles,
        modal: {
            ...styles.modal,
            window: {
                ...styles.modal.window,
                width: windowSize,
                height: windowSize,
                top: 40
            },
            bodyContent: {
                ...styles.modal.bodyContent,
                overflowX: "hidden"
            }
        }
    }
}