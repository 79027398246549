import { IModelCorner, IModelCornerWing } from "../../../responses/modelCorner";
import { ICornerSelectionAimGeometryMaterials } from "./cornerSelectionAimGeometryMaterials";

type DebuggingWingDirectionsMaterials = {
    leftLineMaterial: THREE.LineBasicMaterial;
    rightLineMaterial: THREE.LineBasicMaterial;
}

export class ViewerOverlayCornerSelectorGeometry {
    private readonly size: number = 1;
    private readonly sizeSquared: number = 1;
    private readonly outlineIndices: number[];
    private readonly origin: THREE.Vector3;
    private readonly planes: { x: THREE.Plane, y: THREE.Plane, z: THREE.Plane };
    private readonly debuggingWingDirectionMaterials: DebuggingWingDirectionsMaterials | null;
    private cornerCenterMesh: THREE.Mesh | null = null;

    constructor(private readonly viewer: Autodesk.Viewing.Viewer3D, public readonly corner: IModelCorner, public readonly heightIndex: number,
        private readonly materials: ICornerSelectionAimGeometryMaterials, private readonly drawOverlayName: string,
        private readonly debugCornerDirections: boolean) {
        this.viewer = viewer;

        this.outlineIndices = [
            0, 1,
            1, 3,
            3, 2,
            2, 0
        ];

        this.origin = new THREE.Vector3().copy(this.corner.origin)
            .add(new THREE.Vector3().copy(this.viewer.model.getGlobalOffset()).negate())
            .add(new THREE.Vector3(0, 0, this.corner.heights[this.heightIndex]));

        this.planes = {
            x: new THREE.Plane(new THREE.Vector3(1, 0, 0), -this.origin.x),
            y: new THREE.Plane(new THREE.Vector3(0, 1, 0), -this.origin.y),
            z: new THREE.Plane(new THREE.Vector3(0, 0, 1), -this.origin.z)
        }

        this.debuggingWingDirectionMaterials = debugCornerDirections ? {
            leftLineMaterial: new THREE.LineBasicMaterial({ color: new THREE.Color(1, 0, 0), linewidth: 1, depthTest: false, depthWrite: false }),
            rightLineMaterial: new THREE.LineBasicMaterial({ color: new THREE.Color(0, 1, 0), linewidth: 1, depthTest: false, depthWrite: false })
        } : null;
    }

    draw() {
        const geometry = new THREE.Group();

        const cornerCenterGeometry = new THREE.BufferGeometry().fromGeometry(new THREE.SphereGeometry(0.5, 16, 16));
        const cornerCenterMesh = new THREE.Mesh(cornerCenterGeometry, this.materials.cornerPointMaterial);
        cornerCenterMesh.position.copy(this.origin);

        this.cornerCenterMesh = cornerCenterMesh;

        geometry.add(cornerCenterMesh);

        const planeGeometry = new THREE.PlaneBufferGeometry(2 * this.size, 2 * this.size);
        const planeMesh = new THREE.Mesh(planeGeometry, this.materials.planeMaterial);
        planeMesh.position.copy(this.origin);

        const boundaryLine = this.createPlaneBoundaryLineGeometry(planeMesh);

        planeMesh.add(boundaryLine);
        planeMesh.outline = boundaryLine;

        geometry.add(planeMesh);

        if (this.debugCornerDirections) {
            const createLineGeometry = (wing: IModelCornerWing, lineMaterial: THREE.LineBasicMaterial) => {
                const lineGeometry = new THREE.Geometry();

                const origin = new THREE.Vector3()
                    .copy(this.origin)
                    .add(new THREE.Vector3().copy(this.corner.left.wallOrientation).multiplyScalar(0.01))
                    .add(new THREE.Vector3().copy(this.corner.right.wallOrientation).multiplyScalar(0.01))

                lineGeometry.vertices.push(origin);

                const targetVertex = new THREE.Vector3().copy(origin).add(new THREE.Vector3().copy(wing.direction).multiplyScalar(4));

                lineGeometry.vertices.push(targetVertex);

                lineGeometry.computeLineDistances();
                lineGeometry.lineDistancesNeedUpdate = true;

                return new THREE.Line(lineGeometry, lineMaterial);
            }

            geometry.add(createLineGeometry(this.corner.left, this.debuggingWingDirectionMaterials!.leftLineMaterial));
            geometry.add(createLineGeometry(this.corner.right, this.debuggingWingDirectionMaterials!.rightLineMaterial));
        }

        this.viewer.impl.addOverlay(this.drawOverlayName, geometry);
    }

    intersects(ray: THREE.Ray): boolean {
        const planeIntersection = ray.intersectPlane(this.planes["z"]);

        if (!planeIntersection)
            return false;

        return planeIntersection.distanceToSquared(this.origin) < this.sizeSquared;
    }

    setSelected(selected: boolean) {
        if (this.cornerCenterMesh)
            this.cornerCenterMesh.material = selected ? this.materials.selectedCornerPointMaterial : this.materials.cornerPointMaterial;
    }

    private createPlaneBoundaryLineGeometry(planeMesh: THREE.Mesh) {
        if (!(planeMesh.geometry instanceof THREE.BufferGeometry))
            throw new Error("Bad invocation");

        const pos = planeMesh.geometry.getAttribute('position');

        const planeBoundaryGeometry = new THREE.Geometry();
        for (let i = 0; i < this.outlineIndices.length; i++) {
            planeBoundaryGeometry.vertices.push(new THREE.Vector3().fromBufferAttribute(pos, this.outlineIndices[i]));
        }

        return new THREE.Line(planeBoundaryGeometry, this.materials.lineMaterial);
    }
}