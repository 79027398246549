import { Vector } from "./vector";

type BufferType = Float32Array | Uint16Array;

type BufferConstructor = {
    new(ct: number): BufferType;
}

export class NBuf {
    private top = 0;
    array: BufferType;

    constructor(arrayConstructor: BufferConstructor, count: number, private readonly size: number) {
        this.array = new arrayConstructor(count * size);
    }

    write(v: Vector): void {
        this.array[this.top++] = v.x;
        this.array[this.top++] = v.y;
        if (this.size === 2)
            return;
        this.array[this.top++] = v.z;

        if (this.size > 3)
            this.top++;
    }
}