import React, { useEffect, useState } from "react"
import { CustomPanelType, CustomPanelTypeShape } from "../../../responses/customPanelTypes"
import { ComponentsLibraryDialog } from "../../custom-components-library/componentsLibraryDialog"
import { ProcessingPane } from "../../processing-pane/processingPane";
import eventBus from "../eventBus/eventDispatcher";

type Props = {
    customPanelTypes: CustomPanelType[]
}

export const CustomPanelComponentSelector = ({ customPanelTypes }: Props) => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const [customPanelCreationInProgress, setCustomPanelCreationInProgress] = useState(false);

    useEffect(() => {
        const onOpenLibrary = () => setDialogOpened(true);
        const onCustomPanelCreationPrompt = () => setCustomPanelCreationInProgress(true);
        const onCustomPanelCreated = () => setCustomPanelCreationInProgress(false);

        eventBus.addEventListener("Dextall.CustomPanels.OpenLibrarySelector", onOpenLibrary);
        eventBus.addEventListener("Dextall.CustomPanels.CreateNew", onCustomPanelCreationPrompt);
        eventBus.addEventListener("Dextall.CustomPanels.Created", onCustomPanelCreated);

        return () => {
            eventBus.removeEventListener("Dextall.CustomPanels.OpenLibrarySelector", onOpenLibrary);
            eventBus.removeEventListener("Dextall.CustomPanels.CreateNew", onCustomPanelCreationPrompt);
            eventBus.removeEventListener("Dextall.CustomPanels.Created", onCustomPanelCreated);
        }
    }, []);

    const selectLibraryComponent = (component: CustomPanelType) => {
        if (component.shapeType !== CustomPanelTypeShape.Panel)
            throw new Error("Invalid state!");

        setDialogOpened(false);

        eventBus.dispatchEvent({
            type: "Dextall.CustomPanels.PromptPanelPlacement",
            payload: component
        });
    }

    return <>
        {dialogOpened && <ComponentsLibraryDialog
            customPanelTypes={customPanelTypes}
            closeDialog={() => setDialogOpened(false)}
            selectLibraryComponent={selectLibraryComponent} />}
        {customPanelCreationInProgress && <ProcessingPane title="Custom panel" message="Creating a custom panel..." isVisible={true} />}
    </>
}