import repo from "../../../Repository";
import { guid } from "@dextall/shared";
import { NamedShapeExtrusionGeometry } from "./claddingCellExtrusionGeometryFactory";

type TextGeometry = Omit<NamedShapeExtrusionGeometry, "matrix">

export class TextGeometryFactory {
    private readonly material = new THREE.MeshBasicMaterial({ color: "#ff0000" });
    private readonly geometries = new Map<string, TextGeometry>();
    private readonly options: any;

    private constructor(fontName: string) {
        this.options = { font: fontName, size: 0.3, height: 0.05, curveSegments: 3 }
    }

    createTextGeometry(text: string, matrix: THREE.Matrix4): NamedShapeExtrusionGeometry {
        const existingGeometry = this.geometries.get(text);

        if (existingGeometry)
            return { ...existingGeometry, matrix };

        const textGeometry = new THREE.TextGeometry(text, this.options);

        textGeometry.computeBoundingBox();

        const bufferGeometry = new THREE.BufferGeometry().fromGeometry(textGeometry);

        const geometry: TextGeometry = {
            geometry: bufferGeometry,
            material: this.material,
            name: guid(),
            size: textGeometry.boundingBox.getSize(),
            type: "named-shape"
        }

        this.geometries.set(text, geometry);

        return { ...geometry, matrix };
    }

    computeSize(text: string): THREE.Vector3 {
        return this.createTextGeometry(text, new THREE.Matrix4).size;
    }

    static async create() {
        const fontGeometry = await repo.loadThreeJsFont();

        THREE.FontUtils.loadFace(fontGeometry);

        return new TextGeometryFactory(fontGeometry.familyName.toLowerCase());
    }
}