import { isAlmostEqualToZero } from "@dextall/shared";
import { FaceBoundaryLoop } from "./faceBoundaryLoop";
import { isPointInsideTriangle } from "./pointInTriangleUtils";
import { Triangle } from "./triangle";
import { TriangleEdge } from "./triangleEdge";

export class ModelElementFace {
	private readonly triangles: Triangle[] = [];
	private readonly flatTriangles: Triangle[] = [];
	private coordinateSystem: THREE.Matrix4 | null = null;
	private plane: THREE.Plane

	constructor(triangle: Triangle,
		public readonly source?: ModelElementFaceSource
	) {
		this.plane = triangle.plane;
		
		this.add(triangle);
	}

	isOnPlane(triangle: Triangle): boolean {
		return this.isPanelWallFace(triangle.normal, triangle.a);
	}

	add(triangle: Triangle) {
		this.triangles.push(triangle);

		if (!triangle.isDegenerated())
			this.plane = triangle.plane;
	}

	getBoundaryLoops(): FaceBoundaryLoop[] {
		const edges = this.getBoundaryEdges();

		const edgeByStartVertex = new Map<number, TriangleEdge>();

		for (const edge of edges)
			edgeByStartVertex.set(edge.ia, edge);

		edgeByStartVertex.delete(edges[0].ia);

		let currentLoop = [edges[0]];
		const boundaryEdgeLoops = [currentLoop];

		while (edgeByStartVertex.size > 0) {
			const nextEdgeStartVertexIndex = currentLoop[currentLoop.length - 1].ib;

			const nextEdge = edgeByStartVertex.get(nextEdgeStartVertexIndex);

			if (nextEdge) {
				currentLoop.push(nextEdge);
				edgeByStartVertex.delete(nextEdgeStartVertexIndex);
			} else {
				const nextLoopStartEdge = edgeByStartVertex.entries().next().value[1];
				edgeByStartVertex.delete(nextLoopStartEdge.ia);
				currentLoop = [nextLoopStartEdge];
				boundaryEdgeLoops.push(currentLoop);
			}
		}

		return boundaryEdgeLoops.map(x => new FaceBoundaryLoop(this, x));
	}

	getCoordinateSystem() {
		if (this.coordinateSystem !== null)
			return this.coordinateSystem;

		const triangle = this.triangles.find(x => !x.isDegenerated())!;

		const edge = triangle.getEdges().find(x => !x.isDegenerated())!;

		const basisX = new THREE.Vector3().subVectors(edge.b, edge.a).normalize();

		const basisZ = this.plane.normal;

		const basisY = new THREE.Vector3().crossVectors(basisZ, basisX);

		const origin = this.plane.coplanarPoint();

		this.coordinateSystem = new THREE.Matrix4().makeBasis(basisX, basisY, basisZ).setPosition(origin);

		return this.coordinateSystem;
	}

	isPanelWallFace(panelWallFaceNormal: THREE.Vector3, panelWallFaceOrigin: THREE.Vector3) {
		return this.isCollinearToPlane(panelWallFaceNormal) && this.isPointOnPlane(panelWallFaceOrigin);
	}

	isPointOnPlane(point: THREE.Vector3) {
		return isAlmostEqualToZero(this.getSignedDistanceTo(point), 1e-3)
	}

	isPointInside(point: THREE.Vector3) {
		if (!this.isPointOnPlane(point))
			return false;

		const localPoint = new THREE.Vector3().copy(point).applyMatrix4(new THREE.Matrix4().getInverse(this.getCoordinateSystem()));

		const flatTriangles = this.getFlatTriangles();

		return !!flatTriangles.find(x => isPointInsideTriangle(localPoint, x));
	}

	isCollinearToPlane(planeNormal: THREE.Vector3) {
		return isAlmostEqualToZero(planeNormal.distanceToSquared(this.plane.normal));
	}

	getSignedDistanceTo(point: THREE.Vector3) {
		return this.plane.normal.dot(new THREE.Vector3().subVectors(point, this.plane.coplanarPoint()));
	}

	private getFlatTriangles() {
		if (this.flatTriangles.length === this.triangles.length)
			return this.flatTriangles;

		const matrix = new THREE.Matrix4().getInverse(this.getCoordinateSystem());
		const identity = new THREE.Matrix4();

		for (const triangle of this.triangles) {
			const a = new THREE.Vector3().copy(triangle.a).applyMatrix4(matrix);
			const b = new THREE.Vector3().copy(triangle.b).applyMatrix4(matrix);
			const c = new THREE.Vector3().copy(triangle.c).applyMatrix4(matrix);

			const flatTriangle = new Triangle(a, b, c, triangle.ia, triangle.ib, triangle.ic, identity);

			this.flatTriangles.push(flatTriangle);
		}

		return this.flatTriangles;
	}

	private getBoundaryEdges(): TriangleEdge[] {
		type CountedEdge = {
			edge: TriangleEdge,
			count: number;
		}

		const edges = Array.from(this.triangles
			.flatMap(x => x.getEdges())
			.reduce((acc, elem) => {
				const edgeKey = elem.getKey();

				const countedEdge = acc.get(edgeKey);

				acc.set(edgeKey, { edge: elem, count: countedEdge ? countedEdge.count + 1 : 1 });

				return acc;
			}, new Map<string, CountedEdge>())
			.values())
			.filter(x => x.count === 1)
			.map(x => x.edge);

		return edges;
	}
}

export type ModelElementFaceSource = {
	fragmentId: number;
	svfId: number;
	hash: string;
	vbstride: number;
	importance?: number;
	instanceCount?: number;
}