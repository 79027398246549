import React, { useEffect, useRef, useState } from "react"
import Input from "@weave-design/input";
import NumericInput from "@weave-design/numeric-input";
import Label from "@weave-design/label";
import "./inputs.css"

type Props = {
    title: string;
    value: number;
    disabled?: boolean;
    onChange: (newValue: number) => void;
}

export const NumericPropertyEditor = ({ title, value, disabled, onChange }: Props) => {
    const [editorValue, setEditorValue] = useState(value);
    const editorValueRef = useRef<number>(value);
    const parentDivRef = useRef<HTMLDivElement>(null);
    const idInputRef = useRef<HTMLInputElement>();

    useEffect(() => {
        updateValue(value);
    }, [value]);

    useEffect(() => {
        const onKeyPressed = (event: KeyboardEvent) => {
            if (event.key !== "Enter")
                return;

            const inputElements = (parentDivRef.current!.parentElement?.querySelectorAll("input:not([disabled])") || []) as HTMLInputElement[];

            const currentInputIndex = Array.from(inputElements).indexOf(event.target as HTMLInputElement);

            const nextIndex = currentInputIndex < inputElements.length - 1 ? currentInputIndex + 1 : 0;

            inputElements[nextIndex].focus();
        }

        parentDivRef.current?.addEventListener("keypress", onKeyPressed);

        return () => parentDivRef.current?.removeEventListener("keypress", onKeyPressed);
    }, []);

    const updateValue = (newValue: number) => {
        editorValueRef.current = newValue;
        setEditorValue(newValue);
    }

    const saveValue = () => {
        if (editorValueRef.current === value)
            return;

        onChange(editorValueRef.current);
    }

    return <div className="property-input-container" ref={parentDivRef}>
        <div className="property-input-title-container">
            <Label>{title}</Label>
        </div>
        <div className="property-input-value-conainer">
            <div style={{ width: "100%" }}>
                <NumericInput
                    inputRef={(x: any) => idInputRef.current = x}
                    value={editorValue}
                    disabled={!!disabled}
                    onChange={(value: string) => updateValue(parseFloat(value))}
                    onBlur={() => saveValue()} />
            </div>
        </div>
    </div>
}