import React from "react";
import Modal from '@weave-design/modal';
import { ProcessingIndicator } from "./processingIndicator";

type Props = {
    isVisible: boolean;
    title: string;
    message: string | null;
}

export const ProcessingPane = ({ isVisible, title, message }: Props) => {
    return <div className="dialog">
        <Modal
            open={isVisible} title={title} stylesheet={modalStyles}>
            <ProcessingIndicator message={message || ""} />
        </Modal>
    </div>
}

const modalStyles = (styles: any) => {
    return {
        ...styles, modal: {
            ...styles.modal,
            window: { // by design
                ...styles.modal.window,
                width: "400px",
                height: "200px",
                top: "calc(50% - 100px)"
            },
            bodyContent: {
                ...styles.modal.bodyContent,
                overflow: "hidden" // no scrollbar
            }
        }
    }
}