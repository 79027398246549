import { CustomComponentViewerCameraParameters, ICustomComponentViewerNavigator } from "./customComponentViewerNavigator";
import { CustomComponentFit } from "./customComponentFit";
import { ICustomLibraryViewerNavigationEventPayload } from "../../eventBus/customLibraryNavigationEventPayload";
import eventBus, { IApplicationEvent } from "../../eventBus/eventDispatcher";

export class CustomComponentPlanViewerNavigator implements ICustomComponentViewerNavigator {
    private readonly initialPosition = new THREE.Vector3();
    private readonly currentPosition = new THREE.Vector3();

    constructor(private readonly viewer: Autodesk.Viewing.GuiViewer3D) {
        this.onCameraChanged = this.onCameraChanged.bind(this);
        this.onConnectedViewerNavigationChanged = this.onConnectedViewerNavigationChanged.bind(this);
    }

    initialize(): void {
        const { position, up } = this.computeCameraParameters();

        this.viewer.navigation.setPosition(position);
        this.viewer.navigation.setCameraUpVector(up);

        this.initialPosition.copy(this.viewer.navigation.getPosition());
        this.currentPosition.copy(this.initialPosition);

        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

        eventBus.addEventListener("Dextall.CustomComponentLibrary.Viewers.Navigation", this.onConnectedViewerNavigationChanged);
    }

    getPlaneNormal(): THREE.Vector3 {
        return this.getWorldUp();
    }

    getPlaneX(): THREE.Vector3 {
        return this.getWorldRight();
    }

    getPlaneY(): THREE.Vector3 {
        return this.getWorldFront();
    }

    dispose(): void {
        this.viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, this.onCameraChanged);

        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Viewers.Navigation", this.onConnectedViewerNavigationChanged);
    }

    private computeCameraParameters(): CustomComponentViewerCameraParameters {
        const worldUp = this.getWorldUp();
        const worldFront = this.getWorldFront();

        const fitter = new CustomComponentFit(this.viewer);

        const length = Math.abs(worldUp.dot(fitter.computeDistancesToFit()));

        return { position: worldUp.clone().multiplyScalar(length), up: worldFront };
    }

    private onCameraChanged() {
        const currentPosition = this.viewer.navigation.getPosition().clone();

        if (currentPosition.equals(this.currentPosition))
            return;

        const targetScale = this.getWorldUp().dot(currentPosition) / this.getWorldUp().dot(this.initialPosition);

        const worldRight = this.getWorldRight();
        const worldFront = this.getWorldFront();

        const frontOffset = worldRight.dot(currentPosition);
        const sideOffset = worldFront.dot(currentPosition);

        this.currentPosition.copy(currentPosition);

        eventBus.dispatchEvent({
            type: "Dextall.CustomComponentLibrary.Viewers.Navigation",
            payload: {
                targetViewer: "front",
                scale: targetScale,
                x: frontOffset,
                y: 0
            }
        });

        eventBus.dispatchEvent({
            type: "Dextall.CustomComponentLibrary.Viewers.Navigation",
            payload: {
                targetViewer: "side",
                scale: targetScale,
                x: 0,
                y: sideOffset
            }
        });
    }

    private onConnectedViewerNavigationChanged(event: IApplicationEvent<ICustomLibraryViewerNavigationEventPayload>) {
        const { targetViewer, scale, x, y } = event.payload;

        if (targetViewer !== "plan")
            return;

        const right = this.getWorldRight().clone().multiplyScalar(x);
        const up = this.getWorldFront().clone().multiplyScalar(y);

        const position = this.getWorldUp().clone().multiplyScalar(this.initialPosition.length() * scale)
            .add(right).add(up);

        const target = right.add(up);

        this.currentPosition.copy(position);

        this.viewer.navigation.setView(position, target);
    }

    private getWorldUp(): THREE.Vector3 {
        return this.viewer.autocam.getWorldUpVector();
    }

    private getWorldFront(): THREE.Vector3 {
        return this.viewer.autocam.getWorldFrontVector();
    }

    private getWorldRight(): THREE.Vector3 {
        return this.viewer.autocam.getWorldRightVector();
    }
}