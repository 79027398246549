import { ICoordinateSystem } from "../../../responses/coordinateSystem";
import { ICustomPanelSource } from "../../../responses/customPanelSource";
import { IPanelSource, IPanelSourceOpening } from "../../../responses/panelSource";
import { SystemSettings } from "../../../responses/systemSettings";
import { IWallFace } from "../../../responses/wallFace";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { CladdingCellExtrusionGeometryFactory } from "../geometry/claddingCellExtrusionGeometryFactory";
import { ModelBuilderObjectIds } from "../ids/modelBuilderObjectIds";
import { CustomPanel } from "./customPanel";
import { Panel } from "./panel";
import { WallFacesCollection } from "./wallFacesCollection";

export class PanelsFactory {
    private readonly offset: THREE.Vector3;
    private readonly boundaryLoopsFactory = new BoundaryLoopsFactory();
    private readonly claddingExtrusionFactory = new CladdingCellExtrusionGeometryFactory();
    private readonly glassMaterial = new THREE.MeshBasicMaterial({ color: 0x3B83BD, side: 2, opacity: 0.101961, transparent: true });
    private readonly panelBoundaryPlaneMaterial = new THREE.MeshBasicMaterial({ color: "#ffffff", opacity: 0, transparent: true });

    constructor(viewer: Autodesk.Viewing.GuiViewer3D,
        private readonly wallFacesCollection: WallFacesCollection,
        private readonly systemSetting: SystemSettings,
        private readonly objectIds: ModelBuilderObjectIds) {
        this.offset = new THREE.Vector3().copy(viewer.model.getGlobalOffset()).negate();
    }

    create(panelSource: IPanelSource): Panel {
        const wallFace = this.wallFacesCollection.findById(panelSource.wallFaceId);

        if (!wallFace)
            throw Error("Can't get wall face");

        const panelBoundaryLoop = this.createPanelBoundaryLoop(panelSource.box, wallFace.transform, panelSource.openings);

        const panelDbId = this.objectIds.getNewId();

        return new Panel(panelSource, panelBoundaryLoop, this.systemSetting, panelDbId, this.claddingExtrusionFactory,
            this.boundaryLoopsFactory, this.glassMaterial, this.panelBoundaryPlaneMaterial);
    }

    createCustomPanel(customPanelSource: ICustomPanelSource): CustomPanel {
        const wallFace = this.wallFacesCollection.findById(customPanelSource.wallFaceId);

        if (!wallFace)
            throw Error("Can't get wall face");

        const panelBoundaryLoop = this.createCustomPanelBoundaryLoop(customPanelSource, wallFace.transform);

        const panelDbId = this.objectIds.getNewId();

        return new CustomPanel(customPanelSource, panelBoundaryLoop, panelDbId, this.claddingExtrusionFactory);
    }

    private createPanelBoundaryLoop(box: THREE.Box3, transform: ICoordinateSystem, openings: IPanelSourceOpening[]): BoundaryLoop {
        const panelBoundaryLoop = this.boundaryLoopsFactory.createLoopFromFlatBox(box, transform, this.offset);

        for (const opening of openings) {
            const openingLoop = this.boundaryLoopsFactory.createLoopFromFlatBox(opening.box, transform, this.offset);

            panelBoundaryLoop.addInnerLoop(openingLoop);
        }

        return panelBoundaryLoop;
    }

    private createCustomPanelBoundaryLoop(customPanelSource: ICustomPanelSource, transform: ICoordinateSystem): BoundaryLoop {
        const box = new THREE.Box3(
            new THREE.Vector3(customPanelSource.offsetX, customPanelSource.offsetY, 0),
            new THREE.Vector3(customPanelSource.offsetX + customPanelSource.length, customPanelSource.offsetY + customPanelSource.height, 0)
        );

        return this.boundaryLoopsFactory.createLoopFromFlatBox(box, transform, this.offset);
    }
}