const draggingStartedEvent = "dragging-started";
const draggingCompletedEvent = "dragging-completed";

export class GizmoBase {
    private readonly eventDispatcher: Autodesk.Viewing.EventDispatcher;
    private isDragging: boolean = false;

    constructor(transformControl: THREE.TransformControls, mesh?: THREE.Mesh) {
        this.eventDispatcher = new Autodesk.Viewing.EventDispatcher();
        this.transformControl = transformControl;

        if (!mesh) {
            const material = new THREE.MeshBasicMaterial({ color: 0xffffff });

            this.mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(0.0001, 0.0001), material);
        } else
            this.mesh = mesh;
    }

    public transformControl: THREE.TransformControls;

    protected mesh: THREE.Mesh;

    update() {
        this.transformControl.update();
    }

    apply(matrix: THREE.Matrix4) {
        const origin = new THREE.Vector3().setFromMatrixPosition(matrix);

        this.mesh.position.copy(origin);

        this.update();
    }

    isDraggingGizmo(): boolean {
        return this.isDragging;
    }

    getPosition(): THREE.Vector3 {
        return this.mesh.position;
    }

    handleButtonDown(event: any) {
        this.isDragging = this.onPointerHover(event);

        if (this.isDragging)
            this.eventDispatcher.dispatchEvent({ type: draggingStartedEvent });

        return this.isDragging && this.onPointerDown(event);
    }

    handleButtonUp(event: any) {
        if (!this.isDragging)
            return false;

        this.isDragging = false;

        this.eventDispatcher.dispatchEvent({ type: draggingCompletedEvent });

        return this.onPointerUp(event);
    }

    handleMouseMove(event: any) {
        if (this.isDragging)
            return this.onPointerMove(event);

        return this.onPointerHover(event);
    }

    onPointerMove(event: any) {
        return !!this.transformControl.onPointerMove(event);
    }

    onPointerHover(event: any) {
        return !!this.transformControl.onPointerHover(event);
    }

    onPointerDown(event: any) {
        return !!this.transformControl.onPointerDown(event);
    }

    onPointerUp(event: any) {
        return !!this.transformControl.onPointerUp(event);
    }

    addDraggingStartedEventListener(listener: () => void) {
        this.eventDispatcher.addEventListener(draggingStartedEvent, listener);
    }

    addDraggingCompletedEventListener(listener: () => void) {
        this.eventDispatcher.addEventListener(draggingCompletedEvent, listener);
    }

    removeDraggingStartedEventListener(listener: () => void) {
        this.eventDispatcher.removeEventListener(draggingStartedEvent, listener)
    }

    removeDraggingCompletedEventListener(listener: () => void) {
        this.eventDispatcher.removeEventListener(draggingCompletedEvent, listener);
    }
}