import { ModelElementFace, ModelElementFaceSource } from "./modelElementFace";
import { Triangle } from "./triangle";
import { WallGeometryInstancesCountCalculator } from "./wallGeometryInstancesCountCalculator";

export class ModelElementFacesFactory {
    private readonly instancesCountCalculator: WallGeometryInstancesCountCalculator;

    constructor(model: Autodesk.Viewing.Model) {
        this.instancesCountCalculator = new WallGeometryInstancesCountCalculator(model);
    }

    createFaces(geometry: THREE.BufferGeometry, matrixWorld: THREE.Matrix4, fragmentId: number) {
        const triangles = this.getTriangles(geometry, matrixWorld);

        return this.combineTrianglesIntoModelFaces(triangles, {
            fragmentId,
            svfId: geometry.svfid!,
            vbstride: geometry.vbstride,
            importance: geometry.importance,
            instanceCount: this.instancesCountCalculator.getInstancesCount(fragmentId),
            hash: geometry.hash!
        });
    }

    private combineTrianglesIntoModelFaces(triangles: Triangle[], source?: ModelElementFaceSource) {
        return triangles.reduce((acc: ModelElementFace[], elem) => {
            if (elem.isDegenerated())
                return acc;

            const face = acc.find(x => x.isOnPlane(elem));

            if (face)
                face.add(elem);
            else
                acc.push(new ModelElementFace(elem, source));

            return acc;
        }, []);
    }

    private getTriangles(geometry: THREE.BufferGeometry, matrixWorld: THREE.Matrix4): Triangle[] {
        const triangles: Triangle[] = [];

        Autodesk.Viewing.Private.VertexEnumerator.enumMeshTriangles(geometry, (a, b, c, ia, ib, ic) => {
            const triangle = new Triangle(a, b, c, ia, ib, ic, matrixWorld);

            triangles.push(triangle);
        });

        return triangles;
    }
}