import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { IHookSource } from "../../../responses/hookSource";
import { IPanelSource } from "../../../responses/panelSource";
import { IPanelType } from "../../../responses/panelType";
import { Corner } from "./corner";
import { Panel } from "./panel";

type PanelsType<T> =
    T extends IPanelSource ? Panel :
    T extends ICornerPanelSource ? Corner :
    never;

export class PanelType<T extends IPanelSource | ICornerPanelSource> {
    private readonly _panels = new Map<string, PanelsType<T>>();

    constructor(panelTypeSource: IPanelType) {
        this.id = panelTypeSource.id;
        this.hooks = panelTypeSource.hooks;
        this.name = panelTypeSource.name;
    }

    readonly id: string;

    readonly hooks: IHookSource[];

    name: string;

    get panels(): PanelsType<T>[] {
        return Array.from(this._panels.values());
    }

    addPanel(panel: PanelsType<T>) {
        this._panels.set(panel.id, panel);
    }

    addHook(hook: IHookSource) {
        this.hooks.push(hook);
    }

    updateHook(index: number, hook: IHookSource) {
        const panelHook = this.hooks[index];

        panelHook.x = hook.x;
        panelHook.y = hook.y;
        panelHook.hookType = hook.hookType;
        panelHook.profileSide = hook.profileSide;
    }

    removePanel(panel: PanelsType<T>) {
        this._panels.delete(panel.id);
    }

    removeHook(index: number) {
        this.hooks.splice(index, 1);
    }
}