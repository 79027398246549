import { ICoordinateSystem } from "../../../responses/coordinateSystem";
import { ICornerPanelSource } from "../../../responses/cornerPanelSource";
import { ICustomCornerSource } from "../../../responses/customCornerSource";
import { IModelCorner } from "../../../responses/modelCorner";
import { Corner } from "./corner";
import { CustomCorner } from "./customCorner";
import { Panel } from "./panel";
import { WallFacesCollection } from "./wallFacesCollection";

export const getWallFaceTransform = (transform: ICoordinateSystem, globalOffset: THREE.Vector3): THREE.Matrix4 => {
    return new THREE.Matrix4()
        .makeBasis(
            new THREE.Vector3().copy(transform.basisX),
            new THREE.Vector3().copy(transform.basisY),
            new THREE.Vector3().copy(transform.basisZ))
        .setPosition(new THREE.Vector3().copy(transform.origin).add(globalOffset));
}

export class HooksCoordinateSystems {
    private readonly offset: THREE.Vector3;
    private readonly wallFaceTransforms = new Map<string, THREE.Matrix4>();
    private readonly cornersById = new Map<string, IModelCorner>();

    constructor(wallFacesCollection: WallFacesCollection, wallCorners: IModelCorner[], viewer: Autodesk.Viewing.GuiViewer3D) {
        this.offset = new THREE.Vector3().copy(viewer.model.getGlobalOffset()).negate();

        for (const wallFace of wallFacesCollection.asArray())
            this.wallFaceTransforms.set(wallFace.id, getWallFaceTransform(wallFace.transform, this.offset));

        for (const wallCorner of wallCorners)
            this.cornersById.set(wallCorner.id, wallCorner);
    }

    getPanelWallFaceTransform(panel: Panel): THREE.Matrix4 | undefined {
        return this.wallFaceTransforms.get(panel.wallFaceId);
    }

    getCornerWingTransform(panel: Corner | ICornerPanelSource | CustomCorner | ICustomCornerSource, forLeftWing: boolean): THREE.Matrix4 | undefined {
        const wallCorner = this.cornersById.get(panel.wallCornerId);

        if (!wallCorner)
            return undefined;

        const isReversed = wallCorner.angle > 180;

        const forLeftWingTransform = isReversed ? !forLeftWing : forLeftWing;

        const cornerWing = forLeftWingTransform ? wallCorner.left : wallCorner.right;

        const wallFaceTransform = this.wallFaceTransforms.get(cornerWing.wallFaceId);

        if (!wallFaceTransform)
            throw Error("Can't get wall face transform");

        const shouldOffset = new THREE.Vector3().copy(cornerWing.direction).dot(new THREE.Vector3().setFromMatrixColumn(0, wallFaceTransform)) < 0;

        const isRegularCorner = Object.hasOwn(panel, "leftWingCladdings");

        const forLeftWingLength = isRegularCorner
            ? forLeftWingTransform
            : forLeftWing;

        const wingLength = forLeftWingLength ? panel.leftWing : panel.rightWing;

        const origin = new THREE.Vector3()
            .addVectors(wallCorner.origin, this.offset)
            .add(new THREE.Vector3(0, 0, wallCorner.heights[panel.heightIndex] + panel.offset))
            .add(new THREE.Vector3().copy(cornerWing.direction).multiplyScalar(shouldOffset ? wingLength : 0));

        return new THREE.Matrix4().copy(wallFaceTransform).setPosition(origin);
    }

    getCornerWingWallFaceId(panel: Corner, forLeftWing: boolean) {
        const wallCorner = this.cornersById.get(panel.wallCornerId);

        if (!wallCorner)
            throw new Error("Wall corner was not found");

        return forLeftWing ? wallCorner.left.wallFaceId : wallCorner.right.wallFaceId;
    }

    getCornerAlignmentWallFaceId(panel: Corner, forLeftWing: boolean) {
        const wallCorner = this.cornersById.get(panel.wallCornerId);

        if (!wallCorner)
            throw new Error("Wall corner was not found");

        const isCornerLeftWing = panel.isReversed ? !forLeftWing : forLeftWing;

        return isCornerLeftWing ? wallCorner.left.wallFaceId : wallCorner.right.wallFaceId;
    }

    getMaxX(panel: Corner, forLeftWing: boolean): number {
        const isForLeftWing = panel.isReversed ? !forLeftWing : forLeftWing;

        return isForLeftWing ? panel.leftWing : panel.rightWing;
    }
}