import { isAlmostEqualToZero } from "@dextall/shared";
import { ShapeUtils } from "../fixes/ShapeUtils.js";
import { ModelElementFace } from "./modelElementFace";
import { Triangle } from "./triangle";
import { TriangleEdge } from "./triangleEdge";

export class FaceBoundaryLoop {
    private readonly neighbours = new Map<string, boolean>();
    private readonly toLocalCoordinateMatrix: THREE.Matrix4;
    private readonly _key: string;
    private _index: number = 0;

    constructor(
        public readonly face: ModelElementFace,
        private readonly edges: TriangleEdge[]) {

        let key = "";
        for (const edge of edges)
            key = `${key + edge.ia}-`;

        this._key = key;
        this.neighbours.set(this.key, false);
        this.toLocalCoordinateMatrix = new THREE.Matrix4().getInverse(face.getCoordinateSystem());
    }

    get key(): string {
        return `${this._index}:${this._key}`;
    }

    set index(value: number) {
        this._index = value;
        this.neighbours.clear();
        this.neighbours.set(this.key, false);
    }

    isCounterClockwise(): boolean {
        return this.area() > 0;
    }

    isNeighbourLoop(otherLoop: FaceBoundaryLoop): boolean {
        const precalculatedIsNeighbour = this.neighbours.get(otherLoop.key)

        if (precalculatedIsNeighbour !== undefined)
            return precalculatedIsNeighbour;

        const otherLoopPoint = otherLoop.edges.map(x => x.a).find(x => isAlmostEqualToZero(new THREE.Vector3().copy(x).applyMatrix4(this.toLocalCoordinateMatrix).z, 1e-4));

        const isNeighbour = !!(otherLoopPoint && this.edges.map(x => x.a).find(x => isAlmostEqualToZero(x.distanceToSquared(otherLoopPoint), 1e-4)));

        this.neighbours.set(otherLoop.key, isNeighbour);
        otherLoop.neighbours.set(this.key, isNeighbour);

        return isNeighbour;
    }

    triangulate() {
        const points = this.getPoints();

        const flatLoop = this.getFlatLoop();

        const matrix = new THREE.Matrix4();

        return ShapeUtils.triangulateShape(flatLoop, []).map(x => new Triangle(points[x[0]], points[x[1]], points[x[2]], x[0], x[1], x[2], matrix));
    }

    getPointsCount() {
        return this.edges.length + 1;
    }

    private area(): number {
        const points = this.getFlatLoop();

        const n = points.length;
        let a = 0.0;

        for (let p = n - 1, q = 0; q < n; p = q++) {
            a += points[p].x * points[q].y - points[q].x * points[p].y;
        }

        return a * 0.5;
    }

    private getFlatLoop(): THREE.Vector3[] {
        const matrix = new THREE.Matrix4().getInverse(this.face.getCoordinateSystem());

        const points = this.getPoints().map(x => x.applyMatrix4(matrix));

        points.push(new THREE.Vector3().copy(points[0]));

        return points;
    }

    private getPoints() {
        const points = this.edges.map(x => new THREE.Vector3().copy(x.a));

        points.push(new THREE.Vector3().copy(points[0]));

        return points;
    }
}