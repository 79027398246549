export const mergeVertices = (geometry: THREE.BufferGeometry, tolerance = 1e-4) => {

    tolerance = Math.max(tolerance, Number.EPSILON);

    // Generate an index buffer if the geometry doesn't have one, or optimize it
    // if it's already available.
    const hashToIndex: { [hash: string]: number } = {};
    const indices = geometry.index;
    const positions = geometry.getAttribute('position');
    const vertexCount = indices ? indices.count : positions.count;

    // next value for triangle indices
    let nextIndex = 0;

    // attributes and new attribute arrays
    const attributeNames = Object.keys(geometry.attributes);
    const attrArrays: { [name: string]: any[] } = {};
    const newIndices: number[] = [];
    const getters = ['getX', 'getY', 'getZ', 'getW'];

    // initialize the arrays
    for (let i = 0, l = attributeNames.length; i < l; i++) {

        const name = attributeNames[i];

        attrArrays[name] = [];
    }

    // convert the error tolerance to an amount of decimal places to truncate to
    const decimalShift = Math.log10(1 / tolerance);
    const shiftMultiplier = Math.pow(10, decimalShift);
    for (let i = 0; i < vertexCount; i++) {

        const index = indices ? indices.getX(i) : i;

        // Generate a hash for the vertex attributes at the current index 'i'
        let hash = '';
        for (let j = 0, l = attributeNames.length; j < l; j++) {

            const name = attributeNames[j];
            const attribute = geometry.getAttribute(name);
            const itemSize = attribute.itemSize;

            for (let k = 0; k < itemSize; k++) {

                // double tilde truncates the decimal value
                // @ts-ignore
                hash += `${~ ~(attribute[getters[k]](index) * shiftMultiplier)},`;

            }

        }

        // Add another reference to the vertex if it's already
        // used by another index
        if (hash in hashToIndex) {

            newIndices.push(hashToIndex[hash]);

        } else {

            // copy data to the new index in the attribute arrays
            for (let j = 0, l = attributeNames.length; j < l; j++) {

                const name = attributeNames[j];
                const attribute = geometry.getAttribute(name);
                //const morphAttr = geometry.morphAttributes[ name ];
                const itemSize = attribute.itemSize;
                const newarray = attrArrays[name];

                for (let k = 0; k < itemSize; k++) {

                    const getterFunc = getters[k];
                    // @ts-ignore
                    newarray.push(attribute[getterFunc](index));
                }

            }

            hashToIndex[hash] = nextIndex;
            newIndices.push(nextIndex);
            nextIndex++;

        }

    }

    // Generate typed arrays from new attribute arrays and update
    // the attributeBuffers
    const result = geometry.clone();
    for (let i = 0, l = attributeNames.length; i < l; i++) {

        const name = attributeNames[i];
        const oldAttribute = geometry.getAttribute(name);

        // @ts-ignore
        const buffer = oldAttribute.array && new oldAttribute.array.constructor(attrArrays[name]);
        const attribute = new THREE.BufferAttribute(buffer, oldAttribute.itemSize);
        attribute.bytesPerItem = oldAttribute.bytesPerItem;

        result.setAttribute(name, attribute);
    }

    // @ts-ignore
    result.ib = new Uint16Array(newIndices);

    const indexAttribute = new THREE.BufferAttribute(undefined, 1);
    indexAttribute.bytesPerItem = 2;

    result.setIndex(indexAttribute);

    return result;

}

type ToModelGeometryConversionOptions = {
    svfId: number;
    vbstride: number;
    hash: string;
    importance?: number;
    instanceCount?: number;
}

export const convertToModelGeometry = (geometry: THREE.BufferGeometry, options: ToModelGeometryConversionOptions): THREE.BufferGeometry => {
    geometry.svfid = options.svfId;
    geometry.hash = options.hash;

    geometry.importance = options.importance;
    geometry.instanceCount = options.instanceCount;
    geometry.byteSize = geometry.vb!.byteLength! + geometry.ib!.byteLength!;
    geometry.numInstances = 1;

    return geometry;
}

export const createModelGeometryIndex = (geometry: THREE.BufferGeometry): THREE.BufferGeometry => {
    const attrIndexLines = new THREE.BufferAttribute(undefined, 1);
    attrIndexLines.bytesPerItem = geometry.iblines instanceof Uint32Array ? 4 : 2;

    geometry.setAttribute("indexlines", attrIndexLines);
    geometry.iblinesbuffer = undefined;

    return geometry;
}