import { roundToDecimalSigns } from "@dextall/shared";
import { libraryLengthsToleranceDecimalSigns } from "../defaults";
import { ICustomPanelType } from "../../../responses/customPanelTypes";
import { GizmoDirections } from "../custom-component-library/gizmos/customComponentInsertionPointGizmo";
import { CustomComponentInsertionPointGizmoTool } from "../custom-component-library/gizmos/customComponentInsertionPointGizmoTool";
import { CustomComponentViewerNavigatorFactory } from "../custom-component-library/navigation/customComponentViewerNavigatorFactory";
import { CustomComponentViewerType } from "../custom-component-library/navigation/customComponentViewerType";
import { ExtendedSnapper } from "../custom-component-library/snapping/extendedSnapper";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export class ForgeCustomPanelComponentEditorExtension extends Autodesk.Viewing.Extension {
    private readonly customComponentViewerType: CustomComponentViewerType;
    private boundsMaterial: THREE.MeshBasicMaterial | null = null;
    private customComponent: ICustomPanelType;
    private gizmoTool: CustomComponentInsertionPointGizmoTool | null = null;
    private centerPointMesh: THREE.Mesh | null = null;
    private boundsMesh: THREE.Mesh | null = null;
    private sectionActive: boolean = false;
    private sectionToolButton: Autodesk.Viewing.UI.Button | null = null;
    private showBoundsButton: Autodesk.Viewing.UI.Button | null = null;
    private snapper: ExtendedSnapper | null = null;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
        super(viewer, options);

        this.customComponentViewerType = options.customComponentViewerType;
        this.customComponent = { ...options.customComponent };

        this.onModelAdded = this.onModelAdded.bind(this);
        this.onToggleSection = this.onToggleSection.bind(this);
        this.onExtensionLoaded = this.onExtensionLoaded.bind(this);
        this.onCustomComponentPropertiesChanged = this.onCustomComponentPropertiesChanged.bind(this);
        this.onViewToolChanged = this.onViewToolChanged.bind(this);
        this.onGizmoPositionUpdated = this.onGizmoPositionUpdated.bind(this);
        this.onToggleBounds = this.onToggleBounds.bind(this);
    }

    load() {
        this.boundsMaterial = new THREE.MeshBasicMaterial({ color: "#757E82", opacity: 0.9 });
        this.viewer.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, this.onModelAdded, { once: true });
        this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
        this.viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        eventBus.addEventListener("Dextall.CustomComponentLibrary.Panel.UI.Changed", this.onCustomComponentPropertiesChanged);
        eventBus.addEventListener("Dextall.CustomComponentLibrary.Panel.Position.Gizmo.Changed", this.onGizmoPositionUpdated);

        this.createToolbar();

        return true;
    }

    unload() {
        this.viewer.impl.removeOverlayScene(overlayName);
        this.centerPointMesh = null;
        this.boundsMesh = null;
        this.boundsMaterial = null;
        this.snapper?.dispose();
        this.snapper = null;

        //@ts-ignore
        this.customComponent = null;

        this.sectionToolButton?.removeEventListener("click", this.onToggleSection);
        this.sectionToolButton = null;

        this.showBoundsButton?.removeEventListener("click", this.onToggleBounds);
        this.showBoundsButton = null;

        this.viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
        this.viewer.removeEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, this.onViewToolChanged);

        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Panel.UI.Changed", this.onCustomComponentPropertiesChanged);
        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Panel.Position.Gizmo.Changed", this.onGizmoPositionUpdated);

        if (this.gizmoTool) {
            this.viewer.toolController.deactivateTool(this.gizmoTool.getName());
            this.viewer.toolController.deregisterTool(this.gizmoTool);
        }

        return true;
    }

    private onModelAdded() {
        this.viewer.impl.createOverlayScene(overlayName);

        const geometry = new THREE.SphereBufferGeometry(this.getPointRadius());

        const material = new THREE.MeshBasicMaterial({ color: "#DC143C" });

        this.centerPointMesh = new THREE.Mesh(geometry, material);

        this.viewer.impl.addOverlay(overlayName, this.centerPointMesh);

        this.extendMeasureSnapper();

        if (this.viewer.getExtension("Autodesk.Section"))
            this.createGizmo();

        if (this.isValidBounds())
            this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
    }

    private onCustomComponentPropertiesChanged(event: IApplicationEvent<ICustomPanelType>) {
        this.customComponent = { ...event.payload };

        this.updateInsertPointPosition();

        const newBoundsValid = this.isValidBounds();

        if (newBoundsValid) {
            if (this.isBoundsActive())
                this.activateBounds();
            else
                this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        } else {
            if (this.isBoundsActive())
                this.deactivateBounds();

            this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.DISABLED);
        }
    }

    private getCustomModelInsertPoint() {
        const modelUnits = this.getModelUnits();

        const scale = Autodesk.Viewing.Private.convertUnits("ft", modelUnits, 1, 1);

        return new THREE.Vector3().copy(this.customComponent.offset).multiplyScalar(scale).add(this.getViewerOffset());
    }

    private getCustomComponentOffset(modelPoint: THREE.Vector3) {
        const modelUnits = this.getModelUnits();

        const scale = Autodesk.Viewing.Private.convertUnits(modelUnits, "ft", 1, 1);

        const offset = new THREE.Vector3().copy(modelPoint).sub(this.getViewerOffset()).multiplyScalar(scale);

        offset.x = roundToDecimalSigns(offset.x, libraryLengthsToleranceDecimalSigns);
        offset.y = roundToDecimalSigns(offset.y, libraryLengthsToleranceDecimalSigns);
        offset.z = roundToDecimalSigns(offset.z, libraryLengthsToleranceDecimalSigns);

        return offset;
    }

    private getModelUnits() {
        return this.viewer.model.getDisplayUnit();
    }

    private getViewerOffset() {
        return new THREE.Vector3().copy(this.viewer.model.getGlobalOffset()).negate();
    }

    private getPointRadius() {
        const modelUnits = this.getModelUnits();

        return Autodesk.Viewing.Private.convertUnits("mm", modelUnits, 1, 10);
    }

    private createToolbar() {
        const controlGroup = this.viewer.getToolbar(false)?.getControl(Autodesk.Viewing.TOOLBAR.MODELTOOLSID) as Autodesk.Viewing.UI.ControlGroup;

        if (!controlGroup)
            return;

        if (this.customComponentViewerType !== "3D") {
            this.sectionToolButton = new Autodesk.Viewing.UI.Button("dextall-custom-component-viewer-section-button");
            this.sectionToolButton.setToolTip("Section analysis");
            this.sectionToolButton.setIcon("adsk-icon-section-analysis");
            this.sectionToolButton.addEventListener("click", this.onToggleSection);

            if (!this.getSectionTool())
                this.sectionToolButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);

            controlGroup.addControl(this.sectionToolButton);
        }

        this.showBoundsButton = new Autodesk.Viewing.UI.Button("dextall-custom-component-viewer-toggle-bounds-button");
        this.showBoundsButton.setToolTip("Show bounds");
        this.showBoundsButton.setIcon("adsk-icon-box");
        this.showBoundsButton.addEventListener("click", this.onToggleBounds);
        this.showBoundsButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);

        controlGroup.addControl(this.showBoundsButton);
    }

    private onToggleSection() {
        if (!this.sectionActive) {
            this.showSection();
            this.sectionToolButton?.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
        } else {
            this.clearSection();
            this.sectionToolButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        }

        this.sectionActive = !this.sectionActive;
    }

    private onViewToolChanged(event: { toolName: string; active: boolean }) {
        const { toolName, active } = event;

        if (toolName !== "measure" || this.gizmoTool === null)
            return;

        if (active)
            this.viewer.toolController.deactivateTool(this.gizmoTool.getName());
        else {
            this.viewer.toolController.activateTool(this.gizmoTool.getName());
            this.gizmoTool.updatePosition(this.getCustomModelInsertPoint(), true);
        }
    }

    private updateInsertPointPosition() {
        if (!this.centerPointMesh)
            return;

        const position = this.getCustomModelInsertPoint();

        this.snapper?.setPoints([position]);

        this.gizmoTool?.updatePosition(position);

        if (this.sectionActive)
            this.showSection();

        this.resetMeasurements();
    }

    private resetMeasurements() {
        type MeasureExtension = Autodesk.Viewing.Extension & {
            deleteMeasurements(): void;

            measureTool: {
                setSessionMeasurements(measurements: []): void;
            }
        }

        const measureExtension = this.viewer.getExtension("Autodesk.Measure") as MeasureExtension;

        if (measureExtension.isActive(""))
            measureExtension.deleteMeasurements();
        else
            measureExtension.measureTool.setSessionMeasurements([]);
    }

    private showSection() {
        if (this.customComponentViewerType === "3D")
            return;

        const sectionTool = this.getSectionTool();

        const position = this.getCustomModelInsertPoint();

        const normal = CustomComponentViewerNavigatorFactory.createNavigator(this.viewer, this.customComponentViewerType).getPlaneNormal();

        sectionTool?.setSectionPlane(normal, position, false);

        sectionTool?.deactivate(true);
    }

    private clearSection() {
        const sectionTool = this.getSectionTool();

        sectionTool?.setSectionFromPlane(null);
    }

    private getSectionTool(): Autodesk.Viewing.Extensions.Section.SectionExtension | undefined {
        return this.viewer.getExtension("Autodesk.Section") as Autodesk.Viewing.Extensions.Section.SectionExtension | undefined;
    }

    private onExtensionLoaded(event: { extensionId: string }) {
        if (event.extensionId === "Autodesk.Section") {
            this.createGizmo();
            this.sectionToolButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
        }

        if (event.extensionId === "Autodesk.Measure")
            this.extendMeasureSnapper();
    }

    private extendMeasureSnapper(): boolean {
        if (!this.viewer.model)
            return false;

        type MeasureExtension = Autodesk.Viewing.Extension & { snapper: Autodesk.Viewing.Extensions.Snapping.Snapper };

        const measureExtension = this.viewer.getExtension("Autodesk.Measure") as MeasureExtension;

        if (!measureExtension)
            return false;

        this.snapper = new ExtendedSnapper(measureExtension.snapper, this.viewer, this.getPointRadius());

        this.snapper.setPoints([this.getCustomModelInsertPoint()]);

        return true;
    }

    private onGizmoPositionUpdated(event: IApplicationEvent<THREE.Vector3>) {
        const modelPoint = event.payload;

        this.customComponent.offset = this.getCustomComponentOffset(modelPoint);

        this.updateInsertPointPosition();

        if (this.isBoundsActive())
            this.activateBounds();

        if (this.customComponentViewerType === "3D") // dispatch ony once
            eventBus.dispatchEvent({
                type: "Dextall.CustomComponentLibrary.Panel.Editor.Changed",
                payload: this.customComponent
            });
    }

    private createGizmo() {
        if (this.gizmoTool || !this.centerPointMesh)
            return;

        const gizmoDirections = this.createGizmoDirections();

        const position = this.getCustomModelInsertPoint();

        this.gizmoTool = new CustomComponentInsertionPointGizmoTool(position, gizmoDirections, this.centerPointMesh);

        this.viewer.toolController.registerTool(this.gizmoTool);
        this.viewer.toolController.activateTool(this.gizmoTool.getName());
    }

    private createGizmoDirections(): GizmoDirections {
        const navigator = this.customComponentViewerType !== "3D"
            ? CustomComponentViewerNavigatorFactory.createNavigator(this.viewer, this.customComponentViewerType)
            : null;

        const basisX: THREE.Vector3 = navigator?.getPlaneX() || this.viewer.autocam.getWorldRightVector();
        const basisY = navigator?.getPlaneY() || new THREE.Vector3().copy(this.viewer.autocam.getWorldUpVector()).negate();

        const directions: GizmoDirections = [basisX, basisY];

        if (this.customComponentViewerType === "3D")
            directions.push(new THREE.Vector3().copy(this.viewer.autocam.getWorldFrontVector()).negate());

        return directions;
    }

    private onToggleBounds() {
        if (this.isBoundsActive())
            this.deactivateBounds();
        else if (this.isValidBounds())
            this.activateBounds();
    }

    private isValidBounds() {
        return this.customComponent.length > 0 && this.customComponent.height > 0;
    }

    private isBoundsActive() {
        return !!this.boundsMesh;
    }

    private activateBounds() {
        this.clearBounds();

        const geometry = this.createBoundsBox();

        this.boundsMesh = new THREE.Mesh(geometry, this.boundsMaterial!);

        const { depth, width, height } = this.getBoundsBoxDimensions();

        const position = new THREE.Vector3().addVectors(this.getCustomModelInsertPoint(), new THREE.Vector3(0.5 * width, 0.5 * height, -0.5 * depth));

        this.boundsMesh.position.copy(position);

        this.viewer.impl.addOverlay(overlayName, this.boundsMesh);

        this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
    }

    private deactivateBounds() {
        this.clearBounds();
        this.showBoundsButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
    }

    private clearBounds() {
        if (!this.boundsMesh)
            return;

        this.viewer.impl.removeOverlay(overlayName, this.boundsMesh);

        this.boundsMesh = null;
    }

    private createBoundsBox(): THREE.BoxBufferGeometry {
        const { width, height, depth } = this.getBoundsBoxDimensions();

        return new THREE.BoxBufferGeometry(width, height, depth);
    }

    private getBoundsBoxDimensions(): BoxDimensions {
        const units = this.getModelUnits();
        const width = Autodesk.Viewing.Private.convertUnits("ft", units, 1, this.customComponent.length);
        const height = Autodesk.Viewing.Private.convertUnits("ft", units, 1, this.customComponent.height);
        const depth = Autodesk.Viewing.Private.convertUnits("mm", units, 1, boundsDepthMm);

        return { width, height, depth };
    }
}

type BoxDimensions = {
    width: number;
    height: number;
    depth: number;
}

const overlayName = "dextall-custom-component-editor-overlay";
const boundsDepthMm = 254;