import { IWallFace } from "../../../responses/wallFace";
import { ExternalIdsMapping, ViewerModelUtils } from "../viewer-utils/viewerModelUtils";

export class WallFacesCollection {
    private readonly wallFacesById = new Map<string, IWallFace>();
    private readonly wallFacesByUniqueId = new Map<string, IWallFace[]>();
    private readonly wallFacesByDbId = new Map<number, IWallFace[]>();
    private readonly dbIdsByWallFaceId = new Map<string, number>();

    private constructor(private readonly wallFaces: IWallFace[], externalIdsMapping: ExternalIdsMapping) {
        for (const wallFace of wallFaces) {
            this.wallFacesById.set(wallFace.id, wallFace);

            const facesByUniqueId = this.wallFacesByUniqueId.get(wallFace.wallUniqueId) || [];
            facesByUniqueId.push(wallFace);
            this.wallFacesByUniqueId.set(wallFace.wallUniqueId, facesByUniqueId);

            const dbId = externalIdsMapping[wallFace.wallUniqueId];

            if (dbId !== undefined) {
                const facesByDbId = this.wallFacesByDbId.get(dbId) || [];
                facesByDbId.push(wallFace);
                this.wallFacesByDbId.set(dbId, facesByDbId);
                this.dbIdsByWallFaceId.set(wallFace.id, dbId);
            }
        }
    }

    static async create(wallFaces: IWallFace[], model: Autodesk.Viewing.Model) {
        const externalIdsMapping = await ViewerModelUtils.getExternalIdsMapping(model);

        return new WallFacesCollection(wallFaces, externalIdsMapping);
    }

    static createEmpty() {
        return new WallFacesCollection([], {});
    }

    asArray(): IWallFace[] {
        return this.wallFaces;
    }

    findById(id: string): IWallFace | undefined {
        return this.wallFacesById.get(id);
    }

    findByUniqueId(uniqueId: string): IWallFace[] {
        return this.wallFacesByUniqueId.get(uniqueId) || [];
    }

    findByDbId(dbId: number): IWallFace[] {
        return this.wallFacesByDbId.get(dbId) || [];
    }

    findWallFaceDbId(id: string): number | undefined {
        return this.dbIdsByWallFaceId.get(id);
    }

    findWallFaceDbIdByWallUniqueId(uniqueId: string): number[] {
        return this.findByUniqueId(uniqueId)
            .map(x => this.findWallFaceDbId(x.id))
            .filter((x): x is number => x !== undefined);
    }

    getAllDbIds(): number[] {
        return Array.from(this.wallFacesByDbId.keys());
    }

    dispose() {
        this.wallFacesById.clear();
        this.wallFacesByUniqueId.clear();
        this.wallFacesByDbId.clear();
        this.dbIdsByWallFaceId.clear();
    }
}