import React, { useEffect, useRef } from "react"
import { CustomComponentViewerType } from "../forge/custom-component-library/navigation/customComponentViewerType";
import { registerExtensions } from "../forge/extensions/forgeCustomComponentsExtensionsRegistration";
import { CustomPanelType, CustomPanelTypeShape } from "../../responses/customPanelTypes";
import "./customComponentForgeViewer.css";

type Props = {
    customComponent: CustomPanelType;
}

export const CustomComponentForgeViewer = ({ customComponent }: Props) => {
    const planViewerDiv = useRef<HTMLDivElement>(null);
    const frontViewerDiv = useRef<HTMLDivElement>(null);
    const sideViewerDiv = useRef<HTMLDivElement>(null);
    const threeDViewerDiv = useRef<HTMLDivElement>(null);
    const viewers = useRef<[Autodesk.Viewing.Viewer3D, Autodesk.Viewing.Viewer3D, Autodesk.Viewing.Viewer3D, Autodesk.Viewing.Viewer3D]>();

    useEffect(() => {
        if (planViewerDiv.current === null || frontViewerDiv.current === null || sideViewerDiv.current === null || threeDViewerDiv.current === null)
            return;

        // prevent an error if the measurement mode is active
        const shutdownViewer = (viewer: Autodesk.Viewing.GuiViewer3D) => {
            const measureToolName = "measure";

            type MeasureExtension = Autodesk.Viewing.Extension & {
                exitMeasurementMode(): void;
            }

            if (viewer.toolController.isToolActivated(measureToolName)) {
                const measureExtension = viewer.getExtension("Autodesk.Measure") as MeasureExtension;

                measureExtension.exitMeasurementMode();
            }

            viewer.finish();
        }

        if (viewers.current)
            for (const viewer of viewers.current)
                shutdownViewer(viewer as Autodesk.Viewing.GuiViewer3D);

        const factory = new Autodesk.Viewing.MultiViewerFactory();

        type ViewerConfig = Autodesk.Viewing.Viewer3DConfig & {
            customComponentViewerType: CustomComponentViewerType;
            customComponent: CustomPanelType;
        }

        const initViewer = (container: HTMLDivElement, config: ViewerConfig): Autodesk.Viewing.Viewer3D => {
            const viewer = factory.createViewer(container, config, Autodesk.Viewing.Viewer3D);

            viewer.start();

            return viewer;
        }

        registerExtensions();

        const extensions = [
            "Autodesk.Measure",
            "Autodesk.FullScreen",
            "Autodesk.Section",
            "Dextall.ForgeCustomComponentNavigationExtension"
        ];

        if (customComponent.shapeType === CustomPanelTypeShape.Panel)
            extensions.push("Dextall.ForgeCustomPanelComponentEditorExtension");

        if (customComponent.shapeType === CustomPanelTypeShape.Corner)
            extensions.push("Dextall.ForgeCustomCornerComponentEditorExtension");

        if (customComponent.shapeType === CustomPanelTypeShape.ZShape)
            extensions.push("Dextall.ForgeCustomZShapedComponentEditorExtension");

        const basicConfig: Autodesk.Viewing.Viewer3DConfig = { extensions, theme: "light-theme", localStoragePrefix: "DextalComponentLibraryEditor" }

        viewers.current = [
            initViewer(planViewerDiv.current, { ...basicConfig, customComponentViewerType: "plan", customComponent }),
            initViewer(frontViewerDiv.current, { ...basicConfig, customComponentViewerType: "front", customComponent }),
            initViewer(sideViewerDiv.current, { ...basicConfig, customComponentViewerType: "side", customComponent }),
            initViewer(threeDViewerDiv.current, { ...basicConfig, customComponentViewerType: "3D", customComponent, extensions: [...extensions, "Autodesk.ViewCubeUi"] })
        ];

        Autodesk.Viewing.Document.load(customComponent.bubbleUrl!, async (doc) => {
            const viewable = doc.getRoot().getDefaultGeometry();

            for (const viewer of viewers.current!)
                await viewer.loadDocumentNode(doc, viewable);
        }, () => { })
    }, [customComponent]);

    return <>
        <div className="custom-component-plan-viewer-container" ref={planViewerDiv}></div>
        <div className="custom-component-front-viewer-container" ref={frontViewerDiv}></div>
        <div className="custom-component-side-viewer-container" ref={sideViewerDiv}></div>
        <div className="custom-component-3d-viewer-container" ref={threeDViewerDiv}></div>
    </>
}