import React from "react";
import Badge from "@weave-design/badge";
import { CustomPanelType } from "../../responses/customPanelTypes";
import { getStatusBadgeColor } from "./customComponentCommon";

type ComponentTypeIcon = "custom-panel-component-card-stats-custom-panel-type" 
    | "custom-panel-component-card-stats-custom-corner-type"
    | "custom-panel-component-card-stats-custom-z-shaped-type";

type Props = {
    panel: CustomPanelType;
    panelProperties: React.ReactNode;
    typeIcon: ComponentTypeIcon
    selectLibraryComponent: (component: CustomPanelType) => void;
}

export const CustomComponentCard = ({ panel, panelProperties, typeIcon, selectLibraryComponent }: Props) => {
    return <div className="custom-panel-component-card" onClick={() => selectLibraryComponent(panel)}>
        <div className="custom-panel-component-card-thumbnail-container">
            {panel.thumbnailUrl && <div
                className="custom-panel-component-card-thumbnail"
                style={{ backgroundImage: `url(${panel.thumbnailUrl})` }}></div>}
        </div>
        <div className="custom-panel-component-card-data-container">
            <div className="custom-panel-component-card-data-title">{panel.name}</div>
            {panelProperties}
        </div>
        <div className="custom-panel-component-card-stats-container">
            <div className={typeIcon}></div>
            <div className="custom-panel-component-card-stats-status-badge-container"><Badge color={getStatusBadgeColor(panel.status)} customColors={{ backgroundColor: "#778899" }} /></div>
        </div>
    </div>
}