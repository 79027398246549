import { IPanelSourceOpening } from "../../../responses/panelSource";
import { SystemSettings } from "../../../responses/systemSettings";
import { BoundaryLoopsFactory } from "../boundaries/boundaryLoopsFactory";
import { CladdingCellExtrusionGeometryFactory, ExtrusionGeometry } from "../geometry/claddingCellExtrusionGeometryFactory";
import { BoundaryLoop } from "../boundaries/boundaryLoop";
import colorsFactory from "../colors/colorsFactory";
import { windowCellBorderThickness, zFightingFixDistance } from "@dextall/panels-defaults";

export class PanelWindowSill {
    private readonly windowSillOffset = Autodesk.Viewing.Private.convertUnits("inch", "ft", 1, 2);
    private readonly boundaryLoop: BoundaryLoop;
    private readonly material: THREE.Material;

    constructor(panelWindowDefinition: IPanelSourceOpening, systemSettings: SystemSettings, transform: THREE.Matrix4,
        boundaryLoopsFactory: BoundaryLoopsFactory, private readonly claddingExtrusionFactory: CladdingCellExtrusionGeometryFactory) {

        const windowBox = panelWindowDefinition.box;

        const windowSillStartPoint = windowBox.min.y + systemSettings.windowFrameOffset.bottom;
        
        const box = new THREE.Box3(
            new THREE.Vector3(windowBox.min.x + systemSettings.windowOverlaps.left, windowSillStartPoint - systemSettings.windowSillHeight, 0),
            new THREE.Vector3(windowBox.max.x - systemSettings.windowOverlaps.right, windowSillStartPoint)
        );

        this.boundaryLoop = boundaryLoopsFactory.createLoopFromBox(box, transform);
        this.material = colorsFactory.getColor(`RAL ${panelWindowDefinition.windowSillColor}`)!
    }

    createGeometry(): ExtrusionGeometry {
        return this.claddingExtrusionFactory.createCladdingCellExtrusion(this.boundaryLoop, {
            thickness: this.windowSillOffset,
            material: this.material,
            zOffset: windowCellBorderThickness + zFightingFixDistance
        })
    }
}