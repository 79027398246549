import React, { useEffect, useRef, useState } from "react"
import NotificationsToast, { NotificationsToastList } from '@weave-design/notifications-toast';
import { guid } from "@dextall/shared";
import eventBus, { IApplicationEvent } from "../forge/eventBus/eventDispatcher";

export const ApplicationNotifications = () => {
    const [notifications, _setNotifications] = useState<Notification[]>([]);
    const notificationsRef = useRef<Notification[]>([]);

    const setNotifications = (notifications: Notification[]) => {
        _setNotifications(notifications);
        notificationsRef.current = notifications;
    }

    const onDismissNotification = (notification: Notification) => {
        setNotifications(notificationsRef.current.filter(x => x.key !== notification.key));
        clearTimeout(notification.timeOutHandle);
    }

    useEffect(() => {
        const onError = (event: IApplicationEvent<string>) => {
            const notification: Notification = {
                message: event.payload,
                key: guid(),
                status: "error",
                timeOutHandle: 0
            }

            notification.timeOutHandle = window.setTimeout(() => onDismissNotification(notification), timeOut);

            notificationsRef.current.splice(0, 0, ...[notification]);

            setNotifications([...notificationsRef.current]);
        }

        eventBus.addEventListener("Dextall.Common.Notify.Error", onError);

        return () => {
            eventBus.removeEventListener("Dextall.Common.Notify.Error", onError);
        }

    }, []);

    const notificationToasts = notifications.map(x => <NotificationsToast key={x.key} status={x.status} onDismiss={() => onDismissNotification(x)}>{x.message}</NotificationsToast>);

    return <div style={containerStyle}>
        <NotificationsToastList placement="bottom">
            {notificationToasts}
        </NotificationsToastList>
    </div>
}

const timeOut = 30000;

const containerStyle: React.CSSProperties = {
    position: "absolute",
    right: 20,
    bottom: 70,
    zIndex: 50
}

type Notification = {
    message: string;
    key: string;
    status: "error" | "warning" | "success" | "primary"
    timeOutHandle: number;
}