import { ICustomComponentViewerNavigator } from "../custom-component-library/navigation/customComponentViewerNavigator";
import { CustomComponentViewerNavigatorFactory } from "../custom-component-library/navigation/customComponentViewerNavigatorFactory";
import { CustomComponentViewerType } from "../custom-component-library/navigation/customComponentViewerType";
import eventBus, { IApplicationEvent } from "../eventBus/eventDispatcher";

export class ForgeCustomComponentNavigationExtension extends Autodesk.Viewing.Extension {
    private readonly customComponentViewerType: CustomComponentViewerType;
    private toolbar: Autodesk.Viewing.UI.ToolBar | null = null;
    private fitToViewButton: Autodesk.Viewing.UI.Button | null = null;
    private toggleFullScreenButton: Autodesk.Viewing.UI.Button | null = null;
    private navigator: ICustomComponentViewerNavigator | null = null;
    private measureCreated: boolean = false;

    constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: any) {
        super(viewer, options);
        this.customComponentViewerType = options.customComponentViewerType;

        this.onModelAdded = this.onModelAdded.bind(this);
        this.onExtensionLoaded = this.onExtensionLoaded.bind(this);
        this.onToggleFullScreen = this.onToggleFullScreen.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onOtherViewerSelectionChanged = this.onOtherViewerSelectionChanged.bind(this);
        this.onFitToView = this.onFitToView.bind(this);
        this.onShowNode = this.onShowNode.bind(this);
        this.onHideNode = this.onHideNode.bind(this);
    }

    load() {
        this.viewer.addEventListener(Autodesk.Viewing.MODEL_ADDED_EVENT, this.onModelAdded, { once: true });
        this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionChanged);
        this.viewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);

        if (this.customComponentViewerType === "3D")
            this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, this.notifyTopInstanceNodes.bind(this), { once: true });

        eventBus.addEventListener("Dextall.CustomComponentLibrary.Model.SelectionChanged", this.onOtherViewerSelectionChanged);
        eventBus.addEventListener("Dextall.CustomComponentLibrary.Model.UI.ShowInstanceTreeNode", this.onShowNode);
        eventBus.addEventListener("Dextall.CustomComponentLibrary.Model.UI.HideInstanceTreeNode", this.onHideNode);

        this.createToolbar();

        return true;
    }

    unload() {
        this.viewer.removeEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, this.onExtensionLoaded);
        this.viewer.removeEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionChanged);

        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Model.SelectionChanged", this.onOtherViewerSelectionChanged);
        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Model.UI.ShowInstanceTreeNode", this.onShowNode);
        eventBus.removeEventListener("Dextall.CustomComponentLibrary.Model.UI.HideInstanceTreeNode", this.onHideNode);

        this.toolbar = null;

        this.toggleFullScreenButton?.removeEventListener("click", this.onToggleFullScreen);
        this.toggleFullScreenButton = null;

        this.fitToViewButton?.removeEventListener("click", this.onFitToView);
        this.fitToViewButton = null;

        this.navigator?.dispose();

        this.navigator = null;

        return true;
    }

    private onModelAdded() {
        this.viewer.navigation.toOrthographic();

        this.setCamera();

        this.disableDefaultTransform();

        this.viewer.autocam.setCurrentViewAsHome(true);

        this.viewer.prefs.set(Autodesk.Viewing.Private.Prefs.REVERSE_MOUSE_ZOOM_DIR, true);
        this.viewer.prefs.set(Autodesk.Viewing.Private.Prefs3D.GROUND_SHADOW, false);
        this.fitToViewButton?.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);

        eventBus.dispatchEvent({ type: "Dextall.CustomComponentLibrary.Model.Ready", payload: this.customComponentViewerType });
    }

    private setCamera() {
        if (this.customComponentViewerType === "3D")
            return;

        this.navigator = CustomComponentViewerNavigatorFactory.createNavigator(this.viewer, this.customComponentViewerType);

        this.navigator.initialize();

        this.viewer.toolController.deactivateTool("orbit");
        this.viewer.toolController.activateTool("pan");
    }

    private createToolbar() {
        if (this.viewer.toolbar)
            return;

        this.toolbar = new Autodesk.Viewing.UI.ToolBar("dextall-custom-component-viewer-toolbar");

        const modelToolsControlGroup = new Autodesk.Viewing.UI.ControlGroup(Autodesk.Viewing.TOOLBAR.MODELTOOLSID);

        this.toolbar.addControl(new Autodesk.Viewing.UI.ControlGroup(Autodesk.Viewing.TOOLBAR.NAVTOOLSID));
        this.toolbar.addControl(modelToolsControlGroup);

        this.viewer.getToolbar = () => this.toolbar!;

        const measureExtension = this.viewer.getExtension("Autodesk.Measure") as MeasureExtension | null;

        if (measureExtension) {
            this.measureCreated = true;

            this.setupMeasure(measureExtension);
        }

        const toggleFullScreenGroup = new Autodesk.Viewing.UI.ControlGroup("dextall-custom-component-viewer-full-screen-group");

        this.toggleFullScreenButton = new Autodesk.Viewing.UI.Button("dextall-custom-component-viewer-toggle-full-screen");
        this.toggleFullScreenButton.setToolTip("Full screen");
        this.toggleFullScreenButton.setIcon("adsk-icon-fullscreen");
        this.toggleFullScreenButton.addEventListener("click", this.onToggleFullScreen);

        toggleFullScreenGroup.addControl(this.toggleFullScreenButton);

        this.toolbar.addControl(toggleFullScreenGroup);

        this.fitToViewButton = new Autodesk.Viewing.UI.Button("dextall-custom-component-viewer-fit-to-view");
        this.fitToViewButton.setToolTip("Fit \uFEFFto view");
        this.fitToViewButton.setIcon("adsk-icon-fit-to-view");
        this.fitToViewButton.addEventListener("click", this.onFitToView);
        this.fitToViewButton.setState(Autodesk.Viewing.UI.Button.State.DISABLED);
        modelToolsControlGroup.addControl(this.fitToViewButton);

        this.viewer.container.appendChild(this.toolbar.container);
    }

    private onExtensionLoaded(event: { extensionId: string }) {
        if (event.extensionId === "Autodesk.Measure" && !this.measureCreated && this.toolbar) {
            const measureExtension = this.viewer.getExtension("Autodesk.Measure") as MeasureExtension;

            this.setupMeasure(measureExtension);

            this.measureCreated = true;
        }
    }

    private onToggleFullScreen() {
        const extension = this.viewer.getExtension("Autodesk.FullScreen");

        const targetState = !extension.isActive("");

        if (targetState) {
            extension.activate("");
            this.toggleFullScreenButton?.setIcon("adsk-icon-fullscreen-exit");
        } else {
            extension.deactivate();
            this.toggleFullScreenButton?.setIcon("adsk-icon-fullscreen");
        }
    }

    private onFitToView() {
        this.viewer.impl.fitToView([], true);
    }

    private onSelectionChanged(event: { dbIdArray: number[] }) {
        eventBus.dispatchEvent({ type: "Dextall.CustomComponentLibrary.Model.SelectionChanged", payload: event.dbIdArray })
    }

    private onOtherViewerSelectionChanged(event: IApplicationEvent<number[]>) {
        const currentSelection = this.viewer.getSelection();

        if (areNumericArraysEqual(currentSelection, event.payload))
            return;

        this.viewer.select(event.payload);
    }

    private onShowNode(event: IApplicationEvent<ModelInstanceTreeNode>) {
        this.viewer.show(event.payload.id);
    }

    private onHideNode(event: IApplicationEvent<ModelInstanceTreeNode>) {
        this.viewer.hide(event.payload.id);
    }

    private setupMeasure(extension: MeasureExtension) {
        extension.setUnits("mm");
        extension.setPrecision(0.1);

        if (this.toolbar)
            extension.onToolbarCreated(this.toolbar);
    }

    private notifyTopInstanceNodes() {
        const instanceTree = this.viewer.model.getInstanceTree();

        const rootNodeName = instanceTree.getNodeName(instanceTree.getRootId());

        const instanceNodes: ModelInstanceTreeNode[] = [];

        instanceTree.enumNodeChildren(instanceTree.getRootId(), dbId => { instanceNodes.push({ id: dbId, name: instanceTree.getNodeName(dbId) }) });

        if (instanceNodes.length === 1 && instanceNodes[0].name === rootNodeName) {
            const rootId = instanceNodes[0].id;

            instanceNodes.splice(0, 1);

            instanceTree.enumNodeChildren(rootId, dbId => { instanceNodes.push({ id: dbId, name: instanceTree.getNodeName(dbId) }) });
        }

        eventBus.dispatchEvent({
            type: "Dextall.CustomComponentLibrary.Model.InstancesReady",
            payload: instanceNodes
        });
    }

    private disableDefaultTransform() {
        // Viewer does deffered fit to view. Prevent this
        // @ts-ignore
        this.viewer.utilities.transitionView = () => { };
    }
}

export type ModelInstanceTreeNode = {
    id: number;
    name: string;
}

const areNumericArraysEqual = (x: number[], y: number[]) => {
    if (x.length !== y.length)
        return false;

    for (let i = 0; i < x.length; ++i)
        if (x[i] !== y[i])
            return false;

    return true;
}

type MeasureExtension = Autodesk.Viewing.Extension & {
    setUnits(units: "mm"): void;
    setPrecision(precision: 0.1): void;
}