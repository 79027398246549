import React, { useEffect, useRef } from "react";

type Props = {
    url: string;
    isPdf: boolean;
}

export const PanelGeneratedContentViewer = ({ url, isPdf }: Props) => {
    const viewerDiv = useRef<HTMLDivElement>(null);
    const viewer = useRef<Autodesk.Viewing.GuiViewer3D>();

    const onModelLoaded = async (forgeViewer: Autodesk.Viewing.GuiViewer3D, viewerDocument: Autodesk.Viewing.Document) => {
        const geometry = viewerDocument.getRoot().getDefaultGeometry();
        await forgeViewer.loadDocumentNode(viewerDocument, geometry);
        await forgeViewer.waitForLoadDone();

        if ("autocam" in forgeViewer)
            forgeViewer.autocam.setCurrentViewAsHome(true);
    }

    const createViewer = (): Autodesk.Viewing.GuiViewer3D => {
        const container: HTMLElement = viewerDiv.current!;

        const extensions = ["Autodesk.DocumentBrowser"];

        const config: Autodesk.Viewing.Viewer3DConfig = {
            theme: "light-theme",
            extensions,
            disabledExtensions: {
                bimwalk: true
            },
            localStoragePrefix: "DextallGeneratedContentPreview"
        };

        return new Autodesk.Viewing.GuiViewer3D(container, config);
    }

    useEffect(() => {
        if (viewerDiv.current === null)
            return;

        const viewerDivSize = viewerDiv.current.getBoundingClientRect();

        if (viewerDivSize.width === 0 || viewerDivSize.height === 0)
            return;

        if (viewer.current)
            viewer.current.finish();

        const forgeViewer = createViewer();

        viewer.current = forgeViewer;

        const errorCode = forgeViewer.start();

        if (errorCode !== 0) {
            console.error(`Failed to initialize the viewer. Error code = ${errorCode}`);
            return;
        }

        forgeViewer.addEventListener(Autodesk.Viewing.MODEL_ROOT_LOADED_EVENT, () => {
            forgeViewer.navigation.toOrthographic();
            forgeViewer.setLightPreset(7);
            forgeViewer.prefs.set(Autodesk.Viewing.Private.Prefs.REVERSE_MOUSE_ZOOM_DIR, true);
            forgeViewer.prefs.set(Autodesk.Viewing.Private.Prefs3D.ENV_MAP_BACKGROUND, false);
        }, { once: true });

        if (isPdf)
            forgeViewer.loadExtension("Autodesk.PDF").then(() => forgeViewer.loadModel(url, { loadOptions: { page: 1 }, skipPropertyDb: true }));
        else
            Autodesk.Viewing.Document.load(url, onModelLoaded.bind(null, forgeViewer), (code) => console.error(`Failed to load. Error code =${code}`));

        return () => {
            if (viewer.current)
                viewer.current.finish();
        };
    }, []);

    return <div className="viewer-container" ref={viewerDiv}></div>
}