export class WallGeometryInstancesCountCalculator {
    private readonly fragmentList: Autodesk.Viewing.Private.FragmentList

    constructor(model: Autodesk.Viewing.Model) {
        this.fragmentList = model.getFragmentList();
    }

    getInstancesCount(fragmentId: number): number {
        const geometryId = this.fragmentList.getGeometryId(fragmentId);

        if (geometryId === undefined)
            return 1;

        return this.fragmentList.geoms.getInstanceCount(geometryId) ?? 1;
    }
}