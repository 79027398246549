import React, { useEffect, useRef } from "react"
import eventBus, { IApplicationEvent } from "./forge/eventBus/eventDispatcher";
import repo from "../Repository";

export const ContentDownload = () => {
    const downloadLinkRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {
        const startDownload = (url: string) => {
            const anchorElement = downloadLinkRef.current!;

            anchorElement.href = url;

            anchorElement.click();
        }

        const onDownloadModelConentRequested = async (event: IApplicationEvent<string>) => {
            const signedUrlResponse = await repo.createGeneratedModelDownloadableUrl(event.payload);

            if (!signedUrlResponse.isSuccess)
                return;

            startDownload(signedUrlResponse.item);
        }

        const onDownloadRevitHooksModelRequested = async (event: IApplicationEvent<string>) => {
            const signedUrlResponse = await repo.createHooksGeneratedModelDownloadableUrl(event.payload);

            if (!signedUrlResponse.isSuccess)
                return;

            startDownload(signedUrlResponse.item);
        }

        const onDownloadQrCode = async (event: IApplicationEvent<string>) => startDownload(event.payload);

        eventBus.addEventListener("Dextall.GeneratedModel.Download", onDownloadModelConentRequested);
        eventBus.addEventListener("Dextall.HooksRevitModel.Download", onDownloadRevitHooksModelRequested);
        eventBus.addEventListener("Dextall.QRCode.Download", onDownloadQrCode);

        return () => {
            eventBus.removeEventListener("Dextall.GeneratedModel.Download", onDownloadModelConentRequested);
            eventBus.removeEventListener("Dextall.HooksRevitModel.Download", onDownloadRevitHooksModelRequested);
            eventBus.removeEventListener("Dextall.QRCode.Download", onDownloadQrCode);
        }
    }, []);

    return <a ref={downloadLinkRef} style={{ display: "none" }} />
}